import crypto from 'crypto-js';

const INTERCOM_APP_ID = "ef788m81";
const INTERCOM_SECRET_KEY = process.env.REACT_APP_INTERCOM_SECRET_KEY;

export const initializeIntercom = (user) => {
  if (!user?.id) {
    return;
  }

  try {
    if (typeof window.Intercom !== 'function') {
      return;
    }

    const userIdentifier = user.id.toString();
    // Make sure INTERCOM_SECRET_KEY exists
    if (!INTERCOM_SECRET_KEY) {
      return;
    }

    // Generate user hash for identity verification
    const hash = crypto.HmacSHA256(userIdentifier, INTERCOM_SECRET_KEY).toString(crypto.enc.Hex);
    
    const intercomData = {
      app_id: INTERCOM_APP_ID,
      user_id: user.id,
      name: user.name || '',
      email: user.email || '',
      created_at: user.createdAt ? Math.floor(user.createdAt.getTime() / 1000) : undefined,
      user_hash: hash
    };

    window.Intercom('boot', intercomData);
    
    window.Intercom('update', {
      user_tags: [user.role]
    });
  } catch (error) {
    // Error handling removed
  }
};

export const updateIntercom = (user) => {
  if (!user?.id) return;

  try {
    window.Intercom('update', {
      user_id: user.id,
      name: user.name || '',
      email: user.email || '',
      created_at: user.createdAt ? Math.floor(user.createdAt.getTime() / 1000) : undefined
    });

    window.Intercom('update', {
      user_tags: [user.role]
    });
  } catch (error) {
    // Error handling removed
  }
};
