import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import Header from '../components/Header';
import { FaInstagram, FaTiktok, FaYoutube, FaStar, FaMapMarkerAlt } from 'react-icons/fa';

const CreatorPersonalScreen = () => {
  const { username } = useParams();
  const [creator, setCreator] = useState(null);
  const [portfolio, setPortfolio] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCreatorAndPortfolio = async () => {
      try {
        // Remove console.log('Starting creator search for:', username);
        
        const creatorsRef = collection(db, 'creators');
        const normalizedQuery = query(creatorsRef, where('normalizedName', '==', username));
        let querySnapshot = await getDocs(normalizedQuery);
        
        if (querySnapshot.empty) {
          const usernameQuery = query(creatorsRef, where('username', '==', username));
          querySnapshot = await getDocs(usernameQuery);
        }

        if (querySnapshot.empty) {
          const uidSuffix = username.split('-').pop();
          const uidQuery = query(creatorsRef);
          const allDocs = await getDocs(uidQuery);
          
          const matchingDoc = allDocs.docs.find(doc => doc.id.endsWith(uidSuffix));
          
          if (matchingDoc) {
            const creatorData = matchingDoc.data();
            // Remove console.log('Found creator by UID suffix:', creatorData);
            setCreator({ id: matchingDoc.id, ...creatorData });
            setLoading(false);
            return;
          }
        } else {
          const creatorDoc = querySnapshot.docs[0];
          const creatorData = creatorDoc.data();
          // Remove console.log('Found creator:', creatorData);
          setCreator({ id: creatorDoc.id, ...creatorData });
          setLoading(false);
          return;
        }
        
        // Remove console.log('No creator found with any search method');
        setCreator(null);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching creator or portfolio:', error);
        setLoading(false);
      }
    };

    if (username) {
      fetchCreatorAndPortfolio();
    }
  }, [username]);

  useEffect(() => {
    document.title = `${creator?.name || 'Creator'} | Behave`;
  }, [creator]);

  useEffect(() => {
    if (creator) {
      document.title = `${creator.name} - Creator Profile | Behave`;
    } else {
      document.title = 'Creator Profile | Behave';
    }
  }, [creator]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50">
        <Header userType="brand" />
        <div className="pt-28 md:pt-20 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-2">
          <div className="animate-pulse">
            {/* Add loading skeleton here */}
          </div>
        </div>
      </div>
    );
  }

  if (!creator) {
    return (
      <div className="min-h-screen bg-gray-50">
        <Header userType="brand" />
        <div className="pt-28 md:pt-20 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-2">
          <div className="text-center">
            <h2 className="text-2xl font-bold text-gray-900">Creator Not Found</h2>
            <p className="mt-2 text-gray-600">
              We couldn't find a creator with the username "{username}". 
              Please check the URL and try again.
            </p>
          </div>
        </div>
      </div>
    );
  }

  // Add function to calculate median rating
  const calculateMedianRating = (ratings) => {
    if (!ratings || ratings.length === 0) return null;
    const sortedRatings = ratings.sort((a, b) => a - b);
    const middle = Math.floor(sortedRatings.length / 2);
    return sortedRatings.length % 2 === 0
      ? (sortedRatings[middle - 1] + sortedRatings[middle]) / 2
      : sortedRatings[middle];
  };

  // Add function to render content based on type
  const renderContent = (content) => {
    if (!content) return null;

    if (content.type === 'url') {
      return (
        <iframe
          src={content.url}
          className="w-full aspect-video rounded-lg"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      );
    } else if (content.type === 'video') {
      return (
        <video
          src={content.url}
          controls
          className="w-full aspect-video rounded-lg"
        />
      );
    } else if (content.type === 'image') {
      return (
        <img
          src={content.url}
          alt="Content"
          className="w-full aspect-4/3 object-cover rounded-lg"
        />
      );
    }
    return null;
  };

  const renderPortfolioItem = (item) => {
    // Combine all media content
    const allMedia = [
      ...(item.contentInputs?.Video || []),
      ...(item.contentInputs?.Image || []),
    ];

    const socialLinks = [
      ...(item.contentInputs?.['Instagram post/story/reel URL'] || []),
      ...(item.contentInputs?.['TikTok post URL'] || []),
    ];

    // Helper function to format location
    const formatLocation = (location) => {
      if (!location) return null;
      if (typeof location === 'string') return location;
      if (location.lat && location.lng) return 'Location Set'; // or return a formatted string
      return null;
    };

    return (
      <div key={item.id} className="border-b pb-8 last:border-b-0">
       
        {/* Metadata */}
        <div className="flex items-center space-x-2 text-sm text-gray-500 mb-4">
          <span>{new Date(item.createdAt?.toDate()).toLocaleDateString()}</span>
          <span>•</span>
          <span>{item.offer?.title || 'Untitled Offer'}</span>
          {item.offer?.location && (
            <>
              <span>•</span>
              <span className="flex items-center">
                <FaMapMarkerAlt className="w-4 h-4 mr-1" />
                {formatLocation(item.offer.location)}
              </span>
            </>
          )}
          {item.offer?.brandName && (
            <>
              <span>•</span>
              <span>{item.offer.brandName}</span>
            </>
          )}
        </div>


        {/* Social Links */}
        {socialLinks.length > 0 && (
          <div className="mb-4 flex flex-wrap gap-2 mb-2">
            {socialLinks.map((url, index) => (
              <a
                key={index}
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center px-3 py-1 bg-gray-100 hover:bg-gray-200 rounded-full text-sm text-gray-700"
              >
                {url.includes('instagram.com') ? (
                  <><FaInstagram className="mr-1" /> Instagram Post</>
                ) : (
                  <><FaTiktok className="mr-1" /> TikTok Post</>
                )}
              </a>
            ))}
          </div>
        )}

        

        {/* Offer Type Tags */}
        <div className="mt-2 flex flex-wrap gap-2 mb-2">
          {Object.entries(item.offerType || {}).map(([type, enabled]) => 
            enabled && (
              <span
                key={type}
                className="px-2 py-1 bg-blue-100 text-blue-800 rounded-full text-xs"
              >
                {type.replace(/([A-Z])/g, ' $1').trim()}
              </span>
            )
          )}
        </div>
         {/* Media Content */}
        <div className="mb-4">
          {allMedia.map((url, index) => (
            <div key={index} className="mb-2">
              {url.toLowerCase().includes('.mp4') || url.toLowerCase().includes('.mov') ? (
                <video
                  src={url}
                  controls
                  className="w-full aspect-video rounded-lg"
                />
              ) : (
                <img
                  src={url}
                  alt={`Submission ${index + 1}`}
                  className="w-full aspect-4/3 object-cover rounded-lg"
                />
              )}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Header userType="brand" />
      <main className="pt-28 md:pt-20 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-8">
          {/* Left Column */}
          <div className="md:col-span-3 space-y-6">
            {/* Profile Info */}
            <div className=" rounded-lg p-6 items-center">
              <div className="text-center">
                <img
                  src={creator?.profilePicture || '/default-avatar.png'}
                  alt={creator?.name}
                  className="w-32 h-32 rounded-full mx-auto mb-4 object-cover"
                />
                <h1 className="text-2xl font-bold text-gray-900">{creator?.name}</h1>
                
                {/* Median Rating */}
                {creator?.ratings && (
                  <div className="flex items-center justify-center mt-2">
                    <FaStar className="text-yellow-400 w-5 h-5 mr-1" />
                    <span className="text-lg font-medium">
                      {calculateMedianRating(creator.ratings).toFixed(1)}
                    </span>
                  </div>
                )}
              </div>

              {/* Social Links */}
              <div className="mt-6 space-y-4 flex flex-col items-center">
                {creator?.socials?.instagram && (
                  <a
                    href={creator.socials.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center text-gray-700 hover:text-pink-600"
                  >
                    <FaInstagram className="w-5 h-5 mr-2" />
                    <span>{formatNumber(creator.socialStats?.instagramFollowers)} followers</span>
                  </a>
                )}
                {creator?.socials?.tiktok && (
                  <a
                    href={creator.socials.tiktok}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center text-gray-700 hover:text-black"
                  >
                    <FaTiktok className="w-5 h-5 mr-2" />
                    <span>{formatNumber(creator.socialStats?.tiktokFollowers)} followers</span>
                  </a>
                )}
                {creator?.socials?.youtube && (
                  <a
                    href={creator.socials.youtube}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center text-gray-700 hover:text-red-600"
                  >
                    <FaYoutube className="w-5 h-5 mr-2" />
                    <span>{formatNumber(creator.socialStats?.youtubeSubscribers)} subscribers</span>
                  </a>
                )}
              </div>
            </div>
          </div>

          {/* Middle Column - Portfolio */}
          <div className="md:col-span-6">
            <div className="bg-white rounded-lg shadow">
              <div className="p-6">
                <h2 className="text-xl font-bold text-gray-900 mb-6">Previous Work</h2>
                <div className="space-y-8">
                  {portfolio.length > 0 ? (
                    portfolio.map(renderPortfolioItem)
                  ) : (
                    <p className="text-gray-500 text-center">No previous work to display</p>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Right Column */}
          <div className="md:col-span-3 space-y-6">
            {/* Bio */}
            <div className="p-6">
              <h2 className="text-lg font-bold text-gray-900 mb-4">About creator</h2>
              <p className="text-gray-600">{creator?.bio}</p>
            </div>

            {/* Skills */}
            {creator?.skills?.length > 0 && (
              <div className="p-6">
                <h2 className="text-lg font-bold text-gray-900 mb-4">Skills</h2>
                <div className="flex flex-wrap gap-2">
                  {creator.skills.map((skill, index) => (
                    <span
                      key={index}
                      className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm"
                    >
                      {skill}
                    </span>
                  ))}
                </div>
              </div>
            )}

            {/* Shipping Info */}
            {creator?.shippingInfo?.isPublic && creator?.shippingInfo?.address && (
              <div className="p-6">
                <h2 className="text-lg font-bold text-gray-900 mb-4">Shipping Address</h2>
                <p className="text-gray-600">{creator.shippingInfo.address}</p>
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

// Helper function to format social media numbers
const formatNumber = (num) => {
  if (!num) return '0';
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M';
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'K';
  }
  return num.toString();
};

export default CreatorPersonalScreen;
