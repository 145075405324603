import React from 'react';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const PendingApprovalScreen = () => {
  const { isApproved, isActive, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
      alert('Failed to logout. Please try again.');
    }
  };

  const title = !isApproved 
    ? "Application Submitted"
    : "Account Inactive";

  const message = !isApproved 
    ? (
      <>
        <p>Thank you for submitting your application to join our platform. Our team is excited to review your profile!</p>
        <p className="mt-2">We strive to process all applications within 24 hours.</p>
        <p className="mt-2">You will receive an email notification once your account has been reviewed, informing you whether your application has been accepted or if we need any additional information.</p>
        <p className="mt-2">In the meantime, please check your email (including your spam folder) for any communications from our team.</p>
        <p className="mt-2">If you have any urgent questions, please contact our support team at <a href="mailto:support@behave.ro" className="text-indigo-600 hover:text-indigo-800">support@behave.ro</a></p>
      </>
    )
    : (
      <>
        <p>Your account is currently inactive. This may be due to a violation of our terms of service or an administrative action.</p>
        <p className="mt-2">If you believe this is an error, please contact our support team at <a href="mailto:support@behave.ro" className="text-indigo-600 hover:text-indigo-800">support@behave.ro</a> for assistance.</p>
      </>
    );

  return (
    <div className="min-h-screen bg-gradient-to-b from-[rgba(205,212,213,1)] to-[rgba(255,255,255,0)] flex flex-col justify-center py-6 px-4 sm:py-12 sm:px-6 lg:px-8">
      <div className="mx-auto w-full max-w-md sm:max-w-md">
        <h2 className="mt-2 sm:mt-4 text-2xl sm:text-3xl font-extrabold text-gray-900">
          {title}
        </h2>
      </div>
      <div className="mt-2 sm:mt-4 mx-auto w-full max-w-md">
        <div className="bg-white py-6 px-4 shadow-lg rounded-lg sm:rounded-lg sm:py-8 sm:px-10">
          <div className="text-sm text-gray-600 space-y-4">
            {message}
          </div>
          <button
            onClick={handleLogout}
            className="mt-6 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150 ease-in-out"
          >
            Log Out
          </button>
        </div>
      </div>
    </div>
  );
};

export default PendingApprovalScreen;
