import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { 
  collection, 
  query, 
  where, 
  getDocs,
  getDoc,
  doc,
  addDoc,
  runTransaction,
  serverTimestamp,
  getFirestore
} from 'firebase/firestore';
import { db } from '../firebase';
import FilterBar from '../components/FilterBar';
import { useGlobalContext } from '../context/GlobalContext';
import Header from '../components/Header';
import { GoogleMap, useLoadScript, MarkerF, InfoWindow } from '@react-google-maps/api';
import { useToast } from '../context/ToastContext';
import { auth } from '../firebase';
import { CardSkeleton } from '../components/Skeletons';
import { X, MapPin, CreditCard, Navigation, Pin } from 'lucide-react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import parse from 'html-react-parser';

// Add this helper function near the top of your component
const cleanDescription = (description) => {
  if (!description) return '';
  
  // Remove empty paragraphs and their variations
  const cleaned = description
    .replace(/<p>\s*<\/p>/g, '') // Remove <p></p>
    .replace(/<p>&nbsp;<\/p>/g, '') // Remove <p>&nbsp;</p>
    .replace(/<p>\s*<br\s*\/?>\s*<\/p>/g, ''); // Remove <p><br></p>
    
  return cleaned.trim();
};

// Helper function to get status styles (add this near the top of your file)
const getStatusStyles = (status) => {
  switch (status) {
    case 'pending':
      return 'bg-gray-100 text-gray-700';
    case 'applied':
      return 'bg-blue-100 text-blue-700';
    case 'accepted':
      return 'bg-green-100 text-green-700';
    case 'rejected':
      return 'bg-red-100 text-red-700';
    default:
      return 'bg-gray-100 text-gray-700';
  }
};

function CreatorOfferScreen() {
  const { addToast } = useToast();
  const { 
    selectedCity, 
    setSelectedCity, 
    offers, 
    setOffers,
    isLoading, 
    error, 
    refreshOffers 
  } = useGlobalContext();

  const [showMap, setShowMap] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [mapCenter, setMapCenter] = useState({ lat: 48.8566, lng: 2.3522 });
  const [showOfferDetails, setShowOfferDetails] = useState(false);
  const [showChatModal, setShowChatModal] = useState(false);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [userType, setUserType] = useState(null);
  const [applications, setApplications] = useState({});
  const [mapRef, setMapRef] = useState(null);
  const [bounds, setBounds] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [isFullscreenMap, setIsFullscreenMap] = useState(false);
  const [selectedTag, setSelectedTag] = useState('All');
  const [selectedCollabType, setSelectedCollabType] = useState('All');
  const [filteredOffers, setFilteredOffers] = useState([]);

  // Add loading state if not already present
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  });

  const mapContainerStyle = {
    width: '100%',
    height: '600px',
    borderRadius: '20px',
  };

  // Initial load effect
  useEffect(() => {
    const initializeScreen = async () => {
      const searchParams = new URLSearchParams(location.search);
      const cityParam = searchParams.get('city');
      const useLocation = searchParams.get('useLocation') === 'true';

      if (cityParam) {
        const decodedCity = decodeURIComponent(cityParam);
        setSelectedCity(decodedCity);
        await refreshOffers(true); // Force refresh
      } else {
        // If no city parameter, load all offers
        setSelectedCity('All Cities');
        await refreshOffers(true); // Force refresh
      }
      setInitialLoadComplete(true);
    };

    if (!initialLoadComplete) {
      initializeScreen();
    }
  }, [location.search, initialLoadComplete]);

  // Update offers when city changes
  useEffect(() => {
    const fetchOffers = async () => {
      try {
        let offersQuery;
        
        if (selectedCity && selectedCity !== 'All Cities') {
          offersQuery = query(
            collection(db, 'offers'),
            where('city', '==', selectedCity)
          );
        } else {
          offersQuery = query(
            collection(db, 'offers')
          );
        }

        const offersSnapshot = await getDocs(offersQuery);
        const offersData = offersSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        console.log('All fetched offers:', offersData); // Debug log

        // Filter active offers after fetching
        const activeOffers = offersData.filter(offer => offer.status !== false);
        
        console.log('Active offers after filtering:', activeOffers); // Debug log

        setOffers(activeOffers);
        setFilteredOffers(activeOffers);
      } catch (error) {
        console.error('Error fetching offers:', error);
      }
    };

    if (initialLoadComplete) {
      fetchOffers();
    }
  }, [selectedCity, initialLoadComplete]);

  useEffect(() => {
    document.title = 'Offers | Behave';
  }, []);

  useEffect(() => {
    if (offers.length > 0) {
      const validOffers = offers.filter(offer => 
        typeof offer.latitude === 'number' && 
        typeof offer.longitude === 'number' &&
        !isNaN(offer.latitude) && 
        !isNaN(offer.longitude)
      );
      if (validOffers.length > 0) {
        const totalLat = validOffers.reduce((sum, offer) => sum + offer.latitude, 0);
        const totalLng = validOffers.reduce((sum, offer) => sum + offer.longitude, 0);
        setMapCenter({
          lat: totalLat / validOffers.length,
          lng: totalLng / validOffers.length
        });
      }
    }
  }, [offers]);

  useEffect(() => {
    const fetchApplications = async () => {
      const user = auth.currentUser;
      if (user) {
        const applicationsQuery = query(
          collection(db, 'applications'),
          where('creatorId', '==', user.uid)
        );
        const applicationsSnapshot = await getDocs(applicationsQuery);
        const applicationsData = {};
        applicationsSnapshot.forEach(doc => {
          applicationsData[doc.data().offerId] = doc.data().status;
        });
        setApplications(applicationsData);
      }
    };
    fetchApplications();
  }, []);

  useEffect(() => {
    if (offers.length > 0 && mapRef) {
      fitMapToOffers();
    }
  }, [offers, mapRef, selectedCity]);

  useEffect(() => {
    let filtered = [...offers].filter(offer => offer.status !== false);

    if (selectedTag !== 'All') {
      filtered = filtered.filter(offer => 
        offer.selectedTags?.includes(selectedTag)
      );
    }

    if (selectedCollabType !== 'All') {
      filtered = filtered.filter(offer => 
        offer.collabType === selectedCollabType
      );
    }

    setFilteredOffers(filtered);
  }, [selectedTag, selectedCollabType, offers]);

  const fitMapToOffers = () => {
    const bounds = new window.google.maps.LatLngBounds();
    let validOfferFound = false;

    offers.forEach(offer => {
      if (isValidLatLng(offer.latitude, offer.longitude)) {
        bounds.extend({ lat: offer.latitude, lng: offer.longitude });
        validOfferFound = true;
      }
    });

    if (validOfferFound) {
      mapRef.fitBounds(bounds);
      // Adjust zoom level if it's too close
      const listener = mapRef.addListener('idle', () => {
        if (mapRef.getZoom() > 10) {
          mapRef.setZoom(16);
        }
        window.google.maps.event.removeListener(listener);
      });
    } else {
      // Default view for Romania if no valid offers
      mapRef.setCenter({ lat: 45.9432, lng: 24.9668 });
      mapRef.setZoom(7);
    }
  };

  const isValidLatLng = (lat, lng) => {
    return typeof lat === 'number' && !isNaN(lat) &&
           typeof lng === 'number' && !isNaN(lng) &&
           lat >= -90 && lat <= 90 &&
           lng >= -180 && lng <= 180;
  };

  const handleApply = async (offer) => {
    try {
      const user = auth.currentUser;
      if (!user) {
        addToast('You must be logged in to apply for offers', 'error');
        return;
      }

      const creatorDoc = await getDoc(doc(db, 'creators', user.uid));
      if (!creatorDoc.exists()) {
        addToast('Only creators can apply for offers', 'error');
        return;
      }

      // Check if the user has already applied to this offer
      const existingApplicationQuery = query(
        collection(db, 'applications'),
        where('creatorId', '==', user.uid),
        where('offerId', '==', offer.id)
      );
      const existingApplicationSnapshot = await getDocs(existingApplicationQuery);

      if (!existingApplicationSnapshot.empty) {
        addToast('You have already applied to this offer', 'error');
        return;
      }

      const creatorRef = doc(db, 'creators', user.uid);

      await runTransaction(db, async (transaction) => {
        const creatorDoc = await transaction.get(creatorRef);
        if (!creatorDoc.exists()) {
          throw new Error("Creator document does not exist!");
        }

        const creatorData = creatorDoc.data();
        if (creatorData.credits < 10) {
          throw new Error("Not enough credits to apply for this offer");
        }

        const newCredits = creatorData.credits - 10;

        transaction.update(creatorRef, { credits: newCredits });

        // Create the application
        const applicationRef = await addDoc(collection(db, 'applications'), {
          creatorId: user.uid,
          offerId: offer.id,
          brandId: offer.brandId,
          status: 'pending',
          createdAt: serverTimestamp(),
          offerType: offer.offerTypes || {} // Use an empty object if offerTypes is undefined
        });

        // Add to audit trail
        await addDoc(collection(db, 'audit'), {
          action: 'application_submitted',
          applicationId: applicationRef.id,
          creatorId: user.uid,
          offerId: offer.id,
          creditsCost: 10,
          timestamp: serverTimestamp()
        });
      });

      // Update local state
      setApplications(prev => ({...prev, [offer.id]: 'pending'}));

      addToast('Application submitted successfully', 'success');
    } catch (error) {
      addToast('Error applying to offer: ' + error.message, 'error');
    }
  };

  const handleViewOnMap = useCallback((offer) => {
    if (isValidLatLng(offer.latitude, offer.longitude)) {
      setSelectedOffer(offer);
      setActiveMarker(offer.id);
      setIsFullscreenMap(true);

      if (mapRef) {
        const newCenter = { lat: offer.latitude, lng: offer.longitude };
        mapRef.panTo(newCenter);
        mapRef.setZoom(16);
      }
    } else {
      addToast('Invalid location for this offer', 'error');
    }
  }, [mapRef, addToast]);

  const closeFullscreenMap = () => {
    setIsFullscreenMap(false);
    setActiveMarker(null);
  };

  const onMapLoad = useCallback((map) => {
    setMapRef(map);
  }, []);

  const handleSendMessage = async () => {
    if (newMessage.trim() === '' || !selectedOffer) return;

    try {
      const firestore = getFirestore();
      await addDoc(collection(firestore, 'messages'), {
        offerId: selectedOffer.id,
        senderId: auth.currentUser.uid,
        recipientId: selectedOffer.brandId,
        message: newMessage,
        timestamp: new Date(),
        read: false
      });
      setNewMessage('');
      setMessages([...messages, {
        senderId: auth.currentUser.uid,
        message: newMessage,
        timestamp: new Date()
      }]);
    } catch (error) {
      addToast('Failed to send message. Please try again.', 'error');
    }
  };

  const renderOffers = () => {
    if (isLoading) {
      return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {Array(6).fill().map((_, index) => (
            <CardSkeleton key={index} />
          ))}
        </div>
      );
    }

    if (error) {
      return <div className="text-red-500">{error}</div>;
    }

    if (offers.length === 0) {
      return <div className="text-gray-500">No offers available in this area.</div>;
    }

    return offers.map((offer) => (
      <div 
        key={offer.id} 
        className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer transition-transform duration-300 hover:shadow-lg flex flex-col"
        onClick={() => {
          setSelectedOffer(offer);
          setShowOfferDetails(true);
        }}
      >
        <img 
          src={offer.imageUrl} 
          alt={offer.title} 
          className="w-full h-48 object-cover" 
        />
        <div className="p-4 flex flex-col justify-between flex-grow">
          <div>
            <h2 className="text-xl font-semibold mb-2">{offer.title}</h2>
            <div className="text-gray-600 mb-4 rich-text-content">
              {parse(offer.description || '')}
            </div>
            <div className="mb-4">
              <strong>Apply today for:</strong>
              <ul className="list-disc list-inside">
                {offer.offerTypes && typeof offer.offerTypes === 'object' ? (
                  <>
                    {offer.offerTypes['1story'] && <li>1 Story</li>}
                    {offer.offerTypes['2stories'] && <li>2 Stories</li>}
                    {offer.offerTypes['storiesPost'] && <li>Stories + 1 Post</li>}
                  </>
                ) : (
                  <li>Offer type not specified</li>
                )}
              </ul>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
            {applications[offer.id] ? (
              <span className="text-blue-500 font-semibold mb-2 sm:mb-0">
                Application {applications[offer.id]}
              </span>
            ) : (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleApply(offer);
                }}
                className="w-full bg-blue-500 text-white px-2 py-2 rounded hover:bg-blue-600 transition-colors duration-300 mb-2 sm:mb-0 w-full sm:w-auto text-m"
              >
                Apply (10 credits)
              </button>
            )}
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleViewOnMap(offer);
              }}
              className="bg-green-500 text-white px-2 py-2 rounded hover:bg-green-600 transition-colors duration-300 w-full sm:w-auto text-sm"
            >
              View on Map
            </button>
          </div>
        </div>
      </div>
    ));
  };

  const renderMap = () => (
    <GoogleMap
      mapContainerStyle={isFullscreenMap ? { width: '100vw', height: '100vh' } : mapContainerStyle}
      center={mapCenter}
      zoom={7}
      options={{ 
        gestureHandling: 'greedy',
        disableDefaultUI: true
      }}
      onLoad={onMapLoad}
    >
      {offers.map(offer => (
        isValidLatLng(offer.latitude, offer.longitude) && (
          <MarkerF
            key={offer.id}
            position={{ lat: offer.latitude, lng: offer.longitude }}
            onClick={() => setActiveMarker(offer.id)}
          >
            {activeMarker === offer.id && (
              <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                <div>
                  <h3 className="font-bold">{offer.title}</h3>
                  <p>{offer.description.substring(0, 50)}...</p>
                  <button
                    onClick={() => {
                      setSelectedOffer(offer);
                      setShowOfferDetails(true);
                    }}
                    className="mt-2 bg-blue-500 text-white px-2 py-1 rounded text-sm flex items-center"
                  >
                    <MapPin className="w-4 h-4 mr-1" />
                    View Details
                  </button>
                </div>
              </InfoWindow>
            )}
          </MarkerF>
        )
      ))}
    </GoogleMap>
  );

  const renderApplicationStatus = (offerId, offer) => {
    const status = applications[offerId];
    
    if (!status) {
      return (
        <button
          onClick={(e) => {
            e.stopPropagation();
            handleApply(offer);
          }}
          className="w-full inline-flex items-center justify-center px-3 py-1.5 bg-slate-800 text-white rounded-lg hover:bg-slate-700 transition-colors text-sm"
        >
          <CreditCard className="w-4 h-4 mr-1" />
          Apply (10 credits)
        </button>
      );
    }

    if (status === 'accepted') {
      return (
        <button
          onClick={(e) => {
            e.stopPropagation();
            navigate('/applied-offers');
          }}
          className={`w-full inline-flex items-center justify-center px-3 py-1.5 rounded-lg text-sm
            ${getStatusStyles(status)} hover:bg-green-200 transition-colors cursor-pointer`}
        >
          <CreditCard className="w-4 h-4 mr-1" />
          View Accepted Offer
        </button>
      );
    }

    return (
      <span className={`w-full inline-flex items-center justify-center px-3 py-1.5 rounded-lg text-sm
        ${getStatusStyles(status)}`}
      >
        <CreditCard className="w-4 h-4 mr-1" />
        Application {status}
      </span>
    );
  };

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";

  return (
    <div className="min-h-screen bg-gray-50">
      <Header userType="creator" />
      <main className="pt-28 md:pt-20 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-2">
        {/* Page Header */}
        <div className=" flex justify-between items-center">
        <h2 className="text-xl font-bold mb-3 text-gray-500">Available Offers</h2>
          <button 
            onClick={() => setIsFullscreenMap(true)}
            className="text-gray-500 mt-[-12px] px-4 py-2 text-sm rounded hover:text-gray-600 transition-colors duration-300 flex items-center"
          >
            <MapPin className="w-5 h-5 mr-2" />
            Show Map
          </button>
        </div>
        <FilterBar 
          selectedTag={selectedTag}
          setSelectedTag={setSelectedTag}
          selectedCollabType={selectedCollabType}
          setSelectedCollabType={setSelectedCollabType}
          selectedCity={selectedCity}
        />
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredOffers.map(offer => (
            <div 
              key={offer.id} 
              className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer transition-transform duration-300 hover:shadow-lg flex flex-col"
              onClick={() => {
                setSelectedOffer(offer);
                setShowOfferDetails(true);
              }}
            >
              <LazyLoadImage
                src={offer.imageUrl}
                alt={offer.title}
                effect="blur"
                className="w-full h-44 object-cover"
                wrapperClassName="w-full h-44"
                placeholderSrc={offer.imageUrl + '?w=10'}
              />
              <div className="p-3 flex flex-col justify-between flex-grow">
                <div>
                  {selectedCity === 'All Cities' && (
                    <div className="flex items-center text-gray-600 mb-1 text-xs">
                      <Pin className="w-3 h-3 mr-1" />
                      <span>{offer.city}</span>
                    </div>
                  )}
                  <div className="flex justify-between items-center mb-2">
                    <h3 className="text-base font-semibold text-gray-700">{offer.title}</h3>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleViewOnMap(offer);
                      }}
                      className="text-gray-400 hover:text-green-600 transition-colors"
                    >
                      <Navigation className="w-3 h-3" />
                    </button>
                  </div>

                  {cleanDescription(offer.description) && (
                    <div className="text-xs text-gray-600 mb-3 line-clamp-3 rich-text-content">
                      {parse(cleanDescription(offer.description))}
                    </div>
                  )}

                  <div className="mb-3 bg-gray-50 p-2.5 rounded-md">
                    <p className="text-xs text-gray-600 font-semibold mb-1.5">Requirements:</p>
                    <ul className="list-none space-y-1">
                      {offer.offerTypes && typeof offer.offerTypes === 'object' ? (
                        <>
                          {offer.offerTypes['1story'] && (
                            <li className="flex items-center text-xs text-gray-600">
                              <span className="w-1 h-1 bg-blue-500 rounded-full mr-1.5"></span>
                              1 Story {offer.offerTypes['1story']}
                            </li>
                          )}
                          {offer.offerTypes['2stories'] && (
                            <li className="flex items-center text-xs text-gray-600">
                              <span className="w-1 h-1 bg-blue-500 rounded-full mr-1.5"></span>
                              2 Stories {offer.offerTypes['2stories']}
                            </li>
                          )}
                          {offer.offerTypes['storiesPost'] && (
                            <li className="flex items-center text-xs text-gray-600">
                              <span className="w-1 h-1 bg-blue-500 rounded-full mr-1.5"></span>
                              Stories + 1 Post {offer.offerTypes['storiesPost']}
                            </li>
                          )}
                        </>
                      ) : (
                        <li className="text-xs text-gray-600">Offer type not specified</li>
                      )}
                    </ul>
                    {offer.compensation && (
                      <div className="mt-2 pt-2 border-t border-gray-200">
                        <p className="text-xs text-gray-600 font-semibold">
                          We offer: {offer.compensation}
                        </p>
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex justify-end space-x-2 w-full">
                  {renderApplicationStatus(offer.id, offer)}
                </div>
              </div>
            </div>
          ))}
        </div>
      </main>

      {/* Fullscreen Map Overlay */}
      {isFullscreenMap && (
        <div className="fixed inset-0 z-50">
          {renderMap()}
          <button
            onClick={closeFullscreenMap}
            className="absolute top-4 right-4 bg-white p-2 rounded-full shadow-lg"
          >
            <X className="h-6 w-6 text-gray-600" />
          </button>
        </div>
      )}

      {/* Offer Details Modal */}
      {showOfferDetails && selectedOffer && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-70 backdrop-blur-md flex items-start justify-center z-[9999] overflow-y-auto p-4 pt-8"
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              setShowOfferDetails(false);
            }
          }}
        >
          <div className="bg-white w-full md:max-w-[480px] md:rounded-lg relative flex flex-col mb-8">
            {/* Close button */}
            <button
              onClick={() => setShowOfferDetails(false)}
              className="absolute top-4 right-4 z-50 bg-white p-2 rounded-full shadow-lg hover:bg-gray-100 transition-colors"
            >
              <X className="h-6 w-6 text-gray-600" />
            </button>

            {/* Image Section */}
            <LazyLoadImage
              src={selectedOffer.imageUrl}
              alt={selectedOffer.title}
              effect="blur"
              className="w-full h-44 md:h-36 object-cover md:rounded-t-lg"
              wrapperClassName="w-full h-44 md:h-36"
              placeholderSrc={selectedOffer.imageUrl + '?w=10'}
            />

            {/* Content Section */}
            <div className="p-4">
              {/* Title and Location */}
              <div className="mb-3">
                <h2 className="text-lg font-bold text-gray-800">{selectedOffer.title}</h2>
                <div className="flex items-center text-gray-600 mt-1 text-sm">
                  <Pin className="w-4 h-4 mr-1" />
                  <span>{selectedOffer.city}</span>
                </div>
              </div>

              {/* Description */}
              {cleanDescription(selectedOffer.description) && (
                <div className="mb-4">
                  <div className="text-gray-700 text-sm rich-text-content">
                    {parse(cleanDescription(selectedOffer.description))}
                  </div>
                </div>
              )}

              {/* Requirements Section */}
              <div className="space-y-3">
                <div className="bg-gray-50 p-3 rounded-lg">
                  <h3 className="font-semibold text-gray-800 mb-2 text-sm">Requirements</h3>
                  <ul className="space-y-1.5 text-sm">
                    {selectedOffer.offerTypes && Object.entries(selectedOffer.offerTypes)
                      .filter(([_, value]) => value)
                      .map(([key, value]) => (
                        <li key={key} className="flex items-center text-gray-700">
                          <span className="w-1.5 h-1.5 bg-blue-500 rounded-full mr-2"></span>
                          {key === '1story' && '1 Story'}
                          {key === '2stories' && '2 Stories'}
                          {key === 'storiesPost' && 'Stories + 1 Post'}
                          {value !== true && ` - ${value}`}
                        </li>
                    ))}
                  </ul>
                </div>

                {/* Compensation Section */}
                {selectedOffer.compensation && (
                  <div className="bg-green-50 p-3 rounded-lg">
                    <h3 className="font-semibold text-gray-800 mb-2 text-sm">Compensation</h3>
                    <p className="text-gray-700 text-sm">{selectedOffer.compensation}</p>
                  </div>
                )}

                {/* Location Details */}
                {selectedOffer.address && (
                  <div className="bg-gray-50 p-3 rounded-lg">
                    <h3 className="font-semibold text-gray-800 mb-2 text-sm">Location</h3>
                    <p className="text-gray-700 mb-2 text-sm">{selectedOffer.address}</p>
                    <a 
                      href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(selectedOffer.address)}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-flex items-center text-blue-500 hover:text-blue-600 transition-colors text-sm"
                    >
                      <Navigation className="w-4 h-4 mr-1" />
                      Open in Google Maps
                    </a>
                  </div>
                )}
              </div>

              {/* Action Button */}
              <div className="mt-4">
                {renderApplicationStatus(selectedOffer.id, selectedOffer)}
              </div>
            </div>
          </div>
        </div>
      )}
      {showChatModal && selectedOffer && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-8 rounded-lg max-w-2xl w-full">
            <h2 className="text-2xl font-bold mb-4">Chat with Brand</h2>
            <div className="h-64 overflow-y-auto border rounded p-2 mb-4">
              {messages.map((msg, index) => (
                <div key={index} className={`mb-2 ${msg.senderId === auth.currentUser.uid ? 'text-right' : 'text-left'}`}>
                  <span className={`inline-block p-2 rounded ${msg.senderId === auth.currentUser.uid ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}>
                    {msg.message}
                  </span>
                </div>
              ))}
            </div>
            <div className="flex">
              <input
                type="text"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                className="flex-grow p-2 border rounded-l"
                placeholder="Type a message..."
              />
              <button
                onClick={handleSendMessage}
                className="bg-blue-500 text-white px-4 py-2 rounded-r"
              >
                Send
              </button>
            </div>
            <button
              onClick={() => setShowChatModal(false)}
              className="mt-4 bg-gray-300 text-gray-800 px-4 py-2 rounded hover:bg-gray-400"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default CreatorOfferScreen;
