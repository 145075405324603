import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { db, realtimeDb } from '../firebase';
import { useAuth } from '../context/AuthContext';
import Header from '../components/Header';
import { FaHandshake, FaCheckCircle, FaEnvelope, FaEnvelopeOpen, FaMoneyBillWave } from 'react-icons/fa';
import { ref, onValue } from 'firebase/database';
import { CardSkeleton, ListItemSkeleton } from '../components/Skeletons';
import { useNavigate } from 'react-router-dom';

const CreatorDashboard = () => {
  const { currentUser } = useAuth();
  const [stats, setStats] = useState({
    totalApplications: 0,
    acceptedApplications: 0,
    totalEarnings: 0,
    pendingPayments: 0,
    messages: {
      total: 0,
      unread: 0,
    },
    recentActivity: [],
    applicationsByStatus: {
      pending: 0,
      accepted: 0,
      rejected: 0,
      completed: 0
    }
  });
  const [loading, setLoading] = useState(true);
  const [creatorProfile, setCreatorProfile] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Dashboard | Behave';
  }, []);

  useEffect(() => {
    const fetchMessagesData = async () => {
      const user = currentUser;
      if (!user) return;

      // Listen for unread messages
      const unreadMessagesRef = ref(realtimeDb, `unreadMessages/${user.uid}`);
      const unsubscribeUnreadMessages = onValue(unreadMessagesRef, (snapshot) => {
        const unreadData = snapshot.val() || {};
        // Calculate total unread messages
        const totalUnread = Object.values(unreadData).reduce((sum, count) => sum + count, 0);
        
        // Get total conversations from unread data
        const totalConversations = Object.keys(unreadData).length;

        setStats(prevStats => ({
          ...prevStats,
          messages: {
            total: totalConversations,
            unread: totalUnread
          }
        }));
      });

      return () => {
        unsubscribeUnreadMessages();
      };
    };



    const fetchDashboardData = async () => {
      try {
        // Fetch creator profile
        const creatorDoc = await getDoc(doc(db, 'creators', currentUser.uid));
        setCreatorProfile(creatorDoc.data());

        // Fetch applications
        const applicationsQuery = query(
          collection(db, 'applications'),
          where('creatorId', '==', currentUser.uid)
        );
        const applicationsSnapshot = await getDocs(applicationsQuery);
        const applications = applicationsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        // Calculate stats
        const applicationStats = applications.reduce((acc, app) => {
          acc.applicationsByStatus[app.status] = (acc.applicationsByStatus[app.status] || 0) + 1;
          if (app.status === 'accepted') {
            acc.acceptedApplications++;
            acc.totalEarnings += app.payment?.amount || 0;
            if (!app.payment?.paid) {
              acc.pendingPayments += app.payment?.amount || 0;
            }
          }
          return acc;
        }, {
          applicationsByStatus: {},
          acceptedApplications: 0,
          totalEarnings: 0,
          pendingPayments: 0
        });

        setStats(prevStats => ({
          ...prevStats,
          totalApplications: applications.length,
          acceptedApplications: applicationStats.acceptedApplications,
          totalEarnings: applicationStats.totalEarnings,
          pendingPayments: applicationStats.pendingPayments,
          applicationsByStatus: applicationStats.applicationsByStatus,
          recentActivity: applications
            .sort((a, b) => b.createdAt?.toDate() - a.createdAt?.toDate())
            .slice(0, 5)
        }));

        setLoading(false);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
        setLoading(false);
      }
    };

    fetchDashboardData();
    const unsubscribeMessages = fetchMessagesData();

    return () => {
      if (unsubscribeMessages) {
        unsubscribeMessages.then(unsub => unsub && unsub());
      }
    };
  }, [currentUser]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-100">
        <Header userType="creator" />
        <main className="pt-28 md:pt-20 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-2">
          <div className="animate-pulse mb-4">
            <div className="h-8 bg-gray-200 rounded w-1/4"></div>
            <div className="h-4 bg-gray-200 rounded w-1/3 mt-2"></div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
            {[...Array(4)].map((_, i) => (
              <CardSkeleton key={i} />
            ))}
          </div>
          <div className="bg-white rounded-lg shadow p-6">
            <div className="h-6 bg-gray-200 rounded w-1/4 mb-4"></div>
            <div className="space-y-4">
              {[...Array(5)].map((_, i) => (
                <ListItemSkeleton key={i} />
              ))}
            </div>
          </div>
        </main>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <Header userType="creator" />
      <main className="pt-28 md:pt-20 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-2">
        {/* Welcome Section */}
        <div className="flex justify-between items-center mb-3 flex-wrap gap-4">
          <div>
            <h1 className="text-xl font-bold mb-3 text-gray-600">
              Welcome back, {creatorProfile?.name}
            </h1>
            <p className="mt-1 text-sm text-gray-500">
              Here's what's happening with your applications
            </p>
          </div>
          <button
            onClick={() => navigate('/offers')}
            className=" bg-slate-700 text-white px-4 py-2 rounded-lg hover:bg-slate-800 transition-colors text-sm"
          >
            Browse New Offers
          </button>
        </div>

        {/* Stats Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
          {/* Applications Card */}
          <div className="bg-white p-6 rounded-lg shadow">
            <div className="flex items-center justify-between mb-4">
              <div>
                <p className="text-sm font-medium text-gray-600">Total Applications</p>
                <p className="text-2xl font-semibold text-gray-900">{stats.totalApplications}</p>
              </div>
              <FaHandshake className="h-8 w-8 text-blue-500" />
            </div>
            <p className="text-sm text-gray-600 mb-4">
              {stats.applicationsByStatus.pending || 0} pending
            </p>
            <button
              onClick={() => navigate('/applied-offers')}
              className="w-full bg-slate-700 text-white px-4 py-2 rounded-lg hover:bg-slate-800 transition-colors text-sm"
            >
              View Applications
            </button>
          </div>

          {/* Accepted Applications Card */}
          <div className="bg-white p-6 rounded-lg shadow">
            <div className="flex items-center justify-between mb-4">
              <div>
                <p className="text-sm font-medium text-gray-600">Accepted Applications</p>
                <p className="text-2xl font-semibold text-gray-900">{stats.acceptedApplications}</p>
              </div>
              <FaCheckCircle className="h-8 w-8 text-green-500" />
            </div>
            <p className="text-sm text-gray-600 mb-4">
              {((stats.acceptedApplications / stats.totalApplications) * 100).toFixed(1)}% success rate
            </p>
            <button
              onClick={() => navigate('/applied-offers')}
              className="w-full bg-slate-700 text-white px-4 py-2 rounded-lg hover:bg-slate-800 transition-colors text-sm"
            >
              View Collaborations
            </button>
          </div>

          {/* Earnings Card */}
          <div className="bg-white p-6 rounded-lg shadow">
            <div className="flex items-center justify-between mb-4">
              <div>
                <p className="text-sm font-medium text-gray-600">Total Earnings</p>
                <p className="text-2xl font-semibold text-gray-900">${stats.totalEarnings}</p>
              </div>
              <FaMoneyBillWave className="h-8 w-8 text-green-500" />
            </div>
            <p className="text-sm text-gray-600 mb-4">
              ${stats.pendingPayments} pending
            </p>
            <button
              onClick={() => navigate('/earnings')}
              className="w-full bg-white-500 text-slate-700 px-4 py-2 rounded-lg hover:bg-slate-100 transition-colors text-sm"
            >
              View Earnings (soon)
            </button>
          </div>

          {/* Messages Card */}
          <div className="bg-white p-6 rounded-lg shadow">
            <div className="flex items-center justify-between mb-4">
              <div>
                <p className="text-sm font-medium text-gray-600">Messages</p>
                <p className="text-2xl font-semibold text-gray-900">{stats.messages.total}</p>
              </div>
              <div className="flex space-x-2">
                <FaEnvelopeOpen className="h-8 w-8 text-gray-400" />
                {stats.messages.unread > 0 && (
                  <span className="bg-red-500 text-white text-xs px-3 py-2 rounded-full">
                    {stats.messages.unread}
                  </span>
                )}
              </div>
            </div>
            <p className="text-sm text-gray-600 mb-4">
              {stats.messages.unread} unread messages
            </p>
            <button
              onClick={() => navigate('/messages')}
              className="w-full bg-slate-700 text-white px-4 py-2 rounded-lg hover:bg-slate-800 transition-colors text-sm"
            >
              View Messages
            </button>
          </div>
        </div>

        {/* Recent Activity */}
        <div className="bg-white rounded-lg shadow">
          <div className="p-6">
            <h2 className="text-lg font-semibold text-gray-900 mb-4">Recent Activity</h2>
            <div className="space-y-4">
              {stats.recentActivity.map((activity, index) => (
                <div key={index} className="flex items-center space-x-4">
                  {activity.type === 'application' ? (
                    <FaHandshake className="h-5 w-5 text-blue-500" />
                  ) : (
                    <FaEnvelope className="h-5 w-5 text-gray-500" />
                  )}
                  <div>
                    <p className="text-sm font-medium text-gray-900">
                      {activity.type === 'application' ? 
                        `Applied to ${activity.offerTitle}` : 
                        'New message'}
                    </p>
                    <p className="text-xs text-gray-500">
                      {new Date(activity.createdAt?.toDate()).toLocaleDateString()}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CreatorDashboard;
