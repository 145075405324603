import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { getStorage, ref, uploadString, getDownloadURL, uploadBytes } from 'firebase/storage';
import { auth, db } from '../firebase';
import { useToast } from '../context/ToastContext';
import { useAuth } from '../context/AuthContext';
import { useLoadScript, Autocomplete } from '@react-google-maps/api';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { TextBlockSkeleton } from '../components/Skeletons';
import { FaInstagram, FaTiktok, FaYoutube } from 'react-icons/fa';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const OnboardingScreen = () => {
  const { addToast } = useToast();
  const { onboardingCompleted, setOnboardingCompleted, currentUser } = useAuth();
  const [profile, setProfile] = useState({
    name: '',
    email: '',
    bio: '',
    location: '',
    joinReason: '', // Add this new field
    socialMedia: {
      instagram: '',
      tiktok: '',
      youtube: '',
    },
    profilePicture: '',
  });
  const [brandProfile, setBrandProfile] = useState({
    name: '',
    email: '',
    bio: '',
    location: '',
    website: '',
    venueName: '',
    venueType: '',
    contactPhone: '',
    services: [],
    ownerName: '',
    ownerPhone: '',
    ownerEmail: '',
    businessName: '', // Added new field
    fiscalCode: '',   // Added new field
    venueDetails: null,
    profilePicture: '',  // Add this field
  });
  const [userType, setUserType] = useState(null);
  const [step, setStep] = useState(1);
  const navigate = useNavigate();

  // Add Google Maps loading
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
  });

  // Add ref for autocomplete
  const autocompleteRef = useRef(null);

  // Add place selection handler
  const handlePlaceSelect = (isBrand = false) => {
    const place = autocompleteRef.current.getPlace();
    let cityName = '';
    
    place.address_components.forEach((component) => {
      if (component.types.includes('locality')) {
        cityName = component.long_name;
      }
    });

    if (isBrand) {
      setBrandProfile(prev => ({ ...prev, location: cityName }));
    } else {
      setProfile(prev => ({ ...prev, location: cityName }));
    }
  };

  // Add this helper function at the top level of your component
  const getPlaceDetails = (placeId) => {
    return new Promise((resolve, reject) => {
      const service = new window.google.maps.places.PlacesService(document.createElement('div'));
      service.getDetails({ placeId: placeId }, (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          resolve(place);
        } else {
          reject(status);
        }
      });
    });
  };

  // Add state for manual venue input
  const [isManualVenue, setIsManualVenue] = useState(false);

  // Update the venue selection handler
  const handleVenueSelect = async (place) => {
    if (!place) return;

    try {
      const placeDetails = await getPlaceDetails(place.value.place_id);
      const venueData = {
        name: placeDetails.name,
        address: placeDetails.formatted_address,
        city: placeDetails.address_components.find(component => 
          component.types.includes('locality'))?.long_name || '',
        openingHours: placeDetails.opening_hours?.weekday_text || [],
        placeId: placeDetails.place_id,
        location: {
          lat: placeDetails.geometry.location.lat(),
          lng: placeDetails.geometry.location.lng()
        },
        phoneNumber: placeDetails.formatted_phone_number || '',
        website: placeDetails.website || '',
        types: placeDetails.types || []
      };

      setBrandProfile(prev => ({
        ...prev,
        venueName: venueData.name,
        venueDetails: venueData // Store all venue details for later use
      }));
    } catch (error) {
      console.error('Error fetching place details:', error);
      addToast('Error fetching venue details. You can enter them manually.', 'error');
      setIsManualVenue(true);
    }
  };

  useEffect(() => {
    const fetchUserType = async () => {
      const user = auth.currentUser;
      if (user) {
        const creatorDoc = await getDoc(doc(db, 'creators', user.uid));
        const brandDoc = await getDoc(doc(db, 'brands', user.uid));
        if (creatorDoc.exists()) {
          setUserType('creator');
          setProfile(prev => ({ ...prev, ...creatorDoc.data() }));
        } else if (brandDoc.exists()) {
          setUserType('brand');
          setBrandProfile(prev => ({ ...prev, ...brandDoc.data() }));
        } else {
          addToast('User type not found. Please log in again.', 'error');
          navigate('/login');
        }
      } else {
        navigate('/login');
      }
    };
    fetchUserType();
  }, [navigate, addToast]);

  useEffect(() => {
    const checkOnboardingStatus = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDoc = await getDoc(doc(db, userType === 'creator' ? 'creators' : 'brands', user.uid));
        if (userDoc.exists() && userDoc.data().onboardingCompleted) {
          navigate('/dashboard');
        }
      }
    };

    checkOnboardingStatus();
  }, [userType, navigate]);

  const handleChange = (e, isBrand = false) => {
    const { name, value } = e.target;
    if (isBrand) {
      setBrandProfile(prev => ({ ...prev, [name]: value }));
    } else {
      if (name.startsWith('socialMedia.')) {
        const [, platform] = name.split('.');
        setProfile(prev => ({
          ...prev,
          socialMedia: { ...prev.socialMedia, [platform]: value }
        }));
      } else {
        setProfile(prev => ({ ...prev, [name]: value }));
      }
    }
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        try {
          const storage = getStorage();
          const storageRef = ref(storage, `profilePictures/${auth.currentUser.uid}`);
          await uploadString(storageRef, reader.result, 'data_url');
          const downloadURL = await getDownloadURL(storageRef);
          setProfile(prev => ({ ...prev, profilePicture: downloadURL }));
        } catch (error) {
          console.error('Error uploading image:', error);
          addToast('Error uploading image: ' + error.message, 'error');
        }
      };
      reader.readAsDataURL(file);
    }
  };

  // Add loading state to component
  const [loading, setLoading] = useState(false);

  // Update handleSubmit to use loading state
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (step < getTotalSteps()) {
      setStep(step + 1);
      return;
    }

    setLoading(true);
    try {
      const collectionName = userType === 'creator' ? 'creators' : 'brands';
      const docRef = doc(db, collectionName, auth.currentUser.uid);
      
      const dataToUpdate = {
        ...(userType === 'creator' ? {
          name: profile.name,
          email: profile.email,
          bio: profile.bio,
          location: profile.location,
          joinReason: profile.joinReason,
          socialMedia: profile.socialMedia,
          profilePicture: profile.profilePicture,
          onboardingCompleted: true,
          updatedAt: new Date()
        } : {
          ...brandProfile,
          profilePicture: brandProfile.profilePicture, // Make sure this is included
          onboardingCompleted: true,
          updatedAt: new Date()
        }),
        onboardingCompleted: true,
        updatedAt: new Date()
      };

      await updateDoc(docRef, dataToUpdate);
      setOnboardingCompleted(true);
      addToast('Profile completed successfully!', 'success');
      navigate('/dashboard');
    } catch (error) {
      console.error('Error updating profile:', error);
      addToast(`Error updating profile: ${error.message}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const getTotalSteps = () => {
    return userType === 'creator' ? 4 : 5;
  };

  const renderCreatorStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            <h2 className="text-xl font-semibold text-gray-900 mb-6">Basic Information</h2>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Full Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  value={profile.name}
                  onChange={(e) => handleChange(e, false)}
                  placeholder="Enter your full name"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent sm:text-sm"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Email
                </label>
                <input
                  type="email"
                  value={auth.currentUser?.email || ''}
                  disabled
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50 text-gray-500 sm:text-sm"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  City <span className="text-red-500">*</span>
                </label>
                {isLoaded ? (
                  <Autocomplete
                    onLoad={autocomplete => {
                      autocompleteRef.current = autocomplete;
                    }}
                    onPlaceChanged={() => {
                      const place = autocompleteRef.current.getPlace();
                      for (const component of place.address_components) {
                        if (component.types.includes('locality')) {
                          setProfile(prev => ({
                            ...prev,
                            location: component.long_name
                          }));
                        }
                      }
                    }}
                  >
                    <input
                      type="text"
                      placeholder="Enter your city"
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent sm:text-sm"
                    />
                  </Autocomplete>
                ) : (
                  <input
                    type="text"
                    placeholder="Loading Google Places..."
                    disabled
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50 text-gray-500 sm:text-sm"
                  />
                )}
              </div>
            </div>
          </>
        );

      case 2:
        return (
          <>
            <h2 className="text-xl font-semibold text-gray-900 mb-6">Social Media Links</h2>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Instagram Profile <span className="text-red-500">*</span>
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <FaInstagram className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="url"
                    name="instagram"
                    value={profile.socialMedia.instagram}
                    onChange={(e) => handleSocialMediaChange(e)}
                    placeholder="https://instagram.com/username"
                    required
                    className="appearance-none block w-full pl-10 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  TikTok Profile
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <FaTiktok className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="url"
                    name="tiktok"
                    value={profile.socialMedia.tiktok}
                    onChange={(e) => handleSocialMediaChange(e)}
                    placeholder="https://tiktok.com/@username"
                    className="appearance-none block w-full pl-10 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  YouTube Channel
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <FaYoutube className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="url"
                    name="youtube"
                    value={profile.socialMedia.youtube}
                    onChange={(e) => handleSocialMediaChange(e)}
                    placeholder="https://youtube.com/c/channel"
                    className="appearance-none block w-full pl-10 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent sm:text-sm"
                  />
                </div>
              </div>
            </div>
          </>
        );

      case 3:
        return (
          <>
            <h2 className="text-xl font-semibold text-gray-900 mb-6"> <span className="text-red-500">*</span>Profile Picture</h2>
            <div className="space-y-6">
              <div>
                <div className="flex flex-col items-center space-x-6">
                  <div className="relative inline-block w-[100px] h-[100px]">
                    <img
                      src={profile.profilePicture || 'https://via.placeholder.com/150'}
                      alt="Profile"
                      className="h-24 w-24 rounded-full object-cover ring-2 ring-white shadow-md"
                    />
                    <label
                      htmlFor="profile-upload"
                      className="absolute bottom-0 right-0 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-4 w-4">
                        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                      </svg>
                      <input
                        id="profile-upload"
                        type="file"
                        className="hidden"
                        accept="image/*"
                        onChange={onSelectFile}
                        required={!profile.profilePicture}
                      />
                    </label>
                  </div>
                  <div className="text-sm text-gray-600">
                    <p>Upload a profile picture</p>
                    <p>Recommended: Square image, at least 400x400px</p>
                  </div>
                </div>
              </div>
            </div>
            {/* Image Crop Modal */}
            {ImageCropModal}
          </>
        );

      case 4:
        return (
          <>
            <h2 className="text-xl font-semibold text-gray-900 mb-6">Profile Details</h2>
            <div className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Tell about yourself <span className="text-red-500">*</span>
                </label>
                <textarea
                  name="bio"
                  value={profile.bio}
                  onChange={(e) => handleChange(e, false)}
                  placeholder="Share your story, experience, and what makes you unique"
                  required
                  rows="4"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent sm:text-sm"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Why did you join Behave?
                </label>
                <textarea
                  name="joinReason"
                  value={profile.joinReason || ''}
                  onChange={(e) => handleChange(e, false)}
                  placeholder="Tell us why you decided to join Behave"
                  rows="3"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent sm:text-sm"
                />
              </div>
            </div>
          </>
        );

      default:
        return null;
    }
  };

  const renderBrandStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            <h2 className="text-xl font-semibold text-gray-900 mb-6">Basic Information</h2>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Brand Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  value={brandProfile.name}
                  onChange={(e) => handleChange(e, true)}
                  placeholder="Enter your brand name"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent sm:text-sm"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Email
                </label>
                <input
                  type="email"
                  value={auth.currentUser?.email || ''}
                  disabled
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50 text-gray-500 sm:text-sm"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  City <span className="text-red-500">*</span>
                </label>
                {isLoaded ? (
                  <Autocomplete
                    onLoad={autocomplete => {
                      autocompleteRef.current = autocomplete;
                    }}
                    restrictions={{ types: ['(cities)'] }}
                    onPlaceChanged={() => handlePlaceSelect(true)}
                  >
                    <input
                      type="text"
                      placeholder="Enter your city"
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent sm:text-sm"
                    />
                  </Autocomplete>
                ) : (
                  <input
                    type="text"
                    placeholder="Loading Google Places..."
                    disabled
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50 text-gray-500 sm:text-sm"
                  />
                )}
              </div>
            </div>
          </>
        );

      case 2:
        return (
          <>
            <h2 className="text-xl font-semibold text-gray-900 mb-6">Venue Information</h2>
            {!isManualVenue ? (
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Search for your venue <span className="text-red-500">*</span>
                  </label>
                  {isLoaded ? (
                    <Autocomplete
                      onLoad={autocomplete => {
                        autocompleteRef.current = autocomplete;
                      }}
                      restrictions={{ types: ['establishment'] }}
                      onPlaceChanged={() => {
                        const place = autocompleteRef.current.getPlace();
                        if (place.place_id) {
                          handleVenueSelect({ value: { place_id: place.place_id } });
                        }
                      }}
                    >
                      <input
                        type="text"
                        placeholder="Search for your venue..."
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent sm:text-sm"
                      />
                    </Autocomplete>
                  ) : (
                    <input
                      type="text"
                      placeholder="Loading Google Places..."
                      disabled
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50 text-gray-500 sm:text-sm"
                    />
                  )}
                  <button
                    type="button"
                    onClick={() => setIsManualVenue(true)}
                    className="mt-2 text-sm text-blue-500 hover:text-blue-700"
                  >
                    Can't find your venue? Enter details manually
                  </button>
                </div>
              </div>
            ) : (
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Venue Name <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="venueName"
                    value={brandProfile.venueName}
                    onChange={(e) => handleChange(e, true)}
                    placeholder="Enter venue name"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent sm:text-sm"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Venue Type <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="venueType"
                    value={brandProfile.venueType}
                    onChange={(e) => handleChange(e, true)}
                    placeholder="Ex: Restaurant, Bar, Club"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent sm:text-sm"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Contact Phone <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="tel"
                    name="contactPhone"
                    value={brandProfile.contactPhone}
                    onChange={(e) => handleChange(e, true)}
                    placeholder="Enter venue contact number"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent sm:text-sm"
                  />
                </div>

                <button
                  type="button"
                  onClick={() => setIsManualVenue(false)}
                  className="mt-2 text-sm text-blue-500 hover:text-blue-700"
                >
                  Search for venue instead
                </button>
              </div>
            )}
          </>
        );

      case 3:
        return (
          <>
            <h2 className="text-xl font-semibold text-gray-900 mb-6">Owner Information</h2>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Owner Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="ownerName"
                  value={brandProfile.ownerName}
                  onChange={(e) => handleChange(e, true)}
                  placeholder="Enter owner's full name"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent sm:text-sm"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Owner Phone <span className="text-red-500">*</span>
                </label>
                <input
                  type="tel"
                  name="ownerPhone"
                  value={brandProfile.ownerPhone}
                  onChange={(e) => handleChange(e, true)}
                  placeholder="Enter owner's contact number"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent sm:text-sm"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Owner Email <span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  name="ownerEmail"
                  value={brandProfile.ownerEmail}
                  onChange={(e) => handleChange(e, true)}
                  placeholder="Enter owner's email"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent sm:text-sm"
                />
              </div>
            </div>
          </>
        );

      case 4:
        return (
          <>
            <h2 className="text-xl font-semibold text-gray-900 mb-6">Business Information & Profile Picture</h2>
            <div className="space-y-6">
              {/* Business Information Section */}
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Business Name (SRL / SA) <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="businessName"
                    value={brandProfile.businessName}
                    onChange={(e) => handleChange(e, true)}
                    placeholder="Ex: Company Name SRL"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent sm:text-sm"
                  />
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Fiscal Code Number (CIF) <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="fiscalCode"
                    value={brandProfile.fiscalCode}
                    onChange={(e) => handleChange(e, true)}
                    placeholder="Ex: RO12345678"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent sm:text-sm"
                  />
                  <p className="text-sm text-gray-500 mt-1">
                    Enter your company's fiscal identification code (CIF/CUI)
                  </p>
                </div>
              </div>

              {/* Profile Picture Section */}
              <div className="pt-4 border-t border-gray-200">
                <label className="block text-sm font-medium text-gray-700 mb-3">
                  Brand Logo / Profile Picture <span className="text-red-500">*</span>
                </label>
                <div className="flex flex-col items-center space-y-4">
                  <div className="relative inline-block w-[100px] h-[100px]">
                    <img
                      src={brandProfile.profilePicture || 'https://via.placeholder.com/150'}
                      alt="Profile"
                      className="h-24 w-24 rounded-full object-cover ring-2 ring-white shadow-md"
                    />
                    <label
                      htmlFor="brand-profile-upload"
                      className="absolute bottom-0 right-0 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-4 w-4">
                        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                      </svg>
                      <input
                        id="brand-profile-upload"
                        type="file"
                        className="hidden"
                        accept="image/*"
                        onChange={(e) => {
                          // Use the same image upload handler but for brand profile
                          onSelectFile(e, true);
                        }}
                        required={!brandProfile.profilePicture}
                      />
                    </label>
                  </div>
                  <div className="text-sm text-gray-600 text-center">
                    <p>Upload your brand logo or profile picture</p>
                    <p>Recommended: Square image, at least 400x400px</p>
                  </div>
                </div>
              </div>
            </div>
            {/* Image Crop Modal */}
            {ImageCropModal}
          </>
        );

      case 5:
        return (
          <>
            <h2 className="text-xl font-semibold text-gray-900 mb-6">Additional Information</h2>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  About Your Brand <span className="text-red-500">*</span>
                </label>
                <textarea
                  name="bio"
                  value={brandProfile.bio}
                  onChange={(e) => handleChange(e, true)}
                  placeholder="Tell us about your brand, its values, and what makes it unique"
                  required
                  rows="4"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent sm:text-sm"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Website
                </label>
                <input
                  type="url"
                  name="website"
                  value={brandProfile.website}
                  onChange={(e) => handleChange(e, true)}
                  placeholder="https://www.example.com"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent sm:text-sm"
                />
              </div>
            </div>
          </>
        );

      default:
        return null;
    }
  };

  const inputClassName = "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent sm:text-sm";
  const buttonClassName = "w-full flex justify-center py-2.5 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-slate-800 hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500 transition-colors duration-200";
  const secondaryButtonClassName = "w-full flex justify-center py-2.5 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500 transition-colors duration-200";

  // Add these new states
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const imageRef = useRef(null);

  // Add state for tracking brand uploads
  const [isBrandUpload, setIsBrandUpload] = useState(false);

  // Add the social media change handler
  const handleSocialMediaChange = (e) => {
    const { name, value } = e.target;
    setProfile(prev => ({
      ...prev,
      socialMedia: {
        ...prev.socialMedia,
        [name]: value
      }
    }));
  };

  // Update the onSelectFile function to handle both creator and brand uploads
  const onSelectFile = (e, isBrand = false) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImageSrc(reader.result);
        setIsModalOpen(true);
        // Store whether this is a brand upload
        setIsBrandUpload(isBrand);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // Add the image cropping functions
  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    const crop = centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        1, // aspect ratio
        width,
        height
      ),
      width,
      height
    );
    setCrop(crop);
  };

  // Add function to get cropped image
  const getCroppedImg = async (image, crop) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = crop.width;
    canvas.height = crop.height;

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve) => {
      canvas.toBlob(
        (blob) => {
          resolve(blob);
        },
        'image/jpeg',
        1
      );
    });
  };

  // Update handleCropComplete to handle both creator and brand profiles
  const handleCropComplete = async () => {
    if (!completedCrop || !imageRef.current) return;

    try {
      const croppedImageBlob = await getCroppedImg(
        imageRef.current,
        completedCrop
      );
      
      const userId = currentUser?.uid || auth.currentUser?.uid;
      if (!userId) {
        throw new Error('No user ID found');
      }

      const storage = getStorage();
      const storageRef = ref(storage, `profile-pictures/${userId}`);
      await uploadBytes(storageRef, croppedImageBlob);
      const downloadURL = await getDownloadURL(storageRef);

      if (isBrandUpload) {
        setBrandProfile(prev => ({
          ...prev,
          profilePicture: downloadURL
        }));
      } else {
        setProfile(prev => ({
          ...prev,
          profilePicture: downloadURL
        }));
      }

      setIsModalOpen(false);
      setImageSrc(null);
      setCrop(undefined);
      setCompletedCrop(null);
    } catch (error) {
      console.error('Error uploading image:', error);
      addToast('Error uploading image', 'error');
    }
  };

  // Add the Image Crop Modal component
  const ImageCropModal = useMemo(() => {
    if (!isModalOpen) return null;

    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white p-6 rounded-lg max-w-2xl w-full">
          <h3 className="text-lg font-medium mb-4">Crop Profile Picture</h3>
          {imageSrc && (
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={1}
              circularCrop
            >
              <img
                ref={imageRef}
                alt="Crop me"
                src={imageSrc}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          )}
          <div className="mt-4 flex justify-end space-x-2">
            <button
              onClick={() => {
                setIsModalOpen(false);
                setImageSrc(null);
              }}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              onClick={handleCropComplete}
              className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }, [isModalOpen, imageSrc, crop, completedCrop]);

  // Add loading state check at the beginning of render
  if (!userType) {
    return (
      <div className="bg-gradient-to-b from-[rgba(205,212,213,1)] to-[rgba(255,255,255,0)] min-h-screen flex flex-col justify-center px-4 py-2 sm:px-6 lg:px-8">
        <div className="w-full max-w-[440px] mx-auto">
          <div className="bg-white/80 backdrop-blur-sm py-8 px-6 shadow-lg rounded-xl sm:px-8 border border-white/20">
            <TextBlockSkeleton />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gradient-to-b from-[rgba(205,212,213,1)] to-[rgba(255,255,255,0)] min-h-screen flex flex-col justify-center px-4 py-2 sm:px-6 lg:px-8">
      <div className="w-full max-w-[440px] mx-auto">
        <h2 className="mt-6 text-center text-3xl font-bold text-gray-900">
          Welcome to <span className="text-slate-800">Behave</span>
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          {userType === 'creator' ? 'Complete your creator profile' : 'Set up your brand account'}
        </p>
      </div>

      <div className="mt-8 w-full max-w-[440px] mx-auto">
        <div className="bg-white/80 backdrop-blur-sm py-8 px-6 shadow-lg rounded-xl sm:px-8 border border-white/20">
          {/* Progress Steps */}
          <div className="mb-8">
            <div className="relative">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-300"></div>
              </div>
              <div className="relative flex justify-between">
                {Array.from({ length: getTotalSteps() }, (_, i) => i + 1).map((s) => (
                  <div
                    key={s}
                    className={`w-8 h-8 flex items-center justify-center rounded-full ${
                      s < step ? 'bg-green-500' :
                      s === step ? 'bg-slate-800 ring-4 ring-slate-800/30' :
                      'bg-gray-200'
                    } transition-all duration-200`}
                  >
                    <span className={`text-sm font-medium ${s <= step ? 'text-white' : 'text-gray-600'}`}>
                      {s < step ? '✓' : s}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            {userType === 'creator' ? renderCreatorStep() : renderBrandStep()}

            <div className="flex gap-3 mt-8">
              {step > 1 && (
                <button
                  type="button"
                  onClick={() => setStep(step - 1)}
                  className={secondaryButtonClassName}
                >
                  Back
                </button>
              )}
              <button
                type="submit"
                className={buttonClassName}
              >
                {step === getTotalSteps() ? (
                  loading ? (
                    <AiOutlineLoading3Quarters className="w-5 h-5 animate-spin" />
                  ) : (
                    'Complete Setup'
                  )
                ) : (
                  'Continue'
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default OnboardingScreen;
