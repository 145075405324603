import React, { useState, useEffect, useRef } from 'react';
import { collection, query, where, onSnapshot, doc, getDoc, updateDoc, addDoc, serverTimestamp } from 'firebase/firestore';
import { ref, push, set, onValue, off, get, remove } from 'firebase/database';
import { auth, db, realtimeDb } from '../firebase';
import Header from '../components/Header';
import { useToast } from '../context/ToastContext';
import { CardSkeleton } from '../components/Skeletons';
import { useLocation } from 'react-router-dom';

const MessagesScreen = () => {
  const { addToast } = useToast();
  const [activeOffers, setActiveOffers] = useState([]);
  const [pendingOffers, setPendingOffers] = useState([]);
  const [endedCollaborations, setEndedCollaborations] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [userType, setUserType] = useState(null);
  const [unreadCounts, setUnreadCounts] = useState({});
  const messagesEndRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const fetchUserTypeAndOffers = async () => {
      const user = auth.currentUser;
      if (user) {
        const creatorDoc = await getDoc(doc(db, 'creators', user.uid));
        const brandDoc = await getDoc(doc(db, 'brands', user.uid));
        if (creatorDoc.exists()) {
          setUserType('creator');
          fetchOffers(user.uid, 'creator');
        } else if (brandDoc.exists()) {
          setUserType('brand');
          fetchOffers(user.uid, 'brand');
        }
      }
    };

    fetchUserTypeAndOffers();
  }, []);

  useEffect(() => {
    document.title = 'Your Messages | Behave';
  }, []);

  useEffect(() => {
    if (location.state && location.state.applicationId && location.state.offerId) {
      const { applicationId, offerId } = location.state;
      const offer = [...activeOffers, ...pendingOffers].find(o => o.id === applicationId);
      if (offer) {
        setSelectedOffer(offer);
      } else {
        // If the offer is not found in the current state, fetch it
        fetchOfferById(applicationId, offerId);
      }
    }
  }, [location, activeOffers, pendingOffers]);

  const fetchOfferById = async (applicationId, offerId) => {
    try {
      const applicationDoc = await getDoc(doc(db, 'applications', applicationId));
      if (applicationDoc.exists()) {
        const applicationData = applicationDoc.data();
        const offerDoc = await getDoc(doc(db, 'offers', offerId));
        const offerData = offerDoc.data();
        const otherUserType = userType === 'creator' ? 'brands' : 'creators';
        const otherUserId = userType === 'creator' ? applicationData.brandId : applicationData.creatorId;
        const otherUserDoc = await getDoc(doc(db, otherUserType, otherUserId));
        const offer = {
          id: applicationId,
          ...applicationData,
          offerId: offerId,
          offerTitle: offerData.title,
          otherUserName: otherUserDoc.data().name,
          otherUserProfilePicture: otherUserDoc.data().profilePicture,
        };
        setSelectedOffer(offer);
      }
    } catch (error) {
      console.error('Error fetching offer:', error);
      addToast('Error fetching offer details', 'error');
    }
  };

  const fetchOffers = (userId, type) => {
    const activeOffersQuery = query(
      collection(db, 'applications'),
      where(`${type}Id`, '==', userId),
      where('status', 'in', ['accepted', 'content_submitted'])
    );

    const pendingOffersQuery = query(
      collection(db, 'applications'),
      where(`${type}Id`, '==', userId),
      where('status', '==', 'pending')
    );

    const endedCollaborationsQuery = query(
      collection(db, 'applications'),
      where(`${type}Id`, '==', userId),
      where('status', '==', 'collaboration_completed')
    );

    const unsubscribeActive = onSnapshot(activeOffersQuery, async (snapshot) => {
      const offers = await processOffers(snapshot, type);
      setActiveOffers(offers);
      setLoading(false);
    });

    const unsubscribePending = onSnapshot(pendingOffersQuery, async (snapshot) => {
      const offers = await processOffers(snapshot, type);
      setPendingOffers(offers);
    });

    const unsubscribeEnded = onSnapshot(endedCollaborationsQuery, async (snapshot) => {
      const offers = await processOffers(snapshot, type);
      setEndedCollaborations(offers);
    });

    return () => {
      unsubscribeActive();
      unsubscribePending();
      unsubscribeEnded();
    };
  };

  const processOffers = async (snapshot, type) => {
    const offers = [];
    for (const docSnapshot of snapshot.docs) {
      const offerData = docSnapshot.data();
      const otherUserType = type === 'creator' ? 'brands' : 'creators';
      const otherUserId = type === 'creator' ? offerData.brandId : offerData.creatorId;
      const otherUserDoc = await getDoc(doc(db, otherUserType, otherUserId));
      const offerDoc = await getDoc(doc(db, 'offers', offerData.offerId));
      
      // Add null checks here
      const otherUserData = otherUserDoc.exists() ? otherUserDoc.data() : {};
      const offerDocData = offerDoc.exists() ? offerDoc.data() : {};

      offers.push({
        id: docSnapshot.id,
        ...offerData,
        otherUserName: otherUserData.name || 'Unknown User',
        otherUserProfilePicture: otherUserData.profilePicture || '/default-profile-picture.jpg',
        offerTitle: offerDocData.title || 'Untitled Offer'
      });
    }
    return offers;
  };

  useEffect(() => {
    if (activeOffers.length > 0 || pendingOffers.length > 0) {
      fetchUnreadCounts([...activeOffers, ...pendingOffers]);
    }
  }, [activeOffers, pendingOffers]);

  const fetchUnreadCounts = (offers) => {
    const user = auth.currentUser;
    if (user) {
      offers.forEach(offer => {
        const unreadRef = ref(realtimeDb, `unreadMessages/${user.uid}/${offer.id}`);
        onValue(unreadRef, (snapshot) => {
          const count = snapshot.val() || 0;
          setUnreadCounts(prev => ({ ...prev, [offer.id]: count }));
        });
      });
    }
  };

  useEffect(() => {
    if (selectedOffer) {
      const chatRef = ref(realtimeDb, `chats/${selectedOffer.id}`);
      const unsubscribe = onValue(chatRef, (snapshot) => {
        const chatData = snapshot.val();
        if (chatData) {
          const messageList = Object.entries(chatData).map(([key, value]) => ({
            id: key,
            ...value
          })).sort((a, b) => a.timestamp - b.timestamp);
          setMessages(messageList);
          setTimeout(() => {
            messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
          }, 100);
        }
      });

      // Mark messages as read
      const unreadRef = ref(realtimeDb, `unreadMessages/${auth.currentUser.uid}/${selectedOffer.id}`);
      set(unreadRef, null);

      return () => {
        off(chatRef);
        unsubscribe();
      };
    }
  }, [selectedOffer]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (newMessage.trim() === '' || !selectedOffer) return;

    try {
      const chatRef = ref(realtimeDb, `chats/${selectedOffer.id}`);
      const newMessageRef = push(chatRef);
      await set(newMessageRef, {
        senderId: auth.currentUser.uid,
        message: newMessage,
        timestamp: Date.now()
      });

      // Update unread messages for the recipient
      const recipientId = userType === 'creator' ? selectedOffer.brandId : selectedOffer.creatorId;
      const unreadRef = ref(realtimeDb, `unreadMessages/${recipientId}/${selectedOffer.id}`);
      const unreadSnapshot = await get(unreadRef);
      const currentUnread = unreadSnapshot.val() || 0;
      await set(unreadRef, currentUnread + 1);

      // Clear unread count for the current user's conversation
      const currentUserUnreadRef = ref(realtimeDb, `unreadMessages/${auth.currentUser.uid}/${selectedOffer.id}`);
      await remove(currentUserUnreadRef);

      // Update local unread counts state
      setUnreadCounts(prev => ({
        ...prev,
        [selectedOffer.id]: 0
      }));

      setNewMessage('');
      addToast('Message sent successfully', 'success');
    } catch (error) {
      console.error('Error sending message:', error);
      addToast('Failed to send message. Please try again.', 'error');
    }
  };

  const renderOfferList = (offers, title) => (
    <div className="md:col-span-1 bg-white shadow-md overflow-hidden">
      <h2 className="text-sm font-semibold text-gray-500 p-4 border-b">{title}</h2>
      <ul className="divide-y divide-gray-200 border-b">
        {offers.map((offer) => (
          <li
            key={offer.id}
            className={`p-4 hover:bg-gray-50 cursor-pointer ${selectedOffer?.id === offer.id ? 'bg-blue-50' : ''}`}
            onClick={() => {
              if (selectedOffer?.id === offer.id) {
                setSelectedOffer(null);
              } else {
                setSelectedOffer(offer);
                const unreadRef = ref(realtimeDb, `unreadMessages/${auth.currentUser.uid}/${offer.id}`);
                remove(unreadRef);
                setUnreadCounts(prev => ({
                  ...prev,
                  [offer.id]: 0
                }));
              }
            }}
          >
            <div className="flex items-center space-x-3">
              <img
                src={offer.otherUserProfilePicture}
                alt="Profile"
                className="w-10 h-10 rounded-full"
              />
              <div className="flex-grow">
                <p className="font-semibold">{offer.otherUserName}</p>
                <p className="text-sm text-gray-500">{offer.offerTitle}</p>
              </div>
              <div className="flex items-center space-x-2">
                {unreadCounts[offer.id] > 0 && (
                  <span className="bg-red-500 text-white text-xs font-bold px-2 py-1 rounded-full">
                    {unreadCounts[offer.id]}
                  </span>
                )}
                <span className="md:hidden text-blue-500 text-sm">
                  {selectedOffer?.id === offer.id ? (
                    <div className="flex items-center">
                      <span className="mr-1">Close chat</span>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clipRule="evenodd" />
                      </svg>
                    </div>
                  ) : (
                    <div className="flex items-center">
                      <span className="mr-1">Tap to chat</span>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                      </svg>
                    </div>
                  )}
                </span>
              </div>
            </div>
            {/* Show chat box immediately after selected offer on mobile */}
            {selectedOffer?.id === offer.id && (
              <div className="md:hidden">
                <div className="bg-white shadow-md rounded-lg overflow-hidden flex flex-col h-[calc(100vh-24rem)]">
                  <div className="flex-grow overflow-y-auto p-4">
                    {messages.map((message) => (
                      <div
                        key={message.id}
                        className={`mb-4 ${message.senderId === auth.currentUser.uid ? 'text-right' : 'text-left'}`}
                      >
                        <div
                          className={`inline-block p-2 rounded-lg ${
                            message.senderId === auth.currentUser.uid
                              ? 'bg-blue-500 text-white'
                              : 'bg-gray-200 text-gray-800'
                          }`}
                        >
                          <p>{message.message}</p>
                          <p className="text-xs mt-1 opacity-75">
                            {new Date(message.timestamp).toLocaleString()}
                          </p>
                        </div>
                      </div>
                    ))}
                    <div ref={messagesEndRef} />
                  </div>
                  {selectedOffer.status !== 'collaboration_completed' && (
                    <form onSubmit={handleSendMessage} className="p-4 border-t">
                      <div className="flex space-x-2">
                        <input
                          type="text"
                          value={newMessage}
                          onChange={(e) => setNewMessage(e.target.value)}
                          className="flex-grow p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                          placeholder="Type a message..."
                        />
                        <button
                          type="submit"
                          className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-200"
                        >
                          Send
                        </button>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-100">
        <Header userType={userType} />
        <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="md:col-span-1">
              {Array(5).fill().map((_, index) => (
                <CardSkeleton key={index} />
              ))}
            </div>
            <div className="md:col-span-2">
              <CardSkeleton />
            </div>
          </div>
        </main>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <Header userType={userType} />
      <main className="pt-28 md:pt-20 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-2">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* List of offers */}
          <div className="md:col-span-1">
            {renderOfferList(activeOffers, "Active Collaborations")}
            {renderOfferList(pendingOffers, "Pending Collaborations")}
            {renderOfferList(endedCollaborations, "Ended Collaborations")}
          </div>
          
          {/* Desktop chat box - hidden on mobile */}
          <div className="hidden md:flex md:col-span-2 bg-white shadow-md rounded-lg overflow-hidden flex-col h-[calc(100vh-12rem)]">
            {selectedOffer ? (
              <>
                <div className="p-4 border-b">
                  <h2 className="text-m font-semibold">
                    Chat with {selectedOffer.otherUserName}
                  </h2>
                  <p className="text-sm text-gray-500">{selectedOffer.offerTitle}</p>
                </div>
                <div className="flex-grow overflow-y-auto p-4">
                  {messages.map((message) => (
                    <div
                      key={message.id}
                      className={`mb-4 ${message.senderId === auth.currentUser.uid ? 'text-right' : 'text-left'}`}
                    >
                      <div
                        className={`inline-block p-2 rounded-lg ${
                          message.senderId === auth.currentUser.uid
                            ? 'bg-blue-500 text-white'
                            : 'bg-gray-200 text-gray-800'
                        }`}
                      >
                        <p>{message.message}</p>
                        <p className="text-xs mt-1 opacity-75">
                          {new Date(message.timestamp).toLocaleString()}
                        </p>
                      </div>
                    </div>
                  ))}
                  <div ref={messagesEndRef} />
                </div>
                {selectedOffer.status !== 'collaboration_completed' && (
                  <form onSubmit={handleSendMessage} className="p-4 border-t">
                    <div className="flex space-x-2">
                      <input
                        type="text"
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        className="flex-grow p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Type a message..."
                      />
                      <button
                        type="submit"
                        className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-200"
                      >
                        Send
                      </button>
                    </div>
                  </form>
                )}
              </>
            ) : (
              <div className="flex items-center justify-center h-full">
                <p className="text-gray-500">Select an offer to start chatting</p>
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default MessagesScreen;
