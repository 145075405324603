import React from 'react';

export const CardSkeleton = () => (
  <div className="bg-white rounded-lg shadow-md overflow-hidden animate-pulse">
    {/* Image placeholder */}
    <div className="w-full h-48 bg-gray-200"/>
    
    <div className="p-4">
      {/* City */}
      <div className="h-4 bg-gray-200 w-1/4 mb-1"/>
      
      {/* Title and navigation icon */}
      <div className="flex justify-between items-center mb-2">
        <div className="h-6 bg-gray-200 w-3/4"/>
        <div className="h-5 w-5 bg-gray-200 rounded"/>
      </div>

      {/* Description */}
      <div className="space-y-2 mb-3">
        <div className="h-4 bg-gray-200 w-full"/>
        <div className="h-4 bg-gray-200 w-5/6"/>
      </div>

      {/* Offer details box */}
      <div className="bg-gray-50 p-4 rounded-md mb-2">
        <div className="h-4 bg-gray-200 w-1/3 mb-2"/>
        <div className="space-y-2">
          <div className="h-4 bg-gray-200 w-1/2"/>
          <div className="h-4 bg-gray-200 w-2/3"/>
          <div className="h-4 bg-gray-200 w-3/4"/>
        </div>
      </div>

      {/* Apply button */}
      <div className="flex justify-between mt-2">
        <div className="h-8 bg-gray-200 w-32 rounded"/>
      </div>
    </div>
  </div>
);

export const ListItemSkeleton = () => (
  <div className="flex items-center space-x-4 animate-pulse">
    <div className="rounded-full bg-gray-200 h-10 w-10"></div>
    <div className="flex-1 space-y-2">
      <div className="h-4 bg-gray-200 rounded w-3/4"></div>
      <div className="h-4 bg-gray-200 rounded w-1/2"></div>
    </div>
  </div>
);

export const TextBlockSkeleton = () => (
  <div className="space-y-2 animate-pulse">
    <div className="h-4 bg-gray-200 rounded w-full"></div>
    <div className="h-4 bg-gray-200 rounded w-5/6"></div>
    <div className="h-4 bg-gray-200 rounded w-4/6"></div>
  </div>
);
