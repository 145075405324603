import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider } from 'firebase/auth';
import { setDoc, doc, addDoc, collection, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import zxcvbn from 'zxcvbn';
import { track } from '../utils/posthog';
import { FcGoogle } from 'react-icons/fc';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import PolicyModal from '../components/PolicyModal';
import { SiMeta } from 'react-icons/si';
import { SiInstagram } from 'react-icons/si';

const RegisterScreen = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userType, setUserType] = useState('');
  const [error, setError] = useState('');
  const [passwordStrength, setPasswordStrength] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [acceptedPrivacy, setAcceptedPrivacy] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);

  useEffect(() => {
    document.title = 'Register | Behave';
  }, []);

  useEffect(() => {
    // Handle browser autocomplete by checking input values
    const emailInput = document.getElementById('email');
    const passwordInput = document.getElementById('password');
    const confirmPasswordInput = document.getElementById('confirm-password');

    const handleAutoComplete = () => {
      if (emailInput?.value && emailInput.value !== email) {
        setEmail(emailInput.value);
      }
      if (passwordInput?.value && passwordInput.value !== password) {
        setPassword(passwordInput.value);
        checkPasswordStrength(passwordInput.value);
      }
      if (confirmPasswordInput?.value && confirmPasswordInput.value !== confirmPassword) {
        setConfirmPassword(confirmPasswordInput.value);
      }
    };

    // Check shortly after component mounts and when inputs change
    const timeoutId = setTimeout(handleAutoComplete, 300);
    
    emailInput?.addEventListener('input', handleAutoComplete);
    passwordInput?.addEventListener('input', handleAutoComplete);
    confirmPasswordInput?.addEventListener('input', handleAutoComplete);

    return () => {
      clearTimeout(timeoutId);
      emailInput?.removeEventListener('input', handleAutoComplete);
      passwordInput?.removeEventListener('input', handleAutoComplete);
      confirmPasswordInput?.removeEventListener('input', handleAutoComplete);
    };
  }, [email, password, confirmPassword]); // Include dependencies

  const handleRegister = async (e) => {
    e.preventDefault();
    setError('');

    if (!userType) {
      setError('Please select whether you are a Creator or a Brand');
      return;
    }

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    // Updated password validation
    // Check for minimum length and at least one of: special char, number, or uppercase
    if (password.length < 7 || 
        !(/[!@#$%^&*(),.?":{}|<>\-]/.test(password) || 
          /\d/.test(password) || 
          /[A-Z]/.test(password))) {
      setError('Password should be at least 7 characters long and include at least one of the following: special character, number, or uppercase letter');
      return;
    }

    if (!acceptedTerms || !acceptedPrivacy) {
      // Show error message
      return;
    }

    // Add to your existing registration data
    const registrationData = {
      // ... existing data ...
      termsAccepted: acceptedTerms,
      privacyAccepted: acceptedPrivacy,
      termsAcceptedDate: new Date().toISOString(),
      privacyAcceptedDate: new Date().toISOString(),
    };

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      
      // Track registration event
      track('user_registered', {
        method: 'email',
        userType: userType
      });

      await createUserDocument(userCredential.user, userType);
      navigate('/onboarding');
    } catch (error) {
      console.error('Registration error:', error);
      track('registration_error', {
        method: 'email',
        error: error.message
      });
      setError(error.message);
    }
  };

  const handleGoogleRegister = async () => {
    if (!userType) {
      setError('Please select whether you are a Creator or a Brand before using Google Sign-In');
      return;
    }

    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Check if user already exists
      const [creatorDoc, brandDoc] = await Promise.all([
        getDoc(doc(db, 'creators', user.uid)),
        getDoc(doc(db, 'brands', user.uid))
      ]);

      if (!creatorDoc.exists() && !brandDoc.exists()) {
        // Track registration event
        track('user_registered', {
          method: 'google',
          userType: userType
        });

        await createUserDocument(user, userType);
        navigate('/onboarding');
      } else {
        track('registration_failed', {
          reason: 'user_exists',
          method: 'google'
        });
        setError('An account with this email already exists. Please log in instead.');
      }
    } catch (error) {
      console.error('Google registration error:', error);
      track('registration_error', {
        method: 'google',
        error: error.message
      });
      setError(error.message);
    }
  };

  const handleMetaRegister = async () => {
    if (!userType) {
      setError('Please select whether you are a Creator or a Brand before using Meta Sign-In');
      return;
    }

    try {
      const provider = new FacebookAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Check if user already exists
      const [creatorDoc, brandDoc] = await Promise.all([
        getDoc(doc(db, 'creators', user.uid)),
        getDoc(doc(db, 'brands', user.uid))
      ]);

      if (!creatorDoc.exists() && !brandDoc.exists()) {
        // Track registration event
        track('user_registered', {
          method: 'meta',
          userType: userType
        });

        await createUserDocument(user, userType);
        navigate('/onboarding');
      } else {
        track('registration_failed', {
          reason: 'user_exists',
          method: 'meta'
        });
        setError('An account with this email already exists. Please log in instead.');
      }
    } catch (error) {
      console.error('Meta registration error:', error);
      track('registration_error', {
        method: 'meta',
        error: error.message
      });
      setError(error.message);
    }
  };

  const handleInstagramRegister = async () => {
    if (!userType) {
      setError('Please select whether you are a Creator or a Brand before using Instagram Sign-In');
      return;
    }

    try {
      const provider = new FacebookAuthProvider();
      // Add Instagram-specific scopes
      provider.addScope('instagram_basic');
      provider.addScope('instagram_content_publish');
      provider.addScope('instagram_manage_insights');
      provider.addScope('instagram_manage_comments');
      
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Check if user already exists
      const [creatorDoc, brandDoc] = await Promise.all([
        getDoc(doc(db, 'creators', user.uid)),
        getDoc(doc(db, 'brands', user.uid))
      ]);

      if (!creatorDoc.exists() && !brandDoc.exists()) {
        // Track registration event
        track('user_registered', {
          method: 'instagram',
          userType: userType
        });

        await createUserDocument(user, userType);
        navigate('/onboarding');
      } else {
        track('registration_failed', {
          reason: 'user_exists',
          method: 'instagram'
        });
        setError('An account with this email already exists. Please log in instead.');
      }
    } catch (error) {
      console.error('Instagram registration error:', error);
      track('registration_error', {
        method: 'instagram',
        error: error.message
      });
      setError(error.message);
    }
  };

  const createUserDocument = async (user, type) => {
    const collectionName = type === 'creator' ? 'creators' : 'brands';
    const userData = {
      email: user.email,
      userType: type,
      createdAt: new Date(),
      onboardingCompleted: false,
      credits: type === 'creator' ? 30 : 160,
      isApproved: false,
      isActive: true,
      name: user.displayName || '',
      profilePicture: user.photoURL || '',
      normalizedName: (user.displayName || '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^a-z0-9-]/g, '')
    };

    try {
      // Create user document
      await setDoc(doc(db, collectionName, user.uid), userData);
      
      // Create audit log
      await addDoc(collection(db, 'audit'), {
        action: 'user_registered',
        userId: user.uid,
        userType: type,
        initialCredits: userData.credits,
        timestamp: new Date()
      });

      track('user_document_created', {
        userType: type,
        success: true
      });
    } catch (error) {
      console.error('Error creating user document:', error);
      track('user_document_creation_error', {
        error: error.message
      });
      throw error;
    }
  };

  const checkPasswordStrength = (password) => {
    if (password.length === 0) {
      setPasswordStrength(null);
    } else {
      const result = zxcvbn(password);
      setPasswordStrength(result.score);
    }
  };

  return (
    <div className="bg-gradient-to-b from-[rgba(205,212,213,1)] to-[rgba(255,255,255,0)] min-h-screen flex flex-col justify-center px-4 py-2 sm:px-6 lg:px-8">
      <div className="w-full max-w-[400px] mx-auto">
        <h2 className="mt-3 text-3xl font-extrabold text-gray-900">Register for Behave</h2>
      </div>

      <div className="mt-4 w-full max-w-[400px] mx-auto">
        <div className="bg-white py-8 px-6 shadow rounded-lg sm:px-8">
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                I am a
              </label>
              <div className="mt-2 flex space-x-4">
                <button
                  type="button"
                  onClick={() => setUserType('creator')}
                  className={`flex-1 inline-flex justify-center items-center px-4 py-2 border rounded-md shadow-sm text-sm font-medium transition-colors duration-200 ${
                    userType === 'creator'
                      ? 'bg-slate-800 text-white hover:bg-slate-700 border-transparent'
                      : 'bg-white text-gray-700 hover:bg-gray-50 border-gray-300'
                  }`}
                >
                  Creator
                </button>
                <button
                  type="button"
                  onClick={() => setUserType('brand')}
                  className={`flex-1 inline-flex justify-center items-center px-4 py-2 border rounded-md shadow-sm text-sm font-medium transition-colors duration-200 ${
                    userType === 'brand'
                      ? 'bg-slate-800 text-white hover:bg-slate-700 border-transparent'
                      : 'bg-white text-gray-700 hover:bg-gray-50 border-gray-300'
                  }`}
                >
                  Brand
                </button>
              </div>
            </div>

            <form className="space-y-4" onSubmit={handleRegister}>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="new-password"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      checkPasswordStrength(e.target.value);
                    }}
                  />
                </div>
                {passwordStrength !== null && (
                  <div className="mt-1">
                    <div className="flex justify-between mb-1">
                      <span className="text-xs">Password strength:</span>
                      <span className="text-xs font-medium">
                        {passwordStrength === 0 && 'Very weak'}
                        {passwordStrength === 1 && 'Weak'}
                        {passwordStrength === 2 && 'Fair'}
                        {passwordStrength === 3 && 'Good'}
                        {passwordStrength === 4 && 'Strong'}
                      </span>
                    </div>
                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                      <div
                        className={`h-2.5 rounded-full ${
                          passwordStrength === 0 ? 'bg-red-500' :
                          passwordStrength === 1 ? 'bg-orange-500' :
                          passwordStrength === 2 ? 'bg-yellow-500' :
                          passwordStrength === 3 ? 'bg-blue-500' :
                          'bg-green-500'
                        }`}
                        style={{ width: `${(passwordStrength + 1) * 20}%` }}
                      ></div>
                    </div>
                    <p className="mt-1 text-xs text-gray-500">
                  Password must be at least 7 characters long and include at least one of the following: special character, number, or uppercase letter.
                </p>
                  </div>
                )}
              </div>

              <div>
                <label htmlFor="confirm-password" className="block text-sm font-medium text-gray-700">
                  Confirm Password
                </label>
                <div className="mt-1">
                  <input
                    id="confirm-password"
                    name="confirm-password"
                    type="password"
                    autoComplete="new-password"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
              </div>

              {error && <p className="mt-2 text-sm text-red-600">{error}</p>}


              {/* Add checkboxes before the password field */}
              <div className="space-y-2">
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="terms"
                      name="terms"
                      type="checkbox"
                      required
                      className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                      checked={acceptedTerms}
                      onChange={(e) => setAcceptedTerms(e.target.checked)}
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor="terms" className="font-medium text-gray-700">
                      I accept the{' '}
                      <button
                        type="button"
                        onClick={() => setShowTermsModal(true)}
                        className="text-indigo-600 hover:text-indigo-500"
                      >
                        Terms and Conditions
                      </button>
                    </label>
                  </div>
                </div>

                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="privacy"
                      name="privacy"
                      type="checkbox"
                      required
                      className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                      checked={acceptedPrivacy}
                      onChange={(e) => setAcceptedPrivacy(e.target.checked)}
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor="privacy" className="font-medium text-gray-700">
                      I accept the{' '}
                      <button
                        type="button"
                        onClick={() => setShowPrivacyModal(true)}
                        className="text-indigo-600 hover:text-indigo-500"
                      >
                        Privacy Policy
                      </button>
                    </label>
                  </div>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  disabled={loading}
                  className="w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-slate-800 hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500 transition-colors duration-200 disabled:opacity-70"
                >
                  {loading ? (
                    <AiOutlineLoading3Quarters className="w-5 h-5 animate-spin" />
                  ) : (
                    'Register'
                  )}
                </button>
              </div>
            </form>

            {/* Add PolicyModal components here */}
            <PolicyModal
              isOpen={showTermsModal}
              onClose={() => setShowTermsModal(false)}
              title="Terms and Conditions"
              url="https://behave.ro/terms-conditions.html"
            />
            <PolicyModal
              isOpen={showPrivacyModal}
              onClose={() => setShowPrivacyModal(false)}
              title="Privacy Policy"
              url="https://behave.ro/privacy-policy.html"
            />

            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">Or continue with</span>
                </div>
              </div>

              <div className="mt-6">
                <button
                  onClick={handleGoogleRegister}
                  disabled={loading}
                  className="w-full flex items-center justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500 transition-colors duration-200 disabled:opacity-70"
                >
                  <FcGoogle className="w-5 h-5 mr-2" />
                  Register with Google
                </button>

                {/* Commented Meta and Instagram buttons
                <button
                  onClick={handleMetaRegister}
                  disabled={loading}
                  className="w-full flex items-center justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white bg-[#0668E1] hover:bg-[#0459C7] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#0668E1] transition-colors duration-200"
                >
                  <SiMeta className="w-5 h-5 mr-2" />
                  Register with Meta
                </button>

                <button
                  onClick={handleInstagramRegister}
                  disabled={loading}
                  className="w-full flex items-center justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white bg-[#E4405F] hover:bg-[#D03851] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#E4405F] transition-colors duration-200"
                >
                  <SiInstagram className="w-5 h-5 mr-2" />
                  Register with Instagram
                </button>
                */}
              </div>
            </div>

            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">
                    Already have an account?
                  </span>
                </div>
              </div>

              <div className="mt-6">
                <Link
                  to="/login"
                  className="w-full flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-slate-800 bg-white hover:bg-gray-50 transition-colors duration-200"
                >
                  Log in
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterScreen;
