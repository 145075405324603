import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc, collection, query, where, orderBy, getDocs, addDoc, Timestamp } from 'firebase/firestore';
import { auth, db } from '../firebase';
import Header from '../components/Header';
import { useToast } from '../context/ToastContext';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useAuth } from '../context/AuthContext';
import { CardSkeleton } from '../components/Skeletons';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CREDIT_PACKAGES = [
  { credits: 100, price: 10, perCredit: 0.10, popular: false },
  { credits: 500, price: 45, perCredit: 0.09, popular: true },
  { credits: 1000, price: 80, perCredit: 0.08, popular: false },
];

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: '16px',
      color: '#424770',
      '::placeholder': {
        color: '#aab7c4',
      },
      padding: '16px',
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

const CheckoutForm = ({ selectedPackage, onSuccess, userDetails }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) return;

    setLoading(true);

    try {
      const response = await fetch('https://behavepay.onrender.com/create-payment-intent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          amount: selectedPackage.price,
          credits: selectedPackage.credits,
          userId: userDetails.uid,
          userEmail: userDetails.email,
          userName: userDetails.displayName || 'Unknown User',
          userType: userDetails.userType,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      const result = await stripe.confirmCardPayment(data.clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: userDetails.displayName || 'Unknown User',
            email: userDetails.email,
          },
        },
      });

      if (result.error) {
        addToast(result.error.message, 'error');
      } else if (result.paymentIntent.status === 'succeeded') {
        onSuccess(selectedPackage.credits);
      }
    } catch (error) {
      console.error('Error:', error);
      addToast('An error occurred while processing your payment.', 'error');
    }

    setLoading(false);
  };
  

  return (
    <div className="space-y-6">
      <div className="bg-gray-50 p-4 rounded-lg">
        <h3 className="font-medium mb-2">Order Summary</h3>
        <div className="flex justify-between items-center">
          <span>{selectedPackage.credits} Credits</span>
          <span className="font-medium">${selectedPackage.price}</span>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">
            Card Details
          </label>
          <div className="border p-3 rounded-lg bg-white">
            <CardElement options={CARD_ELEMENT_OPTIONS} />
          </div>
        </div>

        <button 
          type="submit" 
          disabled={!stripe || loading}
          className="w-full bg-blue-600 text-white px-4 py-3 rounded-lg font-medium hover:bg-blue-700 
                   disabled:bg-gray-400 disabled:cursor-not-allowed transition-colors
                   flex items-center justify-center space-x-2"
        >
          {loading ? (
            <div className="flex items-center space-x-2">
              <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z" />
              </svg>
              <span>Processing...</span>
            </div>
          ) : (
            <span>Pay ${selectedPackage.price}</span>
          )}
        </button>

        <p className="text-sm text-gray-500 text-center">
          🔒 Payments are secure and encrypted by Stripe 
        </p>
      </form>
    </div>
  );
};

const CreditPackage = ({ pkg, selected, onSelect }) => (
  <div 
    className={`p-6 rounded-lg border bg-white cursor-pointer transition-all duration-200 
                hover:shadow-lg relative ${selected ? 'border-blue-500 ring-2 ring-blue-500' : 'border-gray-200'}`}
    onClick={() => onSelect(pkg)}
  >
    {pkg.popular && (
      <div className="absolute -top-3 left-1/2 transform -translate-x-1/2">
        <span className="bg-blue-500 text-white text-xs px-3 py-1 rounded-full">
          Most Popular
        </span>
      </div>
    )}
    
    <div className="space-y-4">
      <div>
        <h3 className="text-2xl font-bold">{pkg.credits}</h3>
        <p className="text-gray-500">Credits</p>
      </div>

      <div className="space-y-1">
        <div className="text-2xl font-bold">${pkg.price}</div>
        <p className="text-sm text-gray-500">
          ${pkg.perCredit.toFixed(2)} per credit
        </p>
      </div>
    </div>
  </div>
);

const TransactionTable = ({ transactions }) => (
  <div className="rounded-lg border bg-white overflow-hidden">
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Credits</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Details</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {transactions.map((transaction) => (
            <tr key={transaction.id} className="hover:bg-gray-50">
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                {transaction.timestamp.toDate().toLocaleString()}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                {transaction.type}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                <span className={transaction.type === 'purchase' ? 'text-green-600' : 'text-red-600'}>
                  {transaction.type === 'purchase' ? `+${transaction.credits}` : `-${transaction.credits}`}
                </span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {transaction.type === 'purchase' 
                  ? `Purchased for $${transaction.amount}`
                  : transaction.details || 'N/A'}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
);

const BuyCreditsScreen = () => {
  const [loading, setLoading] = useState(true); // Add a loading state
  const [userCredits, setUserCredits] = useState(0);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const { addToast } = useToast();
  const { userType } = useAuth();

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        const collectionName = userType === 'creator' ? 'creators' : 'brands';
        const userDoc = await getDoc(doc(db, collectionName, user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserCredits(userData.credits || 0);
          setUserDetails({
            uid: user.uid,
            email: user.email,
            displayName: user.displayName || userData.name,
            userType: userType,
          });
        } else {
          console.error('User document does not exist');
          addToast('Error fetching user data', 'error');
        }
      } else {
        console.error('No authenticated user');
        addToast('Please log in to buy credits', 'error');
      }
      setLoading(false); // Set loading to false after data is fetched
    };
    fetchUserData();
    fetchTransactions();
  }, [userType, addToast]);

  const fetchTransactions = async () => {
    const user = auth.currentUser;
    if (user) {
      const transactionsQuery = query(
        collection(db, 'creditTransactions'),
        where('userId', '==', user.uid),
        orderBy('timestamp', 'desc')
      );
      const transactionsSnapshot = await getDocs(transactionsQuery);
      const transactionsData = transactionsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setTransactions(transactionsData);
    }
  };

  const handlePackageSelect = (pkg) => {
    setSelectedPackage(pkg);
  };

  const handlePaymentSuccess = async (credits) => {
    const user = auth.currentUser;
    if (user) {
      const collectionName = userType === 'creator' ? 'creators' : 'brands';
      const userRef = doc(db, collectionName, user.uid);
      try {
        const newCredits = userCredits + credits;
        await updateDoc(userRef, {
          credits: newCredits,
        });
        setUserCredits(newCredits);
        addToast(`Successfully purchased ${credits} credits!`, 'success');
        setSelectedPackage(null);
        
        // Add transaction record
        await addDoc(collection(db, 'creditTransactions'), {
          userId: user.uid,
          credits: credits,
          amount: selectedPackage.price,
          type: 'purchase',
          timestamp: Timestamp.now()
        });
        
        // Refresh transactions
        fetchTransactions();
      } catch (error) {
        console.error('Error updating credits:', error);
        addToast('Error updating credits. Please contact support.', 'error');
      }
    }
  };

  const renderTransactionHistory = () => {
    return (
      <div className="mt-6 mb-6">
        <h2 className="text-xl font-bold mb-3 text-gray-500">Credit Transaction History</h2>
        {transactions.length > 0 ? (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Credits</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Details</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {transactions.map((transaction) => (
                  <tr key={transaction.id}>
                    <td className="px-6 py-4 whitespace-nowrap">{transaction.timestamp.toDate().toLocaleString()}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{transaction.type}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {transaction.type === 'purchase' ? `+${transaction.credits}` : `-${transaction.credits}`}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {transaction.type === 'purchase' 
                        ? `Purchased for $${transaction.amount}`
                        : transaction.details || 'N/A'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p>No transaction history available.</p>
        )}
      </div>
    );
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-100">
        <Header userType={userType} />
        <main className="pt-28 md:pt-20 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
          <h1 className="text-xl font-bold mb-3 text-gray-500">Credits Dashboard</h1>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {[...Array(3)].map((_, index) => (
              <CardSkeleton key={index} />
            ))}
          </div>
        </main>
      </div>
    );
  }

  if (!userDetails) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <Header userType={userType} />
      <main className="pt-28 md:pt-20 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
      <h1 className="text-xl font-bold mb-3 text-gray-500">Credits Dashboard</h1>
        <div className="space-y-8">
          {/* Credits Overview */}
          <div className="bg-white p-6 rounded-lg shadow-sm border">
            <h2 className="text-2xl font-bold mb-2">Your Credits</h2>
            <div className="text-4xl font-bold text-blue-600">{userCredits}</div>
          </div>

          {/* Credit Packages */}
          <div className="space-y-4">
            <h2 className="text-xl font-semibold">
              Select Credit Package
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {CREDIT_PACKAGES.map((pkg) => (
                <div key={pkg.credits} className="relative">
                  <CreditPackage
                    pkg={pkg}
                    selected={selectedPackage === pkg}
                    onSelect={handlePackageSelect}
                  />
                  {/* Checkout Section for Mobile */}
                  {selectedPackage === pkg && (
                    <div className="mt-4 md:hidden shadow-lg p-4 bg-white rounded-lg">
                       <h2 className="text-xl font-semibold mb-4">
                          Checkout
                      </h2>
                      <Elements stripe={stripePromise}>
                        <CheckoutForm 
                          selectedPackage={selectedPackage} 
                          onSuccess={handlePaymentSuccess} 
                          userDetails={userDetails}
                        />
                      </Elements>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          {/* Checkout Section for Desktop */}
          {selectedPackage && (
            <div className="hidden md:block bg-white p-6 rounded-lg shadow-sm border">
              <h2 className="text-xl font-semibold mb-4">
                Checkout
              </h2>
              <Elements stripe={stripePromise}>
                <CheckoutForm 
                  selectedPackage={selectedPackage} 
                  onSuccess={handlePaymentSuccess} 
                  userDetails={userDetails}
                />
              </Elements>
            </div>
          )}

          {/* Transaction History */}
          <div className="space-y-4">
            <h2 className="text-xl font-semibold">
              Transaction History
            </h2>
            {transactions.length > 0 ? (
              <TransactionTable transactions={transactions} />
            ) : (
              <p className="text-gray-500">No transaction history available.</p>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default BuyCreditsScreen;
