import React, { useState, useEffect, useContext } from 'react';
import { collection, getDocs, doc, updateDoc, getDoc, runTransaction, addDoc, query, orderBy, limit, where } from 'firebase/firestore';
import { auth, db, realtimeDb } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { ToastContext } from '../context/ToastContext';
import { FaUsers, FaStore, FaTag, FaEnvelope, FaCreditCard, FaChartLine, FaExclamationTriangle, FaEdit, FaToggleOn, FaToggleOff } from 'react-icons/fa';
import { ref, onValue } from 'firebase/database';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, Cell } from 'recharts';
import { useAuth } from '../context/AuthContext';


const AdminPanel = () => {
  const { currentUser, isAdmin } = useAuth();
  const navigate = useNavigate();
  const { addToast } = useContext(ToastContext);
  const [isLoading, setIsLoading] = useState(true);

  const [brands, setBrands] = useState([]);
  const [creators, setCreators] = useState([]);
  const [offers, setOffers] = useState([]);
  const [messages, setMessages] = useState([]);
  const [credits, setCredits] = useState([]);
  const [activeSection, setActiveSection] = useState('dashboard');
  const [analytics, setAnalytics] = useState({
    brandGrowth: [],
    creatorGrowth: [],
    offerGrowth: []
  });
  const [recentActivity, setRecentActivity] = useState([]);
  const [activityFilter, setActivityFilter] = useState('all');

  const [totalBrands, setTotalBrands] = useState(0);
  const [totalCreators, setTotalCreators] = useState(0);
  const [totalOffers, setTotalOffers] = useState(0);
  const [totalCities, setTotalCities] = useState(0);
  const [editingUser, setEditingUser] = useState(null);

  const [showMessagesModal, setShowMessagesModal] = useState(false);
  const [selectedChat, setSelectedChat] = useState(null);

  const [detailsModal, setDetailsModal] = useState(null);
  const [socialModal, setSocialModal] = useState(null);
  const [venueModal, setVenueModal] = useState(null);

  // Add these functions before the first useEffect
  const fetchUsers = async () => {
    const brandsSnapshot = await getDocs(query(collection(db, 'brands'), orderBy('createdAt', 'desc'), limit(50)));
    const creatorsSnapshot = await getDocs(query(collection(db, 'creators'), orderBy('createdAt', 'desc'), limit(50)));

    setBrands(brandsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    setCreators(creatorsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
  };

  const fetchOffers = async () => {
    try {
      const offersSnapshot = await getDocs(
        query(collection(db, 'offers'), 
        orderBy('createdAt', 'desc'), 
        limit(50)
      ));

      const offersWithBrands = await Promise.all(
        offersSnapshot.docs.map(async (doc) => {
          const data = doc.data();
          const brandId = data.brand?.id || data.brandId;
          
          if (!brandId) {
            return { id: doc.id, ...data, brandName: 'No Brand ID' };
          }

          try {
            const brandDoc = await getDoc(doc(db, 'brands', brandId));
            const brandData = brandDoc.exists() ? brandDoc.data() : null;
            return {
              id: doc.id,
              ...data,
              brandName: brandData?.businessName || brandData?.name || brandData?.email || 'Unknown Brand Name'
            };
          } catch (error) {
            console.error('Error fetching brand:', error);
            return { id: doc.id, ...data, brandName: 'Brand Not Found' };
          }
        })
      );

      setOffers(offersWithBrands);
    } catch (error) {
      console.error('Error fetching offers:', error);
      addToast('Error fetching offers: ' + error.message, 'error');
    }
  };

  const fetchMessages = async () => {
    const messagesRef = ref(realtimeDb, 'messages');
    onValue(messagesRef, (snapshot) => {
      const messagesData = snapshot.val();
      if (messagesData) {
        const messagesArray = Object.entries(messagesData).map(([id, data]) => ({
          id,
          ...data,
        }));
        setMessages(messagesArray);
      }
    });
  };

  const fetchCredits = async () => {
    const brandsSnapshot = await getDocs(collection(db, 'brands'));
    const creatorsSnapshot = await getDocs(collection(db, 'creators'));

    const brandCredits = brandsSnapshot.docs.map(doc => ({ id: doc.id, type: 'brand', ...doc.data() }));
    const creatorCredits = creatorsSnapshot.docs.map(doc => ({ id: doc.id, type: 'creator', ...doc.data() }));

    setCredits([...brandCredits, ...creatorCredits]);
  };

  const fetchAnalytics = async () => {
    const now = new Date();
    const sixMonthsAgo = new Date(now.getFullYear(), now.getMonth() - 6, 1);
    
    const brandsQuery = query(collection(db, 'brands'), where('createdAt', '>=', sixMonthsAgo));
    const creatorsQuery = query(collection(db, 'creators'), where('createdAt', '>=', sixMonthsAgo));
    const offersQuery = query(collection(db, 'offers'), where('createdAt', '>=', sixMonthsAgo));

    try {
      const [brandsSnapshot, creatorsSnapshot, offersSnapshot] = await Promise.all([
        getDocs(brandsQuery),
        getDocs(creatorsQuery),
        getDocs(offersQuery)
      ]);

      const brandGrowth = processGrowthData(brandsSnapshot);
      const creatorGrowth = processGrowthData(creatorsSnapshot);
      const offerGrowth = processGrowthData(offersSnapshot);

      setAnalytics({
        brandGrowth,
        creatorGrowth,
        offerGrowth
      });
    } catch (error) {
      console.error("Error fetching analytics data:", error);
      addToast("Failed to fetch analytics data", "error");
    }
  };

  const fetchRecentActivity = async () => {
    const activitySnapshot = await getDocs(query(collection(db, 'audit'), orderBy('timestamp', 'desc'), limit(100)));
    setRecentActivity(activitySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
  };

  const fetchTotalCounts = async () => {
    const brandsSnapshot = await getDocs(collection(db, 'brands'));
    const creatorsSnapshot = await getDocs(collection(db, 'creators'));
    const offersSnapshot = await getDocs(collection(db, 'offers'));

    setTotalBrands(brandsSnapshot.size);
    setTotalCreators(creatorsSnapshot.size);
    setTotalOffers(offersSnapshot.size);

    const cities = new Set();
    offersSnapshot.forEach(doc => {
      const city = doc.data().city;
      if (city) cities.add(city);
    });
    setTotalCities(cities.size);
  };

  const processGrowthData = (snapshot) => {
    const growthData = {};
    snapshot.forEach(doc => {
      const date = doc.data().createdAt.toDate();
      const monthYear = `${date.getFullYear()}-${date.getMonth() + 1}`;
      growthData[monthYear] = (growthData[monthYear] || 0) + 1;
    });

    return Object.entries(growthData).map(([date, count]) => ({
      date,
      count
    })).sort((a, b) => a.date.localeCompare(b.date));
  };

  const fetchData = async () => {
    try {
      await Promise.all([
        fetchUsers(),
        fetchOffers(),
        fetchMessages(),
        fetchCredits(),
        fetchAnalytics(),
        fetchRecentActivity(),
        fetchTotalCounts(),
      ]);
    } catch (error) {
      console.error('Error fetching data:', error);
      addToast('Error fetching data: ' + error.message, 'error');
    }
  };

  useEffect(() => {
    const checkAccess = async () => {
      if (!currentUser || !isAdmin) {
        console.log('Access check failed:', { currentUser, isAdmin });
        addToast('You do not have permission to access this page', 'error');
        navigate('/dashboard');
        return;
      }

      try {
        setIsLoading(true);
        await fetchData();
      } catch (error) {
        console.error('Error loading admin data:', error);
        addToast('Error loading admin data: ' + error.message, 'error');
      } finally {
        setIsLoading(false);
      }
    };

    checkAccess();
  }, [currentUser, isAdmin, navigate, addToast]);

  // Show loading state
  if (isLoading) {
    return <div>Loading...</div>;
  }

  // Only render if user is admin
  if (!isAdmin) {
    return null;
  }

  const handleUpdateDetails = async (id, type, updatedData) => {
    try {
      await updateDoc(doc(db, type, id), updatedData);
      addToast(`${type.charAt(0).toUpperCase() + type.slice(1)} updated successfully`, 'success');
      fetchData();
    } catch (error) {
      console.error(`Error updating ${type}:`, error);
      addToast(`Error updating ${type}: ${error.message}`, 'error');
    }
  };

  const handleUpdateCredits = async (userId, userType, newCredits) => {
    try {
      await runTransaction(db, async (transaction) => {
        const userRef = doc(db, userType === 'brand' ? 'brands' : 'creators', userId);
        const userDoc = await transaction.get(userRef);

        if (!userDoc.exists()) {
          throw "User document does not exist!";
        }

        transaction.update(userRef, { credits: newCredits });

        await addDoc(collection(db, 'audit'), {
          action: 'admin_credit_update',
          userId: userId,
          userType: userType,
          oldCredits: userDoc.data().credits,
          newCredits: newCredits,
          adminId: auth.currentUser.uid,
          timestamp: new Date()
        });
      });

      addToast('Credits updated successfully', 'success');
      fetchCredits();
    } catch (error) {
      console.error('Error updating credits:', error);
      addToast('Error updating credits: ' + error.message, 'error');
    }
  };

  const handleEditUser = (user, type) => {
    setEditingUser({ ...user, type });
  };

  const handleUpdateUser = async (updatedUser) => {
    try {
      const userRef = doc(db, updatedUser.type === 'brand' ? 'brands' : 'creators', updatedUser.id);
      await updateDoc(userRef, {
        name: updatedUser.name,
        email: updatedUser.email,
        isApproved: updatedUser.isApproved,
        // Add other fields as necessary
      });
      addToast(`${updatedUser.type.charAt(0).toUpperCase() + updatedUser.type.slice(1)} updated successfully`, 'success');
      setEditingUser(null);
      fetchData();
    } catch (error) {
      console.error(`Error updating ${updatedUser.type}:`, error);
      addToast(`Error updating ${updatedUser.type}: ${error.message}`, 'error');
    }
  };

  const handleToggleStatus = async (user, type, field) => {
    const newStatus = !user[field];
    try {
      await updateDoc(doc(db, type, user.id), { [field]: newStatus });
      addToast(`${field} status updated successfully`, 'success');
      fetchData(); // Refresh the data
    } catch (error) {
      console.error(`Error updating ${field} status:`, error);
      addToast(`Error updating ${field} status: ${error.message}`, 'error');
    }
  };

  const handleViewMessages = async (user) => {
    try {
      const chatsRef = ref(realtimeDb, 'chats');
      onValue(chatsRef, (snapshot) => {
        const chatsData = snapshot.val();
        const userChats = Object.entries(chatsData).filter(([_, chat]) => 
          chat.creatorId === user.id || chat.brandId === user.id
        );
        setSelectedChat(userChats);
        setShowMessagesModal(true);
      });
    } catch (error) {
      console.error('Error fetching messages:', error);
      addToast('Error fetching messages', 'error');
    }
  };

  const handleViewDetails = (user, type) => {
    setDetailsModal({ user, type });
  };

  const handleViewSocial = (creator) => {
    setDetailsModal({ user: creator, type: 'social' });
  };

  const handleViewVenue = (brand) => {
    setVenueModal(brand);
  };

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  const renderUserEditModal = () => {
    if (!editingUser) return null;

    return (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
        <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
          <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">
            Edit {editingUser.type.charAt(0).toUpperCase() + editingUser.type.slice(1)}
          </h3>
          <form onSubmit={(e) => {
            e.preventDefault();
            handleUpdateUser(editingUser);
          }}>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                Name
              </label>
              <input
                type="text"
                id="name"
                value={editingUser.name}
                onChange={(e) => setEditingUser({ ...editingUser, name: e.target.value })}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                Email
              </label>
              <input
                type="email"
                id="email"
                value={editingUser.email}
                onChange={(e) => setEditingUser({ ...editingUser, email: e.target.value })}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-4">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={editingUser.isApproved}
                  onChange={(e) => setEditingUser({ ...editingUser, isApproved: e.target.checked })}
                  className="form-checkbox h-5 w-5 text-blue-600"
                />
                <span className="ml-2 text-gray-700">Approved</span>
              </label>
            </div>
            <div className="flex justify-end">
              <button
                type="button"
                onClick={() => setEditingUser(null)}
                className="mr-2 bg-gray-500 text-white px-4 py-2 rounded"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  const renderMessagesModal = () => {
    if (!showMessagesModal || !selectedChat) return null;

    return (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
        <div className="relative top-20 mx-auto p-5 border w-3/4 shadow-lg rounded-md bg-white">
          <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">Messages</h3>
          <div className="max-h-96 overflow-y-auto">
            {selectedChat.map(([chatId, chat]) => (
              <div key={chatId} className="mb-4 p-4 border rounded">
                <h4 className="font-bold">Chat ID: {chatId}</h4>
                <p>Creator ID: {chat.creatorId}</p>
                <p>Brand ID: {chat.brandId}</p>
                {chat.messages && Object.entries(chat.messages).map(([messageId, message]) => (
                  <div key={messageId} className="ml-4 mt-2">
                    <p><strong>{message.senderId}:</strong> {message.message}</p>
                    <p className="text-xs text-gray-500">{new Date(message.timestamp).toLocaleString()}</p>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <button
            onClick={() => setShowMessagesModal(false)}
            className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
          >
            Close
          </button>
        </div>
      </div>
    );
  };

  const renderDetailsModal = () => {
    if (!detailsModal) return null;

    const { user, type } = detailsModal;

    return (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
        <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
          <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">
            {type === 'brands' ? 'Brand Details' : type === 'creators' ? 'Creator Details' : 'Social Media Details'}
          </h3>
          <div className="mt-2 space-y-3">
            {type === 'brands' && (
              <>
                <div>
                  <strong>Business Name:</strong> {user.businessName}
                </div>
                <div>
                  <strong>Fiscal Code:</strong> {user.fiscalCode}
                </div>
                <div>
                  <strong>Owner Name:</strong> {user.ownerName}
                </div>
                <div>
                  <strong>Owner Phone:</strong> {user.ownerPhone}
                </div>
                <div>
                  <strong>Owner Email:</strong> {user.ownerEmail}
                </div>
                <div>
                  <strong>Bio:</strong> {user.bio}
                </div>
                <div>
                  <strong>Website:</strong> {user.website}
                </div>
              </>
            )}
            {type === 'creators' && (
              <>
                <div>
                  <strong>Bio:</strong> {user.bio}
                </div>
                <div>
                  <strong>Location:</strong> {user.location}
                </div>
                <div>
                  <strong>Join Reason:</strong> {user.joinReason}
                </div>
                <div className="mt-4">
                  <strong>Social Media:</strong>
                  <div className="ml-4">
                    <div>Instagram: {user.socialMedia?.instagram}</div>
                    <div>TikTok: {user.socialMedia?.tiktok}</div>
                    <div>YouTube: {user.socialMedia?.youtube}</div>
                  </div>
                </div>
              </>
            )}
            {type === 'social' && (
              <div className="space-y-4">
                {user.socialMedia?.instagram && (
                  <div>
                    <strong>Instagram:</strong>
                    <a href={user.socialMedia.instagram} target="_blank" rel="noopener noreferrer" 
                       className="ml-2 text-blue-500 hover:text-blue-700">
                      View Profile
                    </a>
                  </div>
                )}
                {user.socialMedia?.tiktok && (
                  <div>
                    <strong>TikTok:</strong>
                    <a href={user.socialMedia.tiktok} target="_blank" rel="noopener noreferrer"
                       className="ml-2 text-blue-500 hover:text-blue-700">
                      View Profile
                    </a>
                  </div>
                )}
                {user.socialMedia?.youtube && (
                  <div>
                    <strong>YouTube:</strong>
                    <a href={user.socialMedia.youtube} target="_blank" rel="noopener noreferrer"
                       className="ml-2 text-blue-500 hover:text-blue-700">
                      View Profile
                    </a>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="mt-4">
            <button
              onClick={() => setDetailsModal(null)}
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderVenueModal = () => {
    if (!venueModal) return null;

    return (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
        <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
          <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">
            Venue Details
          </h3>
          <div className="mt-2 space-y-3">
            <div>
              <strong>Venue Name:</strong> {venueModal.venueName}
            </div>
            <div>
              <strong>Venue Type:</strong> {venueModal.venueType}
            </div>
            <div>
              <strong>Contact Phone:</strong> {venueModal.contactPhone}
            </div>
            {venueModal.venueDetails && (
              <>
                <div>
                  <strong>Address:</strong> {venueModal.venueDetails.address}
                </div>
                <div>
                  <strong>City:</strong> {venueModal.venueDetails.city}
                </div>
                {venueModal.venueDetails.openingHours && (
                  <div>
                    <strong>Opening Hours:</strong>
                    <ul className="ml-4">
                      {venueModal.venueDetails.openingHours.map((hours, index) => (
                        <li key={index}>{hours}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="mt-4">
            <button
              onClick={() => setVenueModal(null)}
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex h-screen bg-gray-100">
      <div className="w-64 bg-white shadow-md">
        <div className="p-4">
          <h2 className="text-2xl font-semibold text-gray-800">Behave Admin</h2>
        </div>
        <nav className="mt-4">
          <a onClick={() => setActiveSection('dashboard')} className={`flex items-center py-2 px-4 ${activeSection === 'dashboard' ? 'bg-gray-200' : ''}`}>
            <FaChartLine className="mr-2" /> Dashboard
          </a>
          <a onClick={() => setActiveSection('brands')} className={`flex items-center py-2 px-4 ${activeSection === 'brands' ? 'bg-gray-200' : ''}`}>
            <FaStore className="mr-2" /> Brands
          </a>
          <a onClick={() => setActiveSection('creators')} className={`flex items-center py-2 px-4 ${activeSection === 'creators' ? 'bg-gray-200' : ''}`}>
            <FaUsers className="mr-2" /> Creators
          </a>
          <a onClick={() => setActiveSection('offers')} className={`flex items-center py-2 px-4 ${activeSection === 'offers' ? 'bg-gray-200' : ''}`}>
            <FaTag className="mr-2" /> Offers
          </a>
          <a onClick={() => setActiveSection('messages')} className={`flex items-center py-2 px-4 ${activeSection === 'messages' ? 'bg-gray-200' : ''}`}>
            <FaEnvelope className="mr-2" /> Messages
          </a>
          <a onClick={() => setActiveSection('credits')} className={`flex items-center py-2 px-4 ${activeSection === 'credits' ? 'bg-gray-200' : ''}`}>
            <FaCreditCard className="mr-2" /> Credits
          </a>
          <a onClick={() => setActiveSection('activity')} className={`flex items-center py-2 px-4 ${activeSection === 'activity' ? 'bg-gray-200' : ''}`}>
            <FaExclamationTriangle className="mr-2" /> Recent Activity
          </a>
        </nav>
      </div>
      <div className="flex-1 overflow-y-auto p-8">
        {activeSection === 'dashboard' && (
          <div>
            <h2 className="text-2xl font-semibold mb-4">Dashboard</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
              <div className="bg-white p-4 rounded shadow">
                <h3 className="text-lg font-semibold mb-2">Total Brands</h3>
                <p className="text-3xl font-bold text-blue-600">{totalBrands}</p>
              </div>
              <div className="bg-white p-4 rounded shadow">
                <h3 className="text-lg font-semibold mb-2">Total Creators</h3>
                <p className="text-3xl font-bold text-green-600">{totalCreators}</p>
              </div>
              <div className="bg-white p-4 rounded shadow">
                <h3 className="text-lg font-semibold mb-2">Total Offers</h3>
                <p className="text-3xl font-bold text-yellow-600">{totalOffers}</p>
              </div>
              <div className="bg-white p-4 rounded shadow">
                <h3 className="text-lg font-semibold mb-2">Total Cities</h3>
                <p className="text-3xl font-bold text-purple-600">{totalCities}</p>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="bg-white p-4 rounded shadow">
                <h3 className="text-lg font-semibold mb-2">User Growth</h3>
                {analytics.brandGrowth.length > 0 || analytics.creatorGrowth.length > 0 ? (
                  <LineChart width={500} height={300} data={[...analytics.brandGrowth, ...analytics.creatorGrowth]}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="count" name="Brands" stroke="#8884d8" />
                    <Line type="monotone" dataKey="count" name="Creators" stroke="#82ca9d" />
                  </LineChart>
                ) : (
                  <p>No user growth data available</p>
                )}
              </div>
              <div className="bg-white p-4 rounded shadow">
                <h3 className="text-lg font-semibold mb-2">Offer Creation</h3>
                {analytics.offerGrowth.length > 0 ? (
                  <LineChart width={500} height={300} data={analytics.offerGrowth}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="count" name="Offers" stroke="#ffc658" />
                  </LineChart>
                ) : (
                  <p>No offer creation data available</p>
                )}
              </div>
            </div>
          </div>
        )}
        {activeSection === 'brands' && (
          <div>
            <h2 className="text-2xl font-semibold mb-4">Brands</h2>
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white">
                <thead>
                  <tr>
                    <th className="px-4 py-2">Name</th>
                    <th className="px-4 py-2">Email</th>
                    <th className="px-4 py-2">Created At</th>
                    <th className="px-4 py-2">Business Details</th>
                    <th className="px-4 py-2">Venue Details</th>
                    <th className="px-4 py-2">Status</th>
                    <th className="px-4 py-2">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {brands.map(brand => (
                    <tr key={brand.id}>
                      <td className="border px-4 py-2">{brand.name}</td>
                      <td className="border px-4 py-2">{brand.email}</td>
                      <td className="border px-4 py-2">{brand.createdAt?.toDate().toLocaleString()}</td>
                      <td className="border px-4 py-2">
                        <button 
                          onClick={() => handleViewDetails(brand, 'brands')}
                          className="bg-blue-500 text-white px-2 py-1 rounded"
                        >
                          View Details
                        </button>
                      </td>
                      <td className="border px-4 py-2">
                        <button 
                          onClick={() => handleViewVenue(brand)}
                          className="bg-green-500 text-white px-2 py-1 rounded"
                        >
                          View Venue
                        </button>
                      </td>
                      <td className="border px-4 py-2">
                        <button 
                          onClick={() => handleToggleStatus(brand, 'brands', 'isApproved')}
                          className={`p-1 rounded ${brand.isApproved ? 'bg-green-500' : 'bg-red-500'} text-white`}
                        >
                          {brand.isApproved ? <FaToggleOn /> : <FaToggleOff />}
                        </button>
                      </td>
                      <td className="border px-4 py-2">
                        <button onClick={() => handleEditUser(brand, 'brands')} className="bg-blue-500 text-white px-2 py-1 rounded mr-2">
                          <FaEdit />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
        {activeSection === 'creators' && (
          <div>
            <h2 className="text-2xl font-semibold mb-4">Creators</h2>
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white">
                <thead>
                  <tr>
                    <th className="px-4 py-2">Name</th>
                    <th className="px-4 py-2">Email</th>
                    <th className="px-4 py-2">Created At</th>
                    <th className="px-4 py-2">Social Media</th>
                    <th className="px-4 py-2">Profile Details</th>
                    <th className="px-4 py-2">Status</th>
                    <th className="px-4 py-2">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {creators.map(creator => (
                    <tr key={creator.id}>
                      <td className="border px-4 py-2">{creator.name}</td>
                      <td className="border px-4 py-2">{creator.email}</td>
                      <td className="border px-4 py-2">{creator.createdAt?.toDate().toLocaleString()}</td>
                      <td className="border px-4 py-2">
                        <button 
                          onClick={() => handleViewSocial(creator)}
                          className="bg-purple-500 text-white px-2 py-1 rounded"
                        >
                          View Social
                        </button>
                      </td>
                      <td className="border px-4 py-2">
                        <button 
                          onClick={() => handleViewDetails(creator, 'creators')}
                          className="bg-blue-500 text-white px-2 py-1 rounded"
                        >
                          View Details
                        </button>
                      </td>
                      <td className="border px-4 py-2">
                        <button 
                          onClick={() => handleToggleStatus(creator, 'creators', 'isApproved')}
                          className={`p-1 rounded ${creator.isApproved ? 'bg-green-500' : 'bg-red-500'} text-white`}
                        >
                          {creator.isApproved ? <FaToggleOn /> : <FaToggleOff />}
                        </button>
                      </td>
                      <td className="border px-4 py-2">
                        <button onClick={() => handleEditUser(creator, 'creators')} className="bg-blue-500 text-white px-2 py-1 rounded">
                          <FaEdit />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
        {activeSection === 'offers' && (
          <div>
            <h2 className="text-2xl font-semibold mb-4">Offers</h2>
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white">
                <thead>
                  <tr>
                    <th className="px-4 py-2">Title</th>
                    <th className="px-4 py-2">Brand</th>
                    <th className="px-4 py-2">Created At</th>
                    <th className="px-4 py-2">Status</th>
                    <th className="px-4 py-2">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {offers.map(offer => (
                    <tr key={offer.id}>
                      <td className="border px-4 py-2">{offer.title}</td>
                      <td className="border px-4 py-2">{offer.brandName}</td>
                      <td className="border px-4 py-2">{offer.createdAt?.toDate().toLocaleString()}</td>
                      <td className="border px-4 py-2">
                        {offer.status ? 'Active' : 'Inactive'}
                      </td>
                      <td className="border px-4 py-2">
                        <button 
                          onClick={() => handleUpdateDetails(offer.id, 'offers', { status: !offer.status })} 
                          className={`mr-2 p-1 rounded ${offer.status ? 'bg-green-500' : 'bg-red-500'} text-white`}
                        >
                          {offer.status ? <FaToggleOn /> : <FaToggleOff />}
                        </button>
                        <button onClick={() => handleEditUser(offer, 'offers')} className="bg-green-500 text-white px-2 py-1 rounded">
                          <FaEdit />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
        {activeSection === 'messages' && (
          <div>
            <h2 className="text-2xl font-semibold mb-4">Messages</h2>
            <div className="space-y-4">
              {messages.length > 0 ? (
                messages.map(message => (
                  <div key={message.id} className="bg-white p-4 rounded shadow">
                    <p><strong>From:</strong> {message.senderName}</p>
                    <p><strong>To:</strong> {message.receiverName}</p>
                    <p><strong>Sent:</strong> {new Date(message.timestamp).toLocaleString()}</p>
                    <p className="mt-2">{message.content}</p>
                  </div>
                ))
              ) : (
                <p>No messages to display.</p>
              )}
            </div>
          </div>
        )}
        {activeSection === 'credits' && (
          <div>
            <h2 className="text-2xl font-semibold mb-4">Credits</h2>
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white">
                <thead>
                  <tr>
                    <th className="px-4 py-2">User</th>
                    <th className="px-4 py-2">Type</th>
                    <th className="px-4 py-2">Credits</th>
                    <th className="px-4 py-2">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {credits.map(credit => (
                    <tr key={credit.id}>
                      <td className="border px-4 py-2">{credit.name}</td>
                      <td className="border px-4 py-2">{credit.type}</td>
                      <td className="border px-4 py-2">{credit.credits}</td>
                      <td className="border px-4 py-2">
                        <input 
                          type="number" 
                          value={credit.credits} 
                          onChange={(e) => handleUpdateCredits(credit.id, credit.type, parseInt(e.target.value))}
                          className="border rounded px-2 py-1 w-20"
                        />
                        <button onClick={() => handleUpdateCredits(credit.id, credit.type, credit.credits)} className="ml-2 bg-blue-500 text-white px-2 py-1 rounded">
                          Update
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
        {activeSection === 'activity' && (
          <div>
            <h2 className="text-2xl font-semibold mb-4">Recent Activity</h2>
            <div className="mb-4">
              <select 
                value={activityFilter} 
                onChange={(e) => setActivityFilter(e.target.value)}
                className="border rounded px-2 py-1"
              >
                <option value="all">All Activities</option>
                <option value="user_registration">User Registration</option>
                <option value="offer_creation">Offer Creation</option>
                <option value="application_submission">Application Submission</option>
                <option value="credit_purchase">Credit Purchase</option>
                {/* Add more options based on your activity types */}
              </select>
            </div>
            <div className="space-y-4">
              {recentActivity
                .filter(activity => activityFilter === 'all' || activity.action === activityFilter)
                .map(activity => (
                  <div key={activity.id} className="bg-white p-4 rounded shadow">
                    <p><strong>Action:</strong> {activity.action}</p>
                    <p><strong>User:</strong> {activity.userId}</p>
                    <p><strong>Timestamp:</strong> {activity.timestamp.toDate().toLocaleString()}</p>
                    <p><strong>Details:</strong> {JSON.stringify(activity.details)}</p>
                  </div>
                ))}
            </div>
          </div>
        )}
        {renderUserEditModal()}
        {renderMessagesModal()}
        {renderDetailsModal()}
        {renderVenueModal()}
      </div>
    </div>
  );
};

export default AdminPanel;
