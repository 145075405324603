import React, { createContext, useState, useContext, useCallback, useEffect } from 'react';
import { getFirestore, collection, getDocs, query, where, onSnapshot, doc, getDoc, updateDoc, orderBy } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { useToast } from './ToastContext';
import { cacheData, getCachedData } from '../utils/cacheUtils';

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [selectedCity, setSelectedCity] = useState('All Cities');
  const [offers, setOffers] = useState([]);
  const [credits, setCredits] = useState(0);
  const [user, setUser] = useState(null);
  const [userType, setUserType] = useState(null); // Add userType state
  const { addToast } = useToast();
  const [messages, setMessages] = useState([]);
  const [applications, setApplications] = useState([]);
  const [refreshHeaderCount, setRefreshHeaderCount] = useState(0);

  // Determine user type on auth state change
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      setUser(currentUser);
      
      if (currentUser) {
        try {
          const brandDocRef = doc(db, 'brands', currentUser.uid);
          const creatorDocRef = doc(db, 'creators', currentUser.uid);
          
          const [brandDocSnap, creatorDocSnap] = await Promise.all([
            getDoc(brandDocRef),
            getDoc(creatorDocRef)
          ]);
          
          if (brandDocSnap.exists()) {
            setUserType('brand');
          } else if (creatorDocSnap.exists()) {
            setUserType('creator');
          }
        } catch (error) {
          // Error handling remains but without console.error
        }
      } else {
        setUserType(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const refreshOffers = useCallback(async (forceRefresh = false) => {
    if (!user) return;

    const cacheKey = `offers_${selectedCity}`;
    const cachedOffers = getCachedData(cacheKey);

    if (!forceRefresh && cachedOffers) {
      setOffers(cachedOffers);
      return;
    }

    try {
      let q = collection(db, 'offers');
      const queryConstraints = [];

      // Only apply city filter if specified
      if (selectedCity !== 'All Cities') {
        queryConstraints.push(where('city', '==', selectedCity));
      }

      // Apply query constraints if any exist
      q = queryConstraints.length > 0 ? query(q, ...queryConstraints) : q;

      const querySnapshot = await getDocs(q);
      const offersData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      setOffers(offersData);
      cacheData(cacheKey, offersData, 15);
    } catch (error) {
      // Removed console.error
      addToast('Failed to fetch offers', 'error');
    }
  }, [selectedCity, addToast, user]);

  const refreshMessages = useCallback(async (forceRefresh = false) => {
    if (!user) return;

    const cacheKey = `messages_${user.uid}`;
    const cachedMessages = getCachedData(cacheKey);

    if (!forceRefresh && cachedMessages) {
      setMessages(cachedMessages);
      return;
    }

    try {
      // Update query to match security rules using OR condition
      const senderQuery = query(
        collection(db, 'messages'),
        where('senderId', '==', user.uid)
      );
      const recipientQuery = query(
        collection(db, 'messages'),
        where('recipientId', '==', user.uid)
      );
      
      const [senderSnapshot, recipientSnapshot] = await Promise.all([
        getDocs(senderQuery),
        getDocs(recipientQuery)
      ]);

      const messagesData = [
        ...senderSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })),
        ...recipientSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
      ];

      setMessages(messagesData);
      cacheData(cacheKey, messagesData, 5);
    } catch (error) {
      console.error('Error fetching messages:', error);
      addToast('Failed to fetch messages', 'error');
    }
  }, [addToast, user]);

  const refreshApplications = useCallback(async (forceRefresh = false) => {
    if (!user || !userType) return;

    const cacheKey = `applications_${user.uid}`;
    const cachedApplications = getCachedData(cacheKey);

    if (!forceRefresh && cachedApplications) {
      setApplications(cachedApplications);
      return;
    }

    try {
      // Query based on user type
      const q = query(
        collection(db, 'applications'),
        where(userType === 'creator' ? 'creatorId' : 'brandId', '==', user.uid)
      );
      
      const querySnapshot = await getDocs(q);
      const applicationsData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setApplications(applicationsData);
      cacheData(cacheKey, applicationsData, 10);
    } catch (error) {
      // Removed console.error
      addToast('Failed to fetch applications', 'error');
    }
  }, [addToast, user, userType]);

  useEffect(() => {
    // Removed console.log for data refresh effect
    
    if (user && userType) {
      // Removed console.log for starting data refresh
      refreshOffers();
      refreshMessages();
      refreshApplications();

      // Set up real-time listeners with proper queries
      const offersQuery = userType === 'brand' 
        ? query(collection(db, 'offers'), where('brandId', '==', user.uid))
        : collection(db, 'offers');

      const senderQuery = query(
        collection(db, 'messages'),
        where('senderId', '==', user.uid)
      );
      const recipientQuery = query(
        collection(db, 'messages'),
        where('recipientId', '==', user.uid)
      );

      const applicationsQuery = query(
        collection(db, 'applications'),
        where(userType === 'creator' ? 'creatorId' : 'brandId', '==', user.uid)
      );

      const offersUnsubscribe = onSnapshot(offersQuery, () => refreshOffers(true));
      const messagesUnsubscribe = () => {
        const unsubscribe1 = onSnapshot(senderQuery, () => refreshMessages(true));
        const unsubscribe2 = onSnapshot(recipientQuery, () => refreshMessages(true));
        return () => {
          unsubscribe1();
          unsubscribe2();
        };
      };
      const applicationsUnsubscribe = onSnapshot(applicationsQuery, () => refreshApplications(true));

      return () => {
        offersUnsubscribe();
        messagesUnsubscribe();
        applicationsUnsubscribe();
      };
    }
  }, [refreshOffers, refreshMessages, refreshApplications, user, userType]);

  // Credits monitoring
  useEffect(() => {
    if (user && userType) {
      const userRef = doc(db, userType === 'creator' ? 'creators' : 'brands', user.uid);
      const unsubscribe = onSnapshot(userRef, (doc) => {
        if (doc.exists()) {
          const userData = doc.data();
          setCredits(userData.credits || 0);
          if (userData.credits < 10) {
            addToast('Your credit balance is low. Consider buying more credits.', 'warning');
          }
        }
      });

      return () => unsubscribe();
    }
  }, [user, userType, addToast]);

  const value = {
    selectedCity,
    setSelectedCity: (newCity) => {
      setSelectedCity(newCity);
      setOffers([]);
    },
    offers,
    setOffers,
    refreshOffers,
    refreshMessages,
    refreshApplications,
    messages,
    applications,
    credits,
    user,
    userType,
    // Add these new values
    refreshHeaderCount,
    setRefreshHeaderCount
  };

  return (
    <GlobalContext.Provider value={value}>
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (context === undefined) {
    throw new Error('useGlobalContext must be used within a GlobalProvider');
  }
  return context;
};
