import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, orderBy, getDoc, doc, updateDoc } from 'firebase/firestore';
import { auth, db, storage } from '../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import Header from '../components/Header';
import { useAuth } from '../context/AuthContext';
import { useToast } from '../context/ToastContext';
import { CardSkeleton } from '../components/Skeletons';
import OfferCard from '../components/OfferCard';
import parse from 'html-react-parser';

const AppliedOffersScreen = () => {
  const [appliedOffers, setAppliedOffers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('all');
  const [sortBy, setSortBy] = useState('date');
  const { userType } = useAuth();
  const { addToast } = useToast();
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [showContentModal, setShowContentModal] = useState(false);
  const [contentInputs, setContentInputs] = useState({});
  const [showOfferDetailsModal, setShowOfferDetailsModal] = useState(false);

  useEffect(() => {
    fetchAppliedOffers();
  }, [addToast]);

  useEffect(() => {
    document.title = 'Applied Offers | Behave';
  }, []);

  const fetchAppliedOffers = async () => {
    try {
      const user = auth.currentUser;
      if (!user) {
        addToast('Please log in to view applied offers', 'error');
        setLoading(false);
        return;
      }

      const applicationsQuery = query(
        collection(db, 'applications'),
        where('creatorId', '==', user.uid),
        orderBy('createdAt', 'desc')
      );

      const applicationsSnapshot = await getDocs(applicationsQuery);
      const appliedOffersData = [];

      for (const applicationDoc of applicationsSnapshot.docs) {
        const applicationData = applicationDoc.data();
        const offerDoc = await getDoc(doc(db, 'offers', applicationData.offerId));
        
        if (offerDoc.exists()) {
          const offerData = offerDoc.data();
          if (offerData.status !== false) {
            appliedOffersData.push({
              id: applicationDoc.id,
              ...applicationData,
              offer: {
                id: offerDoc.id,
                ...offerData
              }
            });
          }
        }
      }

      console.log('Fetched applied offers:', appliedOffersData);
      setAppliedOffers(appliedOffersData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching applied offers:', error);
      addToast('Error fetching applied offers', 'error');
      setLoading(false);
    }
  };

  const filteredOffers = appliedOffers.filter(offer => {
    if (offer.offer?.status === false) return false;
    
    if (filter === 'all') return true;
    return offer.status.toLowerCase() === filter;
  });

  const sortedOffers = [...filteredOffers].sort((a, b) => {
    if (sortBy === 'date') {
      return b.createdAt.toDate() - a.createdAt.toDate();
    } else if (sortBy === 'company') {
      return a.offer.companyName.localeCompare(b.offer.companyName);
    }
    return 0;
  });

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'pending': return 'bg-yellow-100 text-yellow-800';
      case 'accepted': return 'bg-green-100 text-green-800';
      case 'rejected': return 'bg-red-100 text-red-800';
      default: return 'bg-gray-100 text-gray-800';
    }
  };

  const handleAddContent = async (application) => {
    try {
      const offerDoc = await getDoc(doc(db, 'offers', application.offerId));
      if (!offerDoc.exists()) {
        addToast('Offer not found', 'error');
        return;
      }

      const offerData = offerDoc.data();
      const updatedOffer = { ...application, offer: { id: offerDoc.id, ...offerData } };
      setSelectedOffer(updatedOffer);

      const initialInputs = {
        'Instagram post/story/reel URL': [''],
        'TikTok post URL': [''],
        'Image': [],
        'Video': []
      };
      setContentInputs(initialInputs);
      setShowContentModal(true);
    } catch (error) {
      console.error('Error fetching offer details:', error);
      addToast('Error fetching offer details', 'error');
    }
  };

  const handleContentInputChange = (type, index, value) => {
    setContentInputs(prev => ({
      ...prev,
      [type]: prev[type].map((item, i) => i === index ? value : item)
    }));
  };

  const handleAddInput = (type) => {
    setContentInputs(prev => ({
      ...prev,
      [type]: [...prev[type], '']
    }));
  };

  const handleRemoveInput = (type, index) => {
    setContentInputs(prev => ({
      ...prev,
      [type]: prev[type].filter((_, i) => i !== index)
    }));
  };

  const handleFileUpload = async (type, file) => {
    try {
      const storageRef = ref(storage, `content/${selectedOffer.id}/${type}/${file.name}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      setContentInputs(prev => ({
        ...prev,
        [type]: [...prev[type], downloadURL]
      }));
    } catch (error) {
      console.error('Error uploading file:', error);
      addToast('Error uploading file', 'error');
    }
  };

  const handleSubmitContent = async () => {
    try {
      const offerRef = doc(db, 'applications', selectedOffer.id);
      await updateDoc(offerRef, {
        contentInputs: contentInputs,
        status: 'content_submitted'
      });
      addToast('Content submitted successfully', 'success');
      setShowContentModal(false);
      fetchAppliedOffers(); // Refresh the offers list
    } catch (error) {
      console.error('Error submitting content:', error);
      addToast('Error submitting content', 'error');
    }
  };

  const handleViewOfferDetails = (application) => {
    setSelectedOffer(application);
    setShowOfferDetailsModal(true);
  };

  const OfferDetailsModal = ({ offer, onClose }) => {
    const cleanDescription = (desc) => {
      if (!desc) return '';
      return desc.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '');
    };

    if (!offer) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
        <div className="bg-white rounded-lg w-full max-w-4xl max-h-[90vh] flex flex-col">
          {/* Scrollable content area */}
          <div className="overflow-y-auto p-6 flex-1">
            {/* Header */}
            <div className="border-b pb-4 mb-6">
              <div className="flex justify-between items-start">
                <div>
                  <h2 className="text-3xl font-bold text-gray-900">{offer.offer?.title}</h2>
                  <p className="text-lg text-gray-600 mt-1">{offer.offer?.companyName}</p>
                </div>
                <span className={`px-3 py-1 rounded-full text-sm font-medium ${getStatusColor(offer.status)}`}>
                  {offer.status}
                </span>
              </div>
            </div>

            {/* Main Content */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Left Column */}
              <div className="space-y-6">
                <div>
                  <h3 className="text-lg font-semibold text-gray-900 mb-2">Description</h3>
                  <div className="text-gray-700 text-sm rich-text-content">
                    {parse(cleanDescription(offer.offer?.description))}
                  </div>
                </div>

                <div>
                  <h3 className="text-lg font-semibold text-gray-900 mb-2">Requirements</h3>
                  <div className="space-y-2">
                    {offer.offer?.requirements?.map((req, index) => (
                      <div key={index} className="flex items-center">
                        <span className="mr-2">•</span>
                        <span className="text-gray-700">{req}</span>
                      </div>
                    ))}
                  </div>
                </div>

                <div>
                  <h3 className="text-lg font-semibold text-gray-900 mb-2">Offer Types</h3>
                  <div className="flex flex-wrap gap-2">
                    {Object.entries(offer.offer?.offerTypes || {}).map(([type, isSelected]) => (
                      isSelected && (
                        <span key={type} className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm">
                          {type}
                        </span>
                      )
                    ))}
                  </div>
                </div>
              </div>

              {/* Right Column */}
              <div className="space-y-6">
                <div className="bg-gray-50 p-4 rounded-lg">
                  <h3 className="text-lg font-semibold text-gray-900 mb-4">Offer Details</h3>
                  <div className="space-y-3">
                    <div>
                      <p className="text-sm text-gray-500">Compensation</p>
                      <p className="font-medium text-gray-900">{offer.offer?.compensation}</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Venue</p>
                      <p className="font-medium text-gray-900">{offer.offer?.venue}</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Location</p>
                      <p className="font-medium text-gray-900">{offer.offer?.city}</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Date Range</p>
                      <p className="font-medium text-gray-900">
                        {offer.offer?.startDate} - {offer.offer?.endDate}
                      </p>
                    </div>
                    {offer.offer?.applicationDeadline && (
                      <div>
                        <p className="text-sm text-gray-500">Application Deadline</p>
                        <p className="font-medium text-gray-900">{offer.offer?.applicationDeadline}</p>
                      </div>
                    )}
                  </div>
                </div>

                {/* Images Section */}
                {offer.offer?.imageUrl && (
                  <div>
                    <h3 className="text-lg font-semibold text-gray-900 mb-2">Images</h3>
                    <img 
                      src={offer.offer.imageUrl} 
                      alt={offer.offer.title}
                      className="w-full h-auto rounded-lg object-cover"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Sticky footer */}
          <div className="border-t p-4 bg-white sticky bottom-0">
            <div className="flex justify-end">
              <button
                onClick={onClose}
                className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition-colors"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const statusColumns = [
    { id: 'pending', title: 'Pending', color: 'yellow' },
    { id: 'accepted', title: 'Approved', color: 'green' },
    { id: 'content_submitted', title: 'Content Submitted', color: 'purple' },
    { id: 'completed', title: 'Completed', color: 'blue' },
    { id: 'rejected', title: 'Rejected', color: 'red' },
  ];

  const organizeOffersByStatus = (offers) => {
    return statusColumns.reduce((acc, { id }) => {
      acc[id] = offers.filter(offer => offer.status === id);
      return acc;
    }, {});
  };

  return (
    <div className="pt-20 md:pt-20 max-w-[1280px] mx-auto px-4 sm:px-6 lg:px-8 py-2">
      <Header />
      <main className="container mx-auto  py-8">
        <h1 className="text-2xl font-bold text-gray-500 mb-6">Applied Offers</h1>

        {loading ? (
          <div className="space-y-4">
            {[...Array(3)].map((_, index) => (
              <CardSkeleton key={index} />
            ))}
          </div>
        ) : (
          <>
            {/* Mobile View */}
            <div className="lg:hidden space-y-8">
              {statusColumns.map(({ id, title, color }) => (
                <section key={id}>
                  <div className={`bg-${color}-100 rounded-lg p-4 mb-4`}>
                    <h2 className="text-lg font-semibold text-gray-700 flex items-center justify-between">
                      <div className="flex items-center">
                        <span className={`w-2 h-2 bg-${color}-400 rounded-full mr-2`}></span>
                        {title}
                      </div>
                      <span className="text-sm text-gray-500">
                        ({organizeOffersByStatus(appliedOffers)[id].length})
                      </span>
                    </h2>
                  </div>
                  <div className="space-y-4">
                    {organizeOffersByStatus(appliedOffers)[id].map(application => (
                      <div key={application.id} className={`border-l-4 border-${color}-400`}>
                        <OfferCard 
                          key={application.id} 
                          application={application}
                          onViewDetails={handleViewOfferDetails}
                          onAddContent={handleAddContent}
                        />
                      </div>
                    ))}
                    {organizeOffersByStatus(appliedOffers)[id].length === 0 && (
                      <div className={`text-center py-8 bg-white rounded-lg border-2 border-dashed border-${color}-200`}>
                        <p className="text-gray-500 text-xs">No {title.toLowerCase()} applications</p>
                      </div>
                    )}
                  </div>
                </section>
              ))}
            </div>

            {/* Desktop Kanban View */}
            <div className="hidden lg:grid grid-cols-5 gap-4 h-[calc(100vh-200px)]">
              {statusColumns.map(({ id, title, color }) => (
                <div key={id} className="flex flex-col bg-gray-50 rounded-lg">
                  <div className={`p-4 bg-${color}-100 rounded-t-lg border-b border-${color}-200`}>
                    <h2 className="text-xs font-semibold text-gray-700 flex items-center">
                      <span className={`w-2 h-2 bg-${color}-400 rounded-full mr-2 text-xs`}></span>
                      {title}
                      <span className="ml-2 text-sm text-gray-500 text-xs">
                        ({organizeOffersByStatus(appliedOffers)[id].length})
                      </span>
                    </h2>
                  </div>
                  <div className="flex-1 p-4 overflow-y-auto">
                    <div className="space-y-4">
                      {organizeOffersByStatus(appliedOffers)[id].map(application => (
                        <div 
                          key={application.id}
                          className="bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200"
                        >
                          <OfferCard 
                            application={application}
                            onViewDetails={handleViewOfferDetails}
                            onAddContent={handleAddContent}
                            compact={true}
                          />
                        </div>
                      ))}
                      {organizeOffersByStatus(appliedOffers)[id].length === 0 && (
                        <div className="text-center py-8 bg-white rounded-lg border-2 border-dashed border-gray-200">
                          <p className="text-gray-500 text-sm">No {title.toLowerCase()} applications</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}

        {/* Content Modal */}
        {showContentModal && selectedOffer && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-lg p-6 max-w-2xl w-full max-h-[90vh] overflow-y-auto">
              <h2 className="text-2xl font-bold mb-4">Add Content for {selectedOffer.offer?.title}</h2>
              
              {/* Instagram URLs */}
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold mb-2">Instagram URLs</label>
                {contentInputs['Instagram post/story/reel URL'].map((url, index) => (
                  <div key={index} className="flex gap-2 mb-2">
                    <input
                      type="text"
                      value={url}
                      onChange={(e) => handleContentInputChange('Instagram post/story/reel URL', index, e.target.value)}
                      className="flex-1 p-2 border rounded"
                      placeholder="Enter Instagram URL"
                    />
                    <button
                      onClick={() => handleRemoveInput('Instagram post/story/reel URL', index)}
                      className="text-red-500 hover:text-red-700"
                    >
                      Remove
                    </button>
                  </div>
                ))}
                <button
                  onClick={() => handleAddInput('Instagram post/story/reel URL')}
                  className="text-blue-500 hover:text-blue-700"
                >
                  Add Instagram URL
                </button>
              </div>

              {/* TikTok URLs */}
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold mb-2">TikTok URLs</label>
                {contentInputs['TikTok post URL'].map((url, index) => (
                  <div key={index} className="flex gap-2 mb-2">
                    <input
                      type="text"
                      value={url}
                      onChange={(e) => handleContentInputChange('TikTok post URL', index, e.target.value)}
                      className="flex-1 p-2 border rounded"
                      placeholder="Enter TikTok URL"
                    />
                    <button
                      onClick={() => handleRemoveInput('TikTok post URL', index)}
                      className="text-red-500 hover:text-red-700"
                    >
                      Remove
                    </button>
                  </div>
                ))}
                <button
                  onClick={() => handleAddInput('TikTok post URL')}
                  className="text-blue-500 hover:text-blue-700"
                >
                  Add TikTok URL
                </button>
              </div>

              {/* Images */}
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold mb-2">Images</label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileUpload('Image', e.target.files[0])}
                  className="mb-2"
                />
                {contentInputs['Image'].map((url, index) => (
                  <div key={index} className="mb-2">
                    <img src={url} alt={`Upload ${index + 1}`} className="max-w-xs" />
                    <button
                      onClick={() => handleRemoveInput('Image', index)}
                      className="text-red-500 hover:text-red-700 mt-1"
                    >
                      Remove
                    </button>
                  </div>
                ))}
              </div>

              {/* Videos */}
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold mb-2">Videos</label>
                <input
                  type="file"
                  accept="video/*"
                  onChange={(e) => handleFileUpload('Video', e.target.files[0])}
                  className="mb-2"
                />
                {contentInputs['Video'].map((url, index) => (
                  <div key={index} className="mb-2">
                    <video src={url} controls className="max-w-xs" />
                    <button
                      onClick={() => handleRemoveInput('Video', index)}
                      className="text-red-500 hover:text-red-700 mt-1"
                    >
                      Remove
                    </button>
                  </div>
                ))}
              </div>

              <div className="flex justify-end gap-2">
                <button
                  onClick={() => setShowContentModal(false)}
                  className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSubmitContent}
                  className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                  Submit Content
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Offer Details Modal */}
        {showOfferDetailsModal && selectedOffer && (
          <OfferDetailsModal offer={selectedOffer} onClose={() => setShowOfferDetailsModal(false)} />
        )}
      </main>
    </div>
  );
};

export default AppliedOffersScreen;
