import React from 'react';

const OfferCard = ({ application, onViewDetails, onAddContent }) => {
  const getStatusStyles = (status) => {
    switch (status.toLowerCase()) {
      case 'pending': return 'bg-yellow-100 text-yellow-800';
      case 'accepted': return 'bg-green-100 text-green-800';
      case 'rejected': return 'bg-red-100 text-red-800';
      case 'completed': return 'bg-blue-100 text-blue-800';
      case 'content_submitted': return 'bg-purple-100 text-purple-800';
      default: return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-4">
      <div className="flex items-start justify-between flex-col">
        <div>
          <h3 className="font-semibold text-gray-800">{application.offer?.title}</h3>
          <p className="text-sm text-gray-600 mt-1">{application.offer?.companyName}</p>
        </div>
        <span className={`px-3 py-1 rounded-full text-xs ${getStatusStyles(application.status)}`}>
          {application.status}
        </span>
      </div>
      
      <div className="mt-4 flex items-center justify-between">
        <span className="text-xs text-gray-500">
          Applied on: {application.createdAt?.toDate().toLocaleDateString()}
        </span>
        <div className="space-x-2">
          <button
            onClick={() => onViewDetails(application)}
            className="text-sm text-blue-600 hover:text-blue-700 flex items-center gap-1"
          >
            View Details
          </button>
          {application.status === 'accepted' && (
            <button
              onClick={() => onAddContent(application)}
              className="text-sm text-green-600 hover:text-green-700 flex items-center gap-1"
            >
              Add Content
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default OfferCard;