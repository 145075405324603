import posthog from 'posthog-js';

// Generate a random ID if none exists
const getDistinctId = () => {
    let distinctId = localStorage.getItem('distinct_id');
    if (!distinctId) {
        distinctId = 'anonymous_' + Math.random().toString(36).substr(2, 9);
        localStorage.setItem('distinct_id', distinctId);
    }
    return distinctId;
};

// Initialize PostHog with configuration
posthog.init('phc_VJwecZ1YMuyrEwkPrvNFg3MwODfd6CjT3syoJ9E7HAR', {
    api_host: 'https://eu.i.posthog.com',
    persistence: 'localStorage',
    autocapture: false,
    capture_pageview: true,
    capture_pageleave: true,
    disable_session_recording: false,
    cross_subdomain_cookie: true, 
    bootstrap: {
        distinctId: getDistinctId(), // Use the helper function
    },
    loaded: (posthog) => {
        // Ensure distinct_id is set
        if (!posthog.get_distinct_id()) {
            posthog.identify(getDistinctId());
        }
    }
});

// Helper functions for tracking
export const identify = (userId, properties = {}) => {
    if (!userId) return;
    
    // Store the user ID for future use
    localStorage.setItem('distinct_id', userId);
    
    posthog.identify(userId, {
        ...properties,
        app_version: process.env.REACT_APP_VERSION || '1.0.0'
    });
};

export const track = (eventName, properties = {}) => {
    // Ensure we have a distinct_id before tracking
    const distinctId = posthog.get_distinct_id() || getDistinctId();
    
    posthog.capture(eventName, {
        ...properties,
        timestamp: new Date().toISOString(),
        distinct_id: distinctId
    });
};

export const setUserProperties = (properties) => {
    if (!properties) return;
    
    // Ensure we have a distinct_id before setting properties
    const distinctId = posthog.get_distinct_id() || getDistinctId();
    
    posthog.people.set({
        ...properties,
        distinct_id: distinctId
    });
};

export default posthog;
