import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc, deleteDoc, addDoc, collection } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { deleteUser } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import { getStorage, ref, uploadString, getDownloadURL } from 'firebase/storage';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { useToast } from '../context/ToastContext';
import { updateIntercom } from '../utils/intercom';

const ProfileScreen = () => {
  const { addToast } = useToast();
  const [profile, setProfile] = useState({
    name: '',
    bio: '',
    website: '',
    socialMedia: {
      instagram: '',
      tiktok: '',
      youtube: '',
    },
    skills: [],
    experience: '',
    venues: [],
  });
  const [userType, setUserType] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [venues, setVenues] = useState([]);
  const [newVenue, setNewVenue] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        const creatorDoc = await getDoc(doc(db, 'creators', user.uid));
        const brandDoc = await getDoc(doc(db, 'brands', user.uid));
        if (creatorDoc.exists()) {
          setUserType('creator');
          setProfile(creatorDoc.data());
          setProfilePicture(creatorDoc.data().profilePicture);
        } else if (brandDoc.exists()) {
          setUserType('brand');
          setProfile(brandDoc.data());
          setProfilePicture(brandDoc.data().profilePicture);
          setVenues(brandDoc.data().venues || []);
        }
      }
    };
    fetchUserData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setProfile(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value
        }
      }));
    } else {
      setProfile(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSkillsChange = (e) => {
    const skills = e.target.value.split(',').map(skill => skill.trim());
    setProfile(prev => ({ ...prev, skills }));
  };

  const handleVenueChange = (index, field, value) => {
    setProfile(prev => {
      const newVenues = [...prev.venues];
      newVenues[index] = { ...newVenues[index], [field]: value };
      return { ...prev, venues: newVenues };
    });
  };

  const addVenue = () => {
    setProfile(prev => ({
      ...prev,
      venues: [...prev.venues, { name: '', address: '', capacity: '' }]
    }));
  };

  const removeVenue = (index) => {
    setProfile(prev => ({
      ...prev,
      venues: prev.venues.filter((_, i) => i !== index)
    }));
  };

  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePicture(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAddVenue = async () => {
    if (!newVenue) return;

    try {
      const placeDetails = await getPlaceDetails(newVenue.value.place_id);
      const venueData = {
        name: placeDetails.name,
        address: placeDetails.formatted_address,
        city: placeDetails.address_components.find(component => component.types.includes('locality'))?.long_name || '',
        openingHours: placeDetails.opening_hours?.weekday_text || [],
        placeId: placeDetails.place_id,
        location: {
          lat: placeDetails.geometry.location.lat(),
          lng: placeDetails.geometry.location.lng()
        }
      };

      setVenues([...venues, venueData]);
      setNewVenue(null);
      addToast('Venue added successfully', 'success');
    } catch (error) {
      console.error('Error adding venue:', error);
      addToast('Failed to add venue. Please try again.', 'error');
    }
  };

  const getPlaceDetails = (placeId) => {
    return new Promise((resolve, reject) => {
      const service = new window.google.maps.places.PlacesService(document.createElement('div'));
      service.getDetails({ placeId: placeId }, (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          resolve(place);
        } else {
          reject(status);
        }
      });
    });
  };

  const handleRemoveVenue = (index) => {
    setVenues(venues.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = auth.currentUser;
    if (user) {
      try {
        const docRef = doc(db, 'brands', user.uid);
        const updatedProfile = { ...profile, venues };

        if (profilePicture && profilePicture !== profile.profilePicture) {
          const storage = getStorage();
          const storageRef = ref(storage, `profilePictures/${user.uid}`);
          await uploadString(storageRef, profilePicture, 'data_url');
          const downloadURL = await getDownloadURL(storageRef);
          updatedProfile.profilePicture = downloadURL;
        }

        await updateDoc(docRef, updatedProfile);
        addToast('Profile updated successfully', 'success');
        
        // After successfully updating the user profile
        updateIntercom(updatedProfile);
      } catch (error) {
        console.error('Error updating profile:', error);
        addToast(`Error updating profile: ${error.message}`, 'error');
      }
    }
  };

  const handleDeleteAccount = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const collectionName = userType === 'creator' ? 'creators' : 'brands';
        const userDoc = doc(db, collectionName, user.uid);
        await deleteDoc(userDoc);
        
        await addDoc(collection(db, 'audit'), {
          action: 'account_deleted',
          userId: user.uid,
          userType: userType,
          timestamp: new Date()
        });

        await deleteUser(user);
        addToast('Account deleted successfully', 'success');
        navigate('/login');
      }
    } catch (error) {
      addToast('Error deleting account: ' + error.message, 'error');
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Header userType={userType} />
      <main className="pt-[120px] md:pt-20 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="text-xl font-bold mb-3 text-gray-500 mt-7">Edit profile</h1>
        <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
              Name
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="name"
              type="text"
              name="name"
              value={profile.name}
              onChange={handleChange}
              placeholder="Name"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="bio">
              Bio
            </label>
            <textarea
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="bio"
              name="bio"
              value={profile.bio}
              onChange={handleChange}
              placeholder="Bio"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="website">
              Website
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="website"
              type="text"
              name="website"
              value={profile.website}
              onChange={handleChange}
              placeholder="Website"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Social Media
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2"
              type="text"
              name="socialMedia.instagram"
              value={profile.socialMedia.instagram}
              onChange={handleChange}
              placeholder="Instagram"
            />
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2"
              type="text"
              name="socialMedia.tiktok"
              value={profile.socialMedia.tiktok}
              onChange={handleChange}
              placeholder="TikTok"
            />
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              name="socialMedia.youtube"
              value={profile.socialMedia.youtube}
              onChange={handleChange}
              placeholder="YouTube"
            />
          </div>
          {userType === 'creator' && (
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="skills">
                Skills (comma-separated)
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="skills"
                type="text"
                value={profile.skills.join(', ')}
                onChange={handleSkillsChange}
                placeholder="Skills"
              />
            </div>
          )}
          {userType === 'creator' && (
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="experience">
                Experience
              </label>
              <textarea
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="experience"
                name="experience"
                value={profile.experience}
                onChange={handleChange}
                placeholder="Experience"
              />
            </div>
          )}
          {userType === 'brand' && (
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Venues
              </label>
              {venues.map((venue, index) => (
                <div key={index} className="mb-2 p-2 border rounded">
                  <p className="font-semibold">{venue.name}</p>
                  <p>{venue.address}</p>
                  <p>{venue.city}</p>
                  <button
                    type="button"
                    onClick={() => handleRemoveVenue(index)}
                    className="mt-2 bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded text-xs"
                  >
                    Remove Venue
                  </button>
                </div>
              ))}
              <div className="mt-2">
                <GooglePlacesAutocomplete
                  apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                  selectProps={{
                    value: newVenue,
                    onChange: setNewVenue,
                    placeholder: 'Search for a venue...',
                  }}
                />
                <button
                  type="button"
                  onClick={handleAddVenue}
                  className="mt-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                >
                  Add Venue
                </button>
              </div>
            </div>
          )}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="profilePicture">
              Profile Picture
            </label>
            <input
              type="file"
              id="profilePicture"
              onChange={handleProfilePictureChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
            {profilePicture && (
              <img src={profilePicture} alt="Profile Preview" className="mt-2 w-32 h-32 object-cover rounded-full" />
            )}
          </div>
          <div className="flex items-center justify-between">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Update Profile
            </button>
          </div>
        </form>
        <button
          onClick={() => setShowDeleteConfirmation(true)}
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Delete Account
        </button>
        {showDeleteConfirmation && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
            <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
              <div className="mt-3 text-center">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Delete Account</h3>
                <div className="mt-2 px-7 py-3">
                  <p className="text-sm text-gray-500">
                    Are you sure you want to delete your account? This action cannot be undone.
                  </p>
                </div>
                <div className="items-center px-4 py-3">
                  <button
                    onClick={handleDeleteAccount}
                    className="px-4 py-2 bg-red-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-300"
                  >
                    Yes, Delete Account
                  </button>
                  <button
                    onClick={() => setShowDeleteConfirmation(false)}
                    className="mt-3 px-4 py-2 bg-gray-300 text-gray-800 text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-300"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default ProfileScreen;
