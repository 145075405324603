import React, { useState, useEffect } from 'react';
import { requestNotificationPermission, onMessageListener } from '../firebase';
import { doc, setDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';

const NotificationHandler = () => {
  const [notification, setNotification] = useState({title: '', body: ''});

  useEffect(() => {
    requestNotificationPermission().then((token) => {
      if (token) {
        const user = auth.currentUser;
        if (user) {
          setDoc(doc(db, 'users', user.uid), { fcmToken: token }, { merge: true });
        }
      }
    });

    const unsubscribe = onMessageListener().then(payload => {
      setNotification({title: payload.notification.title, body: payload.notification.body});
    });

    return () => {
      unsubscribe.catch(err => console.log('failed: ', err));
    };
  }, []);

  return (
    <>
      {notification?.title && <div>{notification.title}</div>}
    </>
  );
};

export default NotificationHandler;
