import React, { useState, useEffect, useRef } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { Tags, Filter, ChevronLeft, ChevronRight } from 'lucide-react';

const FilterBar = ({ 
  selectedTag, 
  setSelectedTag,
  selectedCollabType,
  setSelectedCollabType,
  selectedCity
}) => {
  const [tags, setTags] = useState(['All']);
  const [availableCollabTypes, setAvailableCollabTypes] = useState(['All']);
  const typeScrollContainer = useRef(null);
  const tagsScrollContainer = useRef(null);

  // Calculate width for tags container (label + 6 items + padding)
  const TAG_ITEM_WIDTH = 118; // Approximate width per tag
  const LABEL_WIDTH = 50; // Width of "Tags:" label
  const PADDING = 20; // Container padding
  const MAX_VISIBLE_TAGS = 7;
  const tagsContainerWidth = `min(${(TAG_ITEM_WIDTH * MAX_VISIBLE_TAGS) + LABEL_WIDTH + PADDING}px, 100%)`;

  useEffect(() => {
    const fetchTagsAndCollabTypes = async () => {
      try {
        const offersQuery = query(
          collection(db, 'offers'),
          where('status', '==', 'active')
        );
        
        const offersSnapshot = await getDocs(offersQuery);
        const uniqueTags = new Set(['All']);
        const uniqueCollabTypes = new Set(['All']);
        
        offersSnapshot.docs.forEach(doc => {
          const offer = doc.data();
          if (selectedCity === 'All Cities' || offer.city === selectedCity) {
            if (offer.selectedTags) {
              offer.selectedTags.forEach(tag => uniqueTags.add(tag));
            }
            if (offer.collabType) {
              uniqueCollabTypes.add(offer.collabType);
            }
          }
        });

        const sortedTags = Array.from(uniqueTags).sort((a, b) => {
          if (a === 'All') return -1;
          if (b === 'All') return 1;
          return a.localeCompare(b);
        });

        const sortedCollabTypes = Array.from(uniqueCollabTypes).sort((a, b) => {
          if (a === 'All') return -1;
          if (b === 'All') return 1;
          return a.localeCompare(b);
        });

        setTags(sortedTags);
        setAvailableCollabTypes(sortedCollabTypes);

        // Reset filters if current selections are not available
        if (selectedTag !== 'All' && !sortedTags.includes(selectedTag)) {
          setSelectedTag('All');
        }
        if (selectedCollabType !== 'All' && !sortedCollabTypes.includes(selectedCollabType)) {
          setSelectedCollabType('All');
        }

      } catch (error) {
        console.error('Error fetching data:', error);
        setTags(['All']);
        setAvailableCollabTypes(['All']);
      }
    };

    fetchTagsAndCollabTypes();
  }, [selectedCity, selectedTag, setSelectedTag, selectedCollabType, setSelectedCollabType]);

  const scroll = (direction, containerRef) => {
    if (containerRef.current) {
      const scrollAmount = direction === 'left' ? -200 : 200;
      containerRef.current.scrollBy({
        left: scrollAmount,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className="flex flex-col gap-2 w-full mb-5">
      {/* Filter Labels and Containers */}
      <div className="flex flex-col md:flex-row gap-3 w-full">
        {/* Type Filter */}
        <div className="relative group w-full md:w-auto">
          <div className="relative">
            <button 
              onClick={() => scroll('left', typeScrollContainer)}
              className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-4 bg-white rounded-full p-1.5 shadow-md opacity-0 group-hover:opacity-100 transition-opacity z-10"
            >
              <ChevronLeft size={16} />
            </button>
            
            <div 
              ref={typeScrollContainer} 
              className="flex overflow-x-auto bg-white rounded-full px-3 py-2 shadow-sm scrollbar-hide"
            >
              <Filter className="text-gray-600 w-4 h-4 my-auto mr-2" />
              {availableCollabTypes.map((type) => (
                <button
                  key={type}
                  onClick={() => setSelectedCollabType(type)}
                  className={`px-4 py-1.5 rounded-full text-sm whitespace-nowrap shrink-0 transition-all duration-200 mr-2 ${
                    selectedCollabType === type
                      ? 'bg-gray-700 text-white'
                      : 'hover:bg-gray-100'
                  }`}
                >
                  {type}
                </button>
              ))}
            </div>

            <div className="absolute left-0 top-0 h-full w-8 bg-gradient-to-r from-white to-transparent pointer-events-none rounded-l-full"></div>
            <div className="absolute right-0 top-0 h-full w-8 bg-gradient-to-l from-white to-transparent pointer-events-none rounded-r-full"></div>
            <button 
              onClick={() => scroll('right', typeScrollContainer)}
              className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-4 bg-white rounded-full p-1.5 shadow-md opacity-0 group-hover:opacity-100 transition-opacity z-10"
            >
              <ChevronRight size={16} />
            </button>
          </div>
        </div>

        {/* Tags Filter */}
        <div 
          className="relative group w-full md:w-auto" 
          style={{ 
            maxWidth: tagsContainerWidth,
            minWidth: 'min(300px, 100%)'
          }}
        >
          <div className="relative">
            <button 
              onClick={() => scroll('left', tagsScrollContainer)}
              className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-4 bg-white rounded-full p-1.5 shadow-md opacity-0 group-hover:opacity-100 transition-opacity z-10"
            >
              <ChevronLeft size={16} />
            </button>
            
            <div 
              ref={tagsScrollContainer} 
              className="flex overflow-x-auto bg-white rounded-full px-3 py-2 shadow-sm scrollbar-hide"
            >
              <Tags className="text-gray-600 w-4 h-4 my-auto mr-2" />
              {tags.map((tag) => (
                <button
                  key={tag}
                  onClick={() => setSelectedTag(tag)}
                  className={`px-4 py-1.5 rounded-full text-sm whitespace-nowrap shrink-0 transition-all duration-200 mr-2 ${
                    selectedTag === tag
                      ? 'bg-gray-700 text-white'
                      : 'hover:bg-gray-100'
                  }`}
                >
                  {tag}
                </button>
              ))}
            </div>

            {/* Show gradients only if there are more than 6 tags */}
            {tags.length > MAX_VISIBLE_TAGS && (
              <>
                <div className="absolute left-0 top-0 h-full w-8 bg-gradient-to-r from-white to-transparent pointer-events-none rounded-l-full"></div>
                <div className="absolute right-0 top-0 h-full w-8 bg-gradient-to-l from-white to-transparent pointer-events-none rounded-r-full"></div>
              </>
            )}
            
            {/* Show scroll buttons only if there are more than 6 tags */}
            {tags.length > MAX_VISIBLE_TAGS && (
              <button 
                onClick={() => scroll('right', tagsScrollContainer)}
                className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-4 bg-white rounded-full p-1.5 shadow-md opacity-0 group-hover:opacity-100 transition-opacity z-10"
              >
                <ChevronRight size={16} />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterBar;

