import React, { useState } from 'react';

const EditOfferModal = ({ offer, onClose, onSave }) => {
  const [editedOffer, setEditedOffer] = useState(offer);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedOffer({ ...editedOffer, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(editedOffer);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-xl font-semibold mb-4">Edit Offer</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700">Title</label>
            <input
              type="text"
              name="title"
              value={editedOffer.title}
              onChange={handleChange}
              className="border rounded w-full py-2 px-3"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Description</label>
            <textarea
              name="description"
              value={editedOffer.description}
              onChange={handleChange}
              className="border rounded w-full py-2 px-3"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Compensation</label>
            <input
              type="text"
              name="compensation"
              value={editedOffer.compensation}
              onChange={handleChange}
              className="border rounded w-full py-2 px-3"
              required
            />
          </div>
          {/* Add other fields as necessary */}
          <div className="flex justify-end">
            <button type="button" onClick={onClose} className="mr-2 bg-gray-300 px-4 py-2 rounded">
              Cancel
            </button>
            <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditOfferModal;