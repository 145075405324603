import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, doc, getDoc, orderBy } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../context/AuthContext';
import Header from '../components/Header';
import { FaEnvelope, FaEnvelopeOpen, FaBriefcase, FaUserCheck, FaChartLine } from 'react-icons/fa';
import { ref, onValue } from 'firebase/database';
import { realtimeDb } from '../firebase';
import { CardSkeleton, ListItemSkeleton } from '../components/Skeletons';
import { useNavigate } from 'react-router-dom';

const BrandDashboard = () => {
  const { currentUser } = useAuth();
  const [stats, setStats] = useState({
    totalOffers: 0,
    activeOffers: 0,
    totalApplications: 0,
    applicationsByOffer: {},
    messages: {
      total: 0,
      unread: 0,
    },
    recentActivity: [],
  });
  const [loading, setLoading] = useState(true);
  const [brandProfile, setBrandProfile] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Brand Dashboard | Behave';
  }, []);

  useEffect(() => {
    const fetchUserType = async () => {
      const user = currentUser;
      if (user) {
        const brandDoc = await getDoc(doc(db, 'brands', user.uid));
        if (brandDoc.exists()) {
          setBrandProfile(brandDoc.data());
        }
      }
    };
    fetchUserType();

    const fetchMessagesData = async () => {
      const user = currentUser;
      if (!user) return;

      // Listen for unread messages
      const unreadMessagesRef = ref(realtimeDb, `unreadMessages/${user.uid}`);
      const unsubscribeUnreadMessages = onValue(unreadMessagesRef, (snapshot) => {
        const unreadData = snapshot.val() || {};
        // Calculate total unread messages the same way as Header
        const totalUnread = Object.values(unreadData).reduce((sum, count) => sum + count, 0);
        
        // Get total conversations from unread data
        const totalConversations = Object.keys(unreadData).length;

        setStats(prevStats => ({
          ...prevStats,
          messages: {
            total: totalConversations,
            unread: totalUnread
          }
        }));
      });

      return () => {
        unsubscribeUnreadMessages();
      };
    };

    const fetchDashboardData = async () => {
      const user = currentUser;
      if (!user) return;

      try {
        // First get the offers
        const offersQuery = query(
          collection(db, 'offers'), 
          where('brandId', '==', user.uid),
          orderBy('createdAt', 'desc')
        );

        const offersSnapshot = await getDocs(offersQuery);
        const offers = offersSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        // Process each offer's applications
        let totalApplicationsCount = 0;
        const applicationsByOfferData = {};

        await Promise.all(offers.map(async (offer) => {
          const applicationsQuery = query(
            collection(db, 'applications'),
            where('offerId', '==', offer.id),
            where('brandId', '==', user.uid)
          );

          const applicationsSnapshot = await getDocs(applicationsQuery);
          const applications = applicationsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));

          // Count applications by status
          const applicationStats = applications.reduce((acc, app) => {
            acc.count++;
            acc[app.status] = (acc[app.status] || 0) + 1;
            return acc;
          }, { count: 0, pending: 0, accepted: 0, rejected: 0 });

          totalApplicationsCount += applications.length;
          
          applicationsByOfferData[offer.id] = {
            offerTitle: offer.title,
            ...applicationStats
          };
        }));

        const activeOffers = offers.filter(offer => 
          offer.status !== 'draft' && offer.status !== 'inactive'
        ).length;

        setStats(prevStats => ({
          ...prevStats,
          totalOffers: offers.length,
          activeOffers,
          totalApplications: totalApplicationsCount,
          applicationsByOffer: applicationsByOfferData,
          recentActivity: offers
            .slice(0, 5)
            .map(offer => ({
              type: 'offer',
              title: offer.title,
              createdAt: offer.createdAt
            }))
        }));

        setLoading(false);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
        setLoading(false);
      }
    };

    fetchDashboardData();
    const unsubscribeMessages = fetchMessagesData();

    return () => {
      if (unsubscribeMessages) {
        unsubscribeMessages.then(unsub => unsub && unsub());
      }
    };
  }, [currentUser]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-100">
        <Header userType="brand" />
        <main className="pt-28 md:pt-20 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-2">
          <div className="animate-pulse mb-4">
            <div className="h-8 bg-gray-200 rounded w-1/4"></div>
            <div className="h-4 bg-gray-200 rounded w-1/3 mt-2"></div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
            {[...Array(4)].map((_, i) => (
              <CardSkeleton key={i} />
            ))}
          </div>
          <div className="bg-white rounded-lg shadow mb-8">
            <div className="p-6">
              <div className="h-6 bg-gray-200 rounded w-1/4 mb-4"></div>
              <div className="space-y-4">
                {[...Array(3)].map((_, i) => (
                  <div key={i} className="animate-pulse">
                    <div className="h-4 bg-gray-200 rounded w-full"></div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="bg-white rounded-lg shadow">
            <div className="p-6">
              <div className="h-6 bg-gray-200 rounded w-1/4 mb-4"></div>
              {[...Array(5)].map((_, i) => (
                <ListItemSkeleton key={i} />
              ))}
            </div>
          </div>
        </main>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <Header userType="brand" />
      <main className="pt-28 md:pt-20 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-2">
        {/* Welcome Section */}
        <div className="flex justify-between items-center mb-3">
          <div>
            <h1 className="text-xl font-bold mb-3 text-gray-600">
              Welcome back, {brandProfile?.name}
            </h1>
            <p className="mt-1 text-sm text-gray-500">
              Here's what's happening with your campaigns
            </p>
          </div>
          {stats.totalOffers > 0 && (
            <button
              onClick={() => navigate('/create-offer')}
              className="bg-slate-700 text-white px-4 py-2 rounded-lg hover:bg-slate-800 transition-colors text-sm"
            >
              Create New Offer
            </button>
          )}
        </div>

        {/* Stats Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
          {/* Offers Card */}
          <div className="bg-white p-6 rounded-lg shadow">
            <div className="flex items-center justify-between mb-4">
              <div>
                <p className="text-sm font-medium text-gray-600">Total Offers</p>
                <p className="text-2xl font-semibold text-gray-900">{stats.totalOffers}</p>
              </div>
              <div className="relative">
                <FaBriefcase className="h-8 w-8 text-slate-500" />
                {stats.activeOffers > 0 && (
                  <div className="absolute -top-2 -right-2 bg-green-500 text-white rounded-full h-5 w-5 flex items-center justify-center text-xs">
                    {stats.activeOffers}
                  </div>
                )}
              </div>
            </div>
            <p className="text-sm text-gray-600 mb-4">
              {stats.activeOffers} active {stats.activeOffers === 1 ? 'offer' : 'offers'}
            </p>
            {stats.totalOffers === 0 ? (
              <button
                onClick={() => navigate('/manage-offers')}
                className="w-full bg-slate-700 text-white px-4 py-2 rounded-lg hover:bg-slate-800 transition-colors text-sm"
              >
                Create First Offer
              </button>
            ) : (
              <button
                onClick={() => navigate('/manage-offers')}
                className="w-full bg-slate-700 text-white px-4 py-2 rounded-lg hover:bg-slate-800 transition-colors text-sm"
              >
                Manage Offers
              </button>
            )}
          </div>

          {/* Applications Card */}
          <div className="bg-white p-6 rounded-lg shadow">
            <div className="flex items-center justify-between mb-4">
              <div>
                <p className="text-sm font-medium text-gray-600">Total Applications</p>
                <p className="text-2xl font-semibold text-gray-900">{stats.totalApplications}</p>
              </div>
              <FaUserCheck className="h-8 w-8 text-green-500" />
            </div>
            <p className="text-sm text-gray-600 mb-4">
              Across all offers
            </p>
            <button
              onClick={() => navigate('/applications')}
              className="w-full bg-slate-700 text-white px-4 py-2 rounded-lg hover:bg-slate-800 transition-colors text-sm"
            >
              View Applications
            </button>
          </div>

          {/* Messages Card */}
          <div className="bg-white p-6 rounded-lg shadow">
            <div className="flex items-center justify-between mb-4">
              <div>
                <p className="text-sm font-medium text-gray-600">Messages</p>
                <p className="text-2xl font-semibold text-gray-900">{stats.messages.total}</p>
              </div>
              <div className="flex space-x-2">
                <FaEnvelopeOpen className="h-8 w-8 text-gray-400" />
                {stats.messages.unread > 0 && (
                  <div className="bg-red-500 text-white rounded-full h-6 w-6 flex items-center justify-center text-xs">
                    {stats.messages.unread}
                  </div>
                )}
              </div>
            </div>
            <p className="text-sm text-gray-600 mb-4">
              {stats.messages.unread} unread messages
            </p>
            <button
              onClick={() => navigate('/messages')}
              className="w-full bg-slate-700 text-white px-4 py-2 rounded-lg hover:bg-slate-800 transition-colors text-sm"
            >
              View Messages
            </button>
          </div>

          {/* Engagement Rate Card */}
          <div className="bg-white p-6 rounded-lg shadow">
            <div className="flex items-center justify-between mb-4">
              <div>
                <p className="text-sm font-medium text-gray-600">Avg. Applications</p>
                <p className="text-2xl font-semibold text-gray-900">
                  {stats.totalOffers ? (stats.totalApplications / stats.totalOffers).toFixed(1) : 0}
                </p>
              </div>
              <FaChartLine className="h-8 w-8 text-purple-500" />
            </div>
            <p className="text-sm text-gray-600 mb-4">
              Per offer
            </p>
            <button
              onClick={() => navigate('/analytics')}
              className="w-full bg-slate-700 text-white px-4 py-2 rounded-lg hover:bg-slate-800 transition-colors text-sm"
            >
              View Analytics
            </button>
          </div>
        </div>

        {/* Applications by Offer */}
        <div className="bg-white rounded-lg shadow mb-8">
          <div className="p-6">
            <h2 className="text-lg font-semibold text-gray-900 mb-4">Applications by Offer</h2>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Offer Title
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Total
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Pending
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Accepted
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Rejected
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {Object.entries(stats.applicationsByOffer).map(([offerId, data]) => (
                    <tr key={offerId}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {data.offerTitle}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {data.count}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-yellow-500">
                        {data.pending}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-green-500">
                        {data.accepted}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-red-500">
                        {data.rejected}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* Recent Activity */}
        <div className="bg-white rounded-lg shadow">
          <div className="p-6">
            <h2 className="text-lg font-semibold text-gray-900 mb-4">Recent Activity</h2>
            <div className="space-y-4">
              {stats.recentActivity.map((activity, index) => (
                <div key={index} className="flex items-center space-x-4">
                  {activity.type === 'offer' ? (
                    <FaBriefcase className="h-5 w-5 text-blue-500" />
                  ) : (
                    <FaEnvelope className="h-5 w-5 text-gray-500" />
                  )}
                  <div>
                    <p className="text-sm font-medium text-gray-900">
                      {activity.type === 'offer' ? activity.title : 'New message'}
                    </p>
                    <p className="text-xs text-gray-500">
                      {new Date(activity.createdAt?.toDate()).toLocaleDateString()}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default BrandDashboard;
