import React, { useState, useEffect } from 'react';
import { collection, query, where, onSnapshot, doc, getDoc, updateDoc, addDoc, serverTimestamp, getDocs, orderBy, runTransaction } from 'firebase/firestore';
import { ref, push, set, onValue, off } from 'firebase/database';
import { auth, db, realtimeDb } from '../firebase';
import Header from '../components/Header';
import { useToast } from '../context/ToastContext';
import { CardSkeleton } from '../components/Skeletons';
import { useNavigate } from 'react-router-dom';

const ApplicationStatus = ({ status }) => {
  const statusColors = {
    pending: 'bg-yellow-100 text-yellow-800',
    accepted: 'bg-green-100 text-green-800',
    rejected: 'bg-red-100 text-red-800',
    completed: 'bg-blue-100 text-blue-800'
    // Removed collaboration_completed status
  };

  return (
    <span className={`px-2 py-1 rounded-full text-xs font-medium ${statusColors[status] || 'bg-gray-100 text-gray-800'}`}>
      {status.charAt(0).toUpperCase() + status.slice(1)}
    </span>
  );
};

const ApplicationsScreen = () => {
  const { addToast } = useToast();
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userType, setUserType] = useState(null);
  const [error, setError] = useState(null);
  const [unreadMessages, setUnreadMessages] = useState({});
  const [showContentModal, setShowContentModal] = useState(false);
  const [selectedContent, setSelectedContent] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Applications | Behave';
  }, []);

  useEffect(() => {
    const fetchUserType = async () => {
      const user = auth.currentUser;
      if (user) {
        const brandDoc = await getDoc(doc(db, 'brands', user.uid));
        if (brandDoc.exists()) {
          setUserType('brand');
        }
      }
    };
    fetchUserType();

    const fetchOffers = async () => {
      const user = auth.currentUser;
      if (!user) return;

      try {
        const offersQuery = query(
          collection(db, 'offers'), 
          where('brandId', '==', user.uid),
          orderBy('createdAt', 'desc')
        );

        const unsubscribeOffers = onSnapshot(offersQuery, async (snapshot) => {
          try {
            const offerList = [];
            
            // Process each offer
            for (const offerDoc of snapshot.docs) {
              const offerData = offerDoc.data();
              
              // Make sure imageUrl/image is included in the offer data
              const offerWithImage = {
                id: offerDoc.id,
                ...offerData,
                image: offerData.imageUrl || offerData.image || 'https://via.placeholder.com/400x200', // Fallback image
              };
              
              // Query applications for this specific offer
              const applicationsQuery = query(
                collection(db, 'applications'),
                where('offerId', '==', offerDoc.id),
                where('brandId', '==', user.uid)
              );

              const applicationsSnapshot = await getDocs(applicationsQuery);
              
              // Process applications
              const applications = await Promise.all(
                applicationsSnapshot.docs.map(async (appDoc) => {
                  const appData = appDoc.data();
                  const creatorDoc = await getDoc(doc(db, 'creators', appData.creatorId));
                  const creatorData = creatorDoc.exists() ? creatorDoc.data() : null;
                  
                  return {
                    id: appDoc.id,
                    ...appData,
                    creatorName: creatorData?.name || 'Unknown Creator',
                    creatorProfilePicture: creatorData?.profilePicture || 'https://via.placeholder.com/50'
                  };
                })
              );

              offerList.push({
                ...offerWithImage,
                applications
              });
            }

            setOffers(offerList);
            setLoading(false);
          } catch (err) {
            console.error("Error processing offers and applications:", err);
            setError("Failed to load applications. Please try again later.");
            setLoading(false);
          }
        });

        return () => unsubscribeOffers();
      } catch (err) {
        console.error("Error setting up offers listener:", err);
        setError("Failed to connect to the server. Please try again later.");
        setLoading(false);
      }
    };

    fetchOffers();
  }, []);

  useEffect(() => {
    const user = auth.currentUser;
    if (!user) return;

    const unreadMessagesRef = ref(realtimeDb, `unreadMessages/${user.uid}`);
    const unsubscribe = onValue(unreadMessagesRef, (snapshot) => {
      const unreadData = snapshot.val() || {};
      setUnreadMessages(unreadData);
    });

    return () => off(unreadMessagesRef);
  }, []);
  

  const handleApplicationDecision = async (applicationId, offerId, decision) => {
    try {
      await updateDoc(doc(db, 'applications', applicationId), {
        status: decision,
        updatedAt: serverTimestamp()
      });

      // Notify the creator
      await addDoc(collection(db, 'messages'), {
        applicationId: applicationId,
        offerId: offerId,
        senderId: auth.currentUser.uid,
        recipientId: offers.find(o => o.id === offerId).applications.find(a => a.id === applicationId).creatorId,
        message: `Your application has been ${decision}.`,
        timestamp: serverTimestamp(),
        read: false
      });

      addToast(`Application ${decision} successfully`, 'success');
    } catch (error) {
      console.error('Error updating application:', error);
      addToast(`Error ${decision} application: ${error.message}`, 'error');
    }
  };

  const handleMessageCreator = (application) => {
    // Redirect to MessagesScreen with the application info
    navigate('/messages', { 
      state: { 
        applicationId: application.id, 
        offerId: application.offerId,
        creatorId: application.creatorId
      } 
    });
  };

  const handleCompleteCollaboration = async (application, offerType) => {
    try {
      await runTransaction(db, async (transaction) => {
        const creatorRef = doc(db, 'creators', application.creatorId);
        const creatorDoc = await transaction.get(creatorRef);

        if (!creatorDoc.exists()) {
          throw "Creator document does not exist!";
        }

        let creditsToAward = 0;
        switch (offerType) {
          case '1story':
            creditsToAward = 15;
            break;
          case '2stories':
            creditsToAward = 30;
            break;
          case 'storiesPost':
            creditsToAward = 50;
            break;
          default:
            creditsToAward = 0;
        }

        const newCredits = creatorDoc.data().credits + creditsToAward;
        transaction.update(creatorRef, { credits: newCredits });

        // Update application status
        const applicationRef = doc(db, 'applications', application.id);
        transaction.update(applicationRef, { status: 'completed' });

        // Add to audit trail
        await addDoc(collection(db, 'audit'), {
          action: 'collaboration_completed',
          applicationId: application.id,
          creatorId: application.creatorId,
          brandId: auth.currentUser.uid,
          creditsAwarded: creditsToAward,
          timestamp: new Date()
        });
      });

      addToast('Collaboration completed successfully', 'success');
    } catch (error) {
      console.error('Error completing collaboration:', error);
      addToast('Error completing collaboration: ' + error.message, 'error');
    }
  };

  const handleViewContent = (application) => {
    setSelectedContent(application.contentInputs);
    setShowContentModal(true);
  };

  const handleFinalizeCollaboration = async (applicationId) => {
    try {
      const applicationRef = doc(db, 'applications', applicationId);
      await updateDoc(applicationRef, {
        status: 'completed', // Changed from 'collaboration_completed'
        completedAt: serverTimestamp()
      });
      addToast('Collaboration finalized successfully', 'success');
    } catch (error) {
      console.error('Error finalizing collaboration:', error);
      addToast('Error finalizing collaboration', 'error');
    }
  };

  if (error) return <div className="text-red-500">{error}</div>;

  return (
    <div className="min-h-screen bg-gray-50">
      <Header userType={userType} />
      <main className="pt-20 md:pt-20 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-2">
        <div className="flex flex-col md:flex-row justify-between mb-6">
          <h1 className="text-xl font-bold mb-3 text-gray-500">Received Applications</h1>
          <div className="text-sm text-gray-500">
            Total Offers: {offers.length} | Total Applications: {offers.reduce((acc, offer) => acc + offer.applications.length, 0)}
          </div>
        </div>

        {loading ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {Array(3).fill().map((_, index) => (
              <CardSkeleton key={index} />
            ))}
          </div>
        ) : offers.length === 0 ? (
          <div className="text-center py-12 bg-white rounded-lg shadow">
            <h3 className="text-lg font-medium text-gray-900 mb-2">No Offers Yet</h3>
            <p className="text-gray-500">Create your first offer to start receiving applications.</p>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {offers.map((offer) => {
              console.log('Offer image URL:', offer.image); // Add this debug line
              return (
                <div key={offer.id} className="bg-white shadow-lg rounded-lg overflow-hidden flex flex-col h-full">
                  <div 
                    className="p-4 border-b relative min-h-[150px]"
                    style={{
                      backgroundImage: offer.image 
                        ? `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("${offer.image}")`
                        : 'linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6))',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      backgroundColor: '#2d3748'
                    }}
                  >
                    <h2 className="text-xl font-bold text-white mb-3 line-clamp-1">{offer.title}</h2>
                    <div className="space-y-2">
                      <div className="flex items-center gap-2 text-sm text-gray-200">
                        <span className="font-medium">Collaboration Type:</span>
                        <span>{offer.collaborationType || 'Not specified'}</span>
                      </div>
                      <div className="flex items-center gap-2 text-sm text-gray-200">
                        <span className="font-medium">Offer Type:</span>
                        <span>{offer.type || 'Not specified'}</span>
                      </div>
                      <div className="flex items-center gap-2 text-sm text-gray-200">
                        <span className="font-medium">Compensation:</span>
                        <span>{offer.compensation || 'Not specified'}</span>
                      </div>
                    </div>
                    <div className="mt-3 flex items-center gap-2 text-xs text-gray-200">
                      <span className="bg-blue-500 bg-opacity-50 px-2 py-1 rounded-full">{offer.applications.length} applications</span>
                      <span>•</span>
                      <span>{offer.createdAt?.toDate().toLocaleDateString()}</span>
                    </div>
                  </div>

                  <div className="p-4 flex-grow">
                    <h3 className="text-md font-semibold text-gray-900 mb-3">Applications</h3>
                    {offer.applications && offer.applications.length > 0 ? (
                      <div className="space-y-3">
                        {offer.applications.map((application) => (
                          <div key={application.id} 
                               className="bg-gray-50 rounded-lg p-3 transition-all duration-200 hover:shadow-md">
                            <div className="flex items-start space-x-3">
                              <img 
                                src={application.creatorProfilePicture} 
                                alt={application.creatorName} 
                                className="w-10 h-10 rounded-full border-2 border-white shadow flex-shrink-0"
                              />
                              <div className="min-w-0 flex-1">
                                <p className="font-semibold text-gray-900 truncate">{application.creatorName}</p>
                                <div className="flex items-center gap-2 mt-1 flex-wrap">
                                  <ApplicationStatus status={application.status} />
                                  <span className="text-xs text-gray-500">
                                    {application.createdAt?.toDate().toLocaleDateString()}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 flex flex-wrap gap-2">
                              {/* Keep existing buttons but make them smaller */}
                              <button 
                                onClick={() => handleMessageCreator(application)}
                                className="inline-flex items-center px-3 py-1.5 text-xs border border-transparent rounded-md shadow-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                              >
                                Message
                              </button>

                              {application.status === 'pending' && (
                                <>
                                  <button 
                                    onClick={() => handleApplicationDecision(application.id, offer.id, 'accepted')}
                                    className="inline-flex items-center px-3 py-1.5 text-xs border border-transparent rounded-md shadow-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                  >
                                    Accept
                                  </button>
                                  <button 
                                    onClick={() => handleApplicationDecision(application.id, offer.id, 'rejected')}
                                    className="inline-flex items-center px-3 py-1.5 text-xs border border-transparent rounded-md shadow-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                  >
                                    Reject
                                  </button>
                                </>
                              )}

                              {application.status === 'accepted' && (
                                <button
                                  onClick={() => handleCompleteCollaboration(application, offer.type)}
                                  className="inline-flex items-center px-3 py-1.5 text-xs border border-transparent rounded-md shadow-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                >
                                  Complete Collaboration
                                </button>
                              )}

                              {application.contentInputs && (
                                <>
                                  <button 
                                    onClick={() => handleViewContent(application)}
                                    className="inline-flex items-center px-3 py-1.5 text-xs border border-gray-300 rounded-md shadow-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                  >
                                    View Content
                                  </button>
                                  <button
                                    onClick={() => handleFinalizeCollaboration(application.id)}
                                    className="inline-flex items-center px-3 py-1.5 text-xs border border-transparent rounded-md shadow-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                                  >
                                    Finalize Collaboration
                                  </button>
                                </>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="text-center py-6 bg-gray-50 rounded-lg">
                        <p className="text-sm text-gray-500">No applications yet</p>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </main>

      {showContentModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg shadow-xl w-full max-w-4xl max-h-[90vh] overflow-hidden">
            {/* Header */}
            <div className="px-6 py-4 border-b border-gray-200 flex justify-between items-center">
              <h3 className="text-xl font-semibold text-gray-900">Creator's Content</h3>
              <button
                onClick={() => setShowContentModal(false)}
                className="text-gray-400 hover:text-gray-500 focus:outline-none"
              >
                <span className="sr-only">Close</span>
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>

            {/* Content */}
            <div className="px-6 py-4 overflow-y-auto max-h-[calc(90vh-8rem)]">
              <div className="space-y-6">
                {selectedContent && Object.entries(selectedContent).map(([type, content]) => (
                  <div key={type} className="space-y-3">
                    <h4 className="font-semibold text-lg text-gray-900 capitalize">
                      {type.replace(/([A-Z])/g, ' $1').trim()}:
                    </h4>
                    <div className="space-y-4">
                      {Array.isArray(content) ? (
                        content.map((item, index) => (
                          <div key={index} className="rounded-lg overflow-hidden bg-gray-50 p-2">
                            {type.toLowerCase().includes('url') ? (
                              <a 
                                href={item} 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                className="text-blue-600 hover:text-blue-800 hover:underline break-all"
                              >
                                {item}
                              </a>
                            ) : type.toLowerCase().includes('image') ? (
                              <div className="relative">
                                <img 
                                  src={item} 
                                  alt={`Content ${index + 1}`} 
                                  className="w-full h-auto max-h-[500px] object-contain mx-auto rounded"
                                  loading="lazy"
                                />
                              </div>
                            ) : type.toLowerCase().includes('video') ? (
                              <div className="relative aspect-video">
                                <video 
                                  src={item} 
                                  controls 
                                  className="w-full h-auto max-h-[500px] object-contain mx-auto rounded"
                                >
                                  Your browser does not support the video tag.
                                </video>
                              </div>
                            ) : (
                              <p className="text-gray-700 break-words">{item}</p>
                            )}
                          </div>
                        ))
                      ) : (
                        <p className="text-gray-700 break-words">{content}</p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Footer */}
            <div className="px-6 py-4 border-t border-gray-200 flex justify-end">
              <button
                onClick={() => setShowContentModal(false)}
                className="px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-800 rounded-md transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ApplicationsScreen;
