import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const PrivateRoute = ({ children, adminOnly }) => {
  const { currentUser, isAdmin, loading } = useAuth();

  console.log('PrivateRoute - currentUser:', currentUser);
  console.log('PrivateRoute - isAdmin:', isAdmin);
  console.log('PrivateRoute - adminOnly:', adminOnly);

  if (loading) {
    return <div>Loading...</div>;
  }

  // Check for admin access
  if (adminOnly && !isAdmin) {
    console.log('Access denied: Admin only route');
    return <Navigate to="/dashboard" replace />;
  }

  // Check for authentication
  if (!currentUser) {
    return <Navigate to="/login" replace />;
  }

  return children ? children : <Outlet />;
};

export default PrivateRoute;
