import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useToast } from '../context/ToastContext';

const LogoffScreen = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { addToast } = useToast();

  useEffect(() => {
    const performLogoff = async () => {
      try {
        await logout();
        addToast('You have been successfully logged out', 'success');
        navigate('/login');
      } catch (error) {
        console.error('Logoff error:', error);
        addToast('An error occurred during logoff', 'error');
        navigate('/login');
      }
    };

    performLogoff();
  }, [logout, navigate, addToast]);

  return (
    <div className="flex justify-center items-center h-screen">
      <p>Logging off...</p>
    </div>
  );
};

export default LogoffScreen;
