import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import { useAuth } from '../context/AuthContext';

const Layout = () => {
  const { userType } = useAuth();

  return (
    <>
      <Header userType={userType} />
      <Outlet />
    </>
  );
};

export default Layout;
