import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { doc, getDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { auth, db, storage } from '../firebase';
import { deleteUser } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import { getStorage, ref, uploadString, getDownloadURL, uploadBytes } from 'firebase/storage';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { useToast } from '../context/ToastContext';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { useGlobalContext } from '../context/GlobalContext';

const BrandProfileScreen = () => {
  const { addToast } = useToast();
  const [profile, setProfile] = useState({
    name: '',
    bio: '',
    website: '',
    profilePicture: '',
    venueName: '',
    venueType: '',
    venueDetails: null,
    contactPhone: '',
    ownerName: '',
    ownerPhone: '',
    ownerEmail: '',
    businessName: '',
    fiscalCode: '',
  });
  const [loading, setLoading] = useState(true);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [venues, setVenues] = useState([]);
  const [newVenue, setNewVenue] = useState(null);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState(null);
  const [uploading, setUploading] = useState(false);
  const imageRef = useRef(null);
  const { setRefreshHeaderCount } = useGlobalContext() || {};

  const inputBaseClasses = "block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed";
  const labelBaseClasses = "block mb-2 text-sm font-medium text-gray-900";
  const buttonBaseClasses = "text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none";

  useEffect(() => {
    const fetchProfile = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const docRef = doc(db, 'brands', user.uid);
          const docSnap = await getDoc(docRef);
          
          if (docSnap.exists()) {
            const data = docSnap.data();
            setProfile(data);
            
            const loadedVenues = [];
            
            // Add primary venue if it exists
            if (data.venueDetails) {
              loadedVenues.push({
                name: data.venueName,
                type: data.venueType,
                city: data.venueDetails.city,
                location: data.venueDetails.location,
                phoneNumber: data.venueDetails.phoneNumber,
                types: data.venueDetails.types,
                website: data.venueDetails.website,
                address: data.venueDetails.address,
                openingHours: data.venueDetails.openingHours,
                rating: data.venueDetails.rating,
                businessStatus: data.venueDetails.businessStatus,
                placeId: data.venueDetails.placeId,
                isPrimary: true
              });
            }

            // Add additional venues if they exist
            if (data.venues && Array.isArray(data.venues)) {
              loadedVenues.push(...data.venues.map(venue => ({
                ...venue,
                isPrimary: false
              })));
            }

            console.log('Loaded venues:', loadedVenues);
            setVenues(loadedVenues);
          }
          setLoading(false);
        } catch (error) {
          console.error('Error fetching profile:', error);
          addToast('Error loading profile data', 'error');
          setLoading(false);
        }
      }
    };
    
    fetchProfile();
  }, [addToast]);

  useEffect(() => {
    document.title = 'Brand Profile | Behave';
  }, []);

  const handleChange = (e) => {
    setProfile({ ...profile, [e.target.name]: e.target.value });
  };

  // Helper function to save venues to Firebase
  const saveVenuesToFirebase = async (updatedVenues) => {
    const user = auth.currentUser;
    if (!user) return;

    try {
      const primaryVenue = updatedVenues.find(v => v.isPrimary);
      const additionalVenues = updatedVenues.filter(v => !v.isPrimary);

      const updatedProfile = {
        ...profile,
        // Primary venue details
        venueName: primaryVenue?.name || '',
        venueType: primaryVenue?.type || '',
        venueDetails: primaryVenue ? {
          city: primaryVenue.city,
          location: primaryVenue.location,
          phoneNumber: primaryVenue.phoneNumber,
          types: primaryVenue.types,
          website: primaryVenue.website,
          address: primaryVenue.address,
          openingHours: primaryVenue.openingHours,
          rating: primaryVenue.rating,
          businessStatus: primaryVenue.businessStatus,
          placeId: primaryVenue.placeId
        } : null,
        // Store additional venues
        venues: additionalVenues
      };

      console.log('Saving to Firebase:', updatedProfile);
      const docRef = doc(db, 'brands', user.uid);
      await updateDoc(docRef, updatedProfile);
      return true;
    } catch (error) {
      console.error('Error saving venues:', error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = auth.currentUser;
    if (user) {
      try {
        await saveVenuesToFirebase(venues);
        addToast('Profile updated successfully', 'success');
      } catch (error) {
        console.error('Error updating profile:', error);
        addToast(`Error updating profile: ${error.message}`, 'error');
      }
    }
  };

  const handleDeleteAccount = async () => {
    try {
      const user = auth.currentUser;
      await deleteDoc(doc(db, 'brands', user.uid));
      await deleteUser(user);
      addToast('Account deleted successfully', 'success');
      navigate('/login');
    } catch (error) {
      console.error('Error deleting account:', error);
      addToast('Error deleting account: ' + error.message, 'error');
    }
  };

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImageSrc(reader.result);
        setIsModalOpen(true);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e) {
    const { width, height } = e.currentTarget;
    const newCrop = centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        1,
        width,
        height
      ),
      width,
      height
    );
    setCrop(newCrop);
  }

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    setImageSrc(null);
    setCrop(undefined);
    setCompletedCrop(null);
  }, []);

  const handleSaveImage = useCallback(async () => {
    if (!completedCrop || !imageRef.current) {
      return;
    }

    setUploading(true);
    try {
      const croppedImageBlob = await getCroppedImg(imageRef.current, completedCrop);
      
      if (!croppedImageBlob) {
        throw new Error('Failed to create image blob');
      }
      
      const storageRef = ref(storage, `profile-pictures/${auth.currentUser.uid}`);
      await uploadBytes(storageRef, croppedImageBlob);

      const downloadURL = await getDownloadURL(storageRef);

      if (!downloadURL) {
        throw new Error('Failed to get download URL');
      }

      const docRef = doc(db, 'brands', auth.currentUser.uid);
      await updateDoc(docRef, {
        profilePicture: downloadURL
      });

      setProfile(prev => ({
        ...prev,
        profilePicture: downloadURL
      }));

      if (typeof setRefreshHeaderCount === 'function') {
        setRefreshHeaderCount(prev => prev + 1);
      }

      handleCloseModal();
      addToast('Profile picture updated successfully', 'success');
    } catch (error) {
      addToast(`Error uploading image: ${error.message}`, 'error');
    } finally {
      setUploading(false);
    }
  }, [completedCrop, auth.currentUser.uid, addToast, handleCloseModal, setRefreshHeaderCount]);

  const ProfilePictureSection = (
    <div className="mb-4">
      <label className={labelBaseClasses}>Profile Picture</label>
      <div className="flex items-center space-x-6">
        <div className="relative inline-block min-w-24">
          <img
            src={profile.profilePicture || 'https://via.placeholder.com/150'}
            alt="Profile"
            className="h-24 w-24 rounded-full object-cover ring-2 ring-white shadow-md"
          />
          <label
            htmlFor="profile-upload"
            className="absolute bottom-0 right-0 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-4 w-4">
              <path d="M13.586 3.586a2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
            </svg>
            <input
              id="profile-upload"
              type="file"
              className="hidden"
              accept="image/*"
              onChange={onSelectFile}
            />
          </label>
        </div>
        <div className="text-sm text-gray-600">
          <p>Upload a new profile picture</p>
          <p>Recommended: Square image, at least 400x400px</p>
        </div>
      </div>
    </div>
  );

  const ImageCropModal = useMemo(() => {
    if (!isModalOpen) return null;

    return (
      <div className="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-75">
        <div className="flex min-h-full items-center justify-center p-4">
          <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl w-full max-w-2xl">
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">
                  Crop Profile Picture
                </h3>
                <div className="mt-2">
                  {imageSrc && (
                    <ReactCrop
                      crop={crop}
                      onChange={(_, percentCrop) => setCrop(percentCrop)}
                      onComplete={(c) => setCompletedCrop(c)}
                      aspect={1}
                      circularCrop
                      className="max-h-[60vh]"
                    >
                      <img
                        ref={imageRef}
                        alt="Crop me"
                        src={imageSrc}
                        onLoad={onImageLoad}
                        className="max-w-full"
                      />
                    </ReactCrop>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse gap-2">
              <button
                type="button"
                className={`${buttonBaseClasses} ${uploading ? 'opacity-50 cursor-not-allowed' : ''}`}
                onClick={handleSaveImage}
                disabled={uploading || !completedCrop}
              >
                {uploading ? 'Saving...' : 'Save'}
              </button>
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-lg border border-gray-300 bg-white px-4 py-2.5 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:mt-0 sm:w-auto"
                onClick={handleCloseModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }, [isModalOpen, imageSrc, crop, completedCrop, uploading, handleSaveImage, handleCloseModal]);

  // Update handleAddVenue
  const handleAddVenue = async () => {
    if (!newVenue) return;

    try {
      const placeId = newVenue.value.place_id;
      const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));

      placesService.getDetails(
        {
          placeId: placeId,
          fields: ['name', 'formatted_address', 'geometry', 'types', 'website', 'formatted_phone_number', 'opening_hours', 'rating', 'business_status', 'place_id']
        },
        async (place, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            const newVenueData = {
              name: place.name,
              address: place.formatted_address,
              city: place.formatted_address?.split(',')[1]?.trim() || '',
              location: {
                lat: place.geometry.location.lat() || 0,
                lng: place.geometry.location.lng() || 0
              },
              phoneNumber: place.formatted_phone_number || null,
              types: place.types || [],
              website: place.website || null,
              openingHours: place.opening_hours?.weekday_text || [],
              rating: place.rating || null,
              businessStatus: place.business_status || null,
              placeId: place.place_id,
              isPrimary: venues.length === 0
            };

            const updatedVenues = [...venues, newVenueData];
            
            try {
              await saveVenuesToFirebase(updatedVenues);
              setVenues(updatedVenues);
              setNewVenue(null);
              addToast('Venue added successfully', 'success');
            } catch (error) {
              console.error('Error in handleAddVenue:', error);
              addToast('Error saving venue', 'error');
            }
          }
        }
      );
    } catch (error) {
      console.error('Error adding venue:', error);
      addToast('Error adding venue', 'error');
    }
  };

  // Update handleSetPrimary
  const handleSetPrimary = async (placeId) => {
    try {
      const updatedVenues = venues.map(venue => ({
        ...venue,
        isPrimary: venue.placeId === placeId
      }));
      
      await saveVenuesToFirebase(updatedVenues);
      setVenues(updatedVenues);
      addToast('Primary venue updated', 'success');
    } catch (error) {
      console.error('Error setting primary venue:', error);
      addToast('Error updating primary venue', 'error');
    }
  };

  // Update handleRemoveVenue
  const handleRemoveVenue = async (placeId) => {
    try {
      const updatedVenues = venues.filter(venue => venue.placeId !== placeId);
      // If we removed the primary venue and there are other venues, make the first one primary
      if (venues.find(v => v.placeId === placeId)?.isPrimary && updatedVenues.length > 0) {
        updatedVenues[0].isPrimary = true;
      }
      
      await saveVenuesToFirebase(updatedVenues);
      setVenues(updatedVenues);
      addToast('Venue removed successfully', 'success');
    } catch (error) {
      console.error('Error removing venue:', error);
      addToast('Error removing venue', 'error');
    }
  };

  // Update VenuesSection component
  const VenuesSection = () => (
    <div className="mb-6">
      <h2 className="text-lg font-semibold mb-4 pb-2 border-b">Venues</h2>
      <div className="space-y-4">
        {/* List all venues */}
        {venues.map((venue) => (
          <div 
            key={venue.placeId}
            className={`p-4 border rounded-lg ${venue.isPrimary ? 'bg-blue-50 border-blue-200' : 'bg-white'}`}
          >
            <div className="flex justify-between items-start mb-2">
              <h3 className="font-medium flex items-center gap-2">
                {venue.name}
                {venue.isPrimary && (
                  <span className="text-xs bg-blue-100 text-blue-800 px-2 py-1 rounded">
                    Primary
                  </span>
                )}
              </h3>
              <div className="flex gap-2">
                {!venue.isPrimary && (
                  <button
                    type="button"
                    onClick={() => handleSetPrimary(venue.placeId)}
                    className="text-blue-600 hover:text-blue-800 text-sm"
                  >
                    Set as Primary
                  </button>
                )}
                <button
                  type="button"
                  onClick={() => handleRemoveVenue(venue.placeId)}
                  className="text-red-600 hover:text-red-800 text-sm"
                >
                  Remove
                </button>
              </div>
            </div>
            <div className="space-y-2">
              <p className="text-sm"><span className="font-medium">Address:</span> {venue.address}</p>
              <p className="text-sm"><span className="font-medium">City:</span> {venue.city}</p>
              {venue.phoneNumber && (
                <p className="text-sm"><span className="font-medium">Phone:</span> {venue.phoneNumber}</p>
              )}
              {venue.website && (
                <p className="text-sm"><span className="font-medium">Website:</span> {venue.website}</p>
              )}
              {venue.rating && (
                <p className="text-sm"><span className="font-medium">Rating:</span> {venue.rating}</p>
              )}
              {venue.businessStatus && (
                <p className="text-sm">
                  <span className="font-medium">Status:</span> {venue.businessStatus.toLowerCase()}
                </p>
              )}
              {venue.openingHours && venue.openingHours.length > 0 && (
                <div className="mt-2">
                  <p className="text-sm font-medium mb-1">Opening Hours:</p>
                  <div className="bg-gray-50 p-2 rounded text-sm">
                    {venue.openingHours.map((hours, idx) => (
                      <p key={`${venue.placeId}-hours-${idx}`} className="text-gray-600">
                        {hours}
                      </p>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}

        {/* Add New Venue */}
        <div className="mt-4">
          <label className={labelBaseClasses}>Add New Venue</label>
          <div className="flex gap-2">
            <div className="flex-grow">
              <GooglePlacesAutocomplete
                apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                selectProps={{
                  value: newVenue,
                  onChange: setNewVenue,
                  placeholder: 'Search for a venue...',
                  className: 'google-places-autocomplete',
                  classNamePrefix: 'google-places'
                }}
              />
            </div>
            <button
              type="button"
              onClick={handleAddVenue}
              disabled={!newVenue}
              className={`${buttonBaseClasses} ${!newVenue ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    console.log('Current venues:', venues);
  }, [venues]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <Header userType="brand" />
      <main className="pt-28 md:pt-20 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-2">
        <h1 className="text-xl font-bold mb-3 text-gray-500">Edit Profile</h1>
        <form onSubmit={handleSubmit} className="bg-white shadow-md rounded-lg px-8 pt-6 pb-8 mb-4">
          {/* Profile Picture Section - Full Width */}
          <div className="mb-8 border-b pb-6">
            {ProfilePictureSection}
          </div>

          {/* Two Column Layout Container */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-8">
            {/* Left Column */}
            <div className="space-y-8">
              {/* Basic Information Section */}
              <div className="mb-6">
                <h2 className="text-lg font-semibold mb-4 pb-2 border-b">Basic Information</h2>
                <div className="space-y-4">
                  <div>
                    <label className={labelBaseClasses} htmlFor="name">Brand Name</label>
                    <input
                      className={inputBaseClasses}
                      id="name"
                      type="text"
                      name="name"
                      value={profile.name}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div>
                    <label className={labelBaseClasses} htmlFor="bio">Bio</label>
                    <textarea
                      className={inputBaseClasses}
                      id="bio"
                      name="bio"
                      value={profile.bio}
                      onChange={handleChange}
                      required
                      rows="4"
                    />
                  </div>

                  <div>
                    <label className={labelBaseClasses} htmlFor="website">Website</label>
                    <input
                      className={inputBaseClasses}
                      id="website"
                      type="url"
                      name="website"
                      value={profile.website}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              {/* Business Information Section */}
              <div className="mb-6">
                <h2 className="text-lg font-semibold mb-4 pb-2 border-b">Business Information</h2>
                <div className="space-y-4">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <label className={labelBaseClasses} htmlFor="businessName">
                        Business Name (SRL / SA)
                      </label>
                      <input
                        className={inputBaseClasses}
                        id="businessName"
                        type="text"
                        name="businessName"
                        value={profile.businessName}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div>
                      <label className={labelBaseClasses} htmlFor="fiscalCode">
                        Fiscal Code (CIF)
                      </label>
                      <input
                        className={inputBaseClasses}
                        id="fiscalCode"
                        type="text"
                        name="fiscalCode"
                        value={profile.fiscalCode}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <label className={labelBaseClasses} htmlFor="contactPhone">
                      Contact Phone
                    </label>
                    <input
                      className={inputBaseClasses}
                      id="contactPhone"
                      type="tel"
                      name="contactPhone"
                      value={profile.contactPhone}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Right Column */}
            <div className="space-y-8">
              {/* Owner Information Section */}
              <div className="mb-6">
                <h2 className="text-lg font-semibold mb-4 pb-2 border-b">Owner Information</h2>
                <div className="space-y-4">
                  <div>
                    <label className={labelBaseClasses} htmlFor="ownerName">
                      Owner Name
                    </label>
                    <input
                      className={inputBaseClasses}
                      id="ownerName"
                      type="text"
                      name="ownerName"
                      value={profile.ownerName}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <label className={labelBaseClasses} htmlFor="ownerPhone">
                        Owner Phone
                      </label>
                      <input
                        className={inputBaseClasses}
                        id="ownerPhone"
                        type="tel"
                        name="ownerPhone"
                        value={profile.ownerPhone}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div>
                      <label className={labelBaseClasses} htmlFor="ownerEmail">
                        Owner Email
                      </label>
                      <input
                        className={inputBaseClasses}
                        id="ownerEmail"
                        type="email"
                        name="ownerEmail"
                        value={profile.ownerEmail}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Venues Section */}
              <VenuesSection />
            </div>
          </div>

          {/* Action Buttons - Full Width */}
          <div className="mt-8 pt-6 border-t flex items-center justify-between">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2.5 px-6 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors duration-200"
              type="submit"
            >
              Update Profile
            </button>
            <button
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2.5 px-6 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition-colors duration-200"
              type="button"
              onClick={() => setShowDeleteConfirmation(true)}
            >
              Delete Account
            </button>
          </div>
        </form>
        {ImageCropModal}
        {showDeleteConfirmation && (
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
              </div>
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                        Delete Account
                      </h3>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to delete your account? This action cannot be undone.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm" onClick={handleDeleteAccount}>
                    Delete
                  </button>
                  <button type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" onClick={() => setShowDeleteConfirmation(false)}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

function getCroppedImg(image, crop) {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const ctx = canvas.getContext('2d');

  canvas.width = crop.width;
  canvas.height = crop.height;

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  );

  return new Promise((resolve, reject) => {
    try {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            reject(new Error('Canvas to Blob failed'));
            return;
          }
          resolve(blob);
        },
        'image/jpeg',
        0.95
      );
    } catch (e) {
      reject(e);
    }
  });
}

export default BrandProfileScreen;
