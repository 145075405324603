import { initializeApp } from 'firebase/app';
import { getAuth, FacebookAuthProvider } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';
import { getDatabase } from 'firebase/database';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const realtimeDb = getDatabase(app);
export const messaging = getMessaging(app);

// Configure Facebook provider with Instagram permissions
const facebookProvider = new FacebookAuthProvider();
facebookProvider.addScope('instagram_basic');
facebookProvider.addScope('instagram_content_publish');
facebookProvider.addScope('instagram_manage_insights');
facebookProvider.addScope('instagram_manage_comments');

export { facebookProvider };

export const requestNotificationPermission = async () => {
  try {
    if (!('Notification' in window)) {
      console.log('This browser does not support notifications');
      return null;
    }

    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      // Check if service worker is supported and registered
      if ('serviceWorker' in navigator) {
        try {
          const registration = await navigator.serviceWorker.ready;
          const token = await getToken(messaging, { 
            vapidKey: process.env.REACT_APP_FIREBASE_VAPID_PUBLIC_KEY,
            serviceWorkerRegistration: registration
          });
          console.log('Notification permission granted. Token:', token);
          return token;
        } catch (tokenError) {
          console.error('Error getting token:', tokenError);
          return null;
        }
      } else {
        console.log('Service workers are not supported');
        return null;
      }
    } else {
      console.log('Permission not granted');
      return null;
    }
  } catch (error) {
    console.error('An error occurred while requesting permission:', error);
    return null;
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
