import React, { createContext, useState, useContext } from 'react';

export const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const [toast, setToast] = useState(null);

  const addToast = (message, type = 'info') => {
    setToast({ message, type });
    setTimeout(() => setToast(null), 3000);
  };

  return (
    <ToastContext.Provider value={{ addToast }}>
      {children}
      {toast && (
        <div className={`fixed top-[150px] left-1/2 transform -translate-x-1/2 bg-${toast.type === 'error' ? 'red' : 'green'}-500 text-white px-4 py-2 rounded shadow-lg animate-toast-slide transition-all duration-300 ease-in-out`}>
          {toast.message}
        </div>
      )}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};
