import React, { useState, useEffect } from 'react';
import { collection, addDoc, onSnapshot, query, where, doc, updateDoc, getDoc, runTransaction, getDocs, setDoc, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { auth, db, storage } from '../firebase';
import Header from '../components/Header';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { useToast } from '../context/ToastContext';
import EditOfferModal from '../components/EditOfferModal';
import { Link } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import parse from 'html-react-parser';

// Add this function at the top of the file
const normalizeCityName = (cityName) => {
  return cityName
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .trim();
};

const BrandOfferScreen = () => {
  const { addToast } = useToast();
  const [userType, setUserType] = useState(null);
  const [offers, setOffers] = useState([]);
  const [newOffer, setNewOffer] = useState({
    title: '',
    description: '',
    compensation: '',
    venue: '',
    startDate: '',
    endDate: '',
    offerTypes: {
      '1story': false,
      '2stories': false,
      'storiesPost': false
    },
  });
  const [applications, setApplications] = useState([]);
  const [credits, setCredits] = useState(0);
  const [offerImage, setOfferImage] = useState(null);
  const [offerImageUrl, setOfferImageUrl] = useState('');
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [address, setAddress] = useState(null);
  const [coordinates, setCoordinates] = useState(null);
  const [city, setCity] = useState('');
  const [unreadMessages, setUnreadMessages] = useState({});
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [showChatModal, setShowChatModal] = useState(false);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [offerToDelete, setOfferToDelete] = useState(null);
  const [brandVenues, setBrandVenues] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState(null);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showContentModal, setShowContentModal] = useState(false);
  const [selectedContent, setSelectedContent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newVenue, setNewVenue] = useState(null);
  const [isDraft, setIsDraft] = useState(false);
  const [draftOffers, setDraftOffers] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editingOfferId, setEditingOfferId] = useState(null);
  const [collabType, setCollabType] = useState('Barter');

  // Quill editor modules configuration
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      ['link'],
      ['clean']
    ],
  };

  const generateTags = async () => {
    const tagsToAdd = [
      'Barter',
      'Paid Collaboration',
      'Partnership',
      'Product Review',
      'Sponsored Post',
      'Brand Ambassador',
      'Event Promotion',
      'Affiliate Marketing',
      'Giveaway',
      'Content Creation',
      'Social Media Takeover',
      'Unboxing',
      'Tutorial',
      'Lifestyle',
      'Beauty',
      'Fashion',
      'Tech',
      'Food',
      'Travel',
      'Fitness',
      'Wellness'
    ];

    const tagsCollection = collection(db, 'tags');
    const tagsSnapshot = await getDocs(tagsCollection);

    if (tagsSnapshot.empty) {
      for (const tag of tagsToAdd) {
        await setDoc(doc(tagsCollection), { name: tag });
      }
      console.log('Tags have been added to the database');
    } else {
      console.log('Tags collection is not empty, skipping tag generation');
    }
  };

  useEffect(() => {
    document.title = 'Brand Offers | Behave';
  }, []);

  useEffect(() => {
    const fetchUserType = async () => {
      const user = auth.currentUser;
      if (user) {
        const brandDoc = await getDoc(doc(db, 'brands', user.uid));
        if (brandDoc.exists()) {
          setUserType('brand');
        }
      }
    };
    fetchUserType();
    

    const fetchOffers = () => {
      const user = auth.currentUser;
      if (user) {
        const offersQuery = query(collection(db, 'offers'), where('brandId', '==', user.uid));
        const unsubscribeOffers = onSnapshot(offersQuery, (querySnapshot) => {
          const offerList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setOffers(offerList);
        });

        return unsubscribeOffers;
      }
    };

    const fetchApplications = () => {
      const user = auth.currentUser;
      if (user) {
        const applicationsQuery = query(collection(db, 'applications'), where('brandId', '==', user.uid));
        const unsubscribeApplications = onSnapshot(applicationsQuery, async (querySnapshot) => {
          const applicationList = [];
          for (const docSnapshot of querySnapshot.docs) {
            const data = docSnapshot.data();
            const creatorDoc = await getDoc(doc(db, 'creators', data.creatorId));
            const creatorData = creatorDoc.exists() ? creatorDoc.data() : null;
            applicationList.push({ 
              id: docSnapshot.id, 
              ...data, 
              creatorName: creatorData?.name || 'Unknown Creator',
              creatorProfilePicture: creatorData?.profilePicture || 'https://via.placeholder.com/50'
            });
          }
          setApplications(applicationList);
        });

        return unsubscribeApplications;
      }
    };

    const unsubscribeOffers = fetchOffers();
    const unsubscribeApplications = fetchApplications();

    // Fetch tags
    const fetchTags = async () => {
      const tagsSnapshot = await getDocs(collection(db, 'tags'));
      if (tagsSnapshot.empty) {
        await generateTags();
        const newTagsSnapshot = await getDocs(collection(db, 'tags'));
        const tagsList = newTagsSnapshot.docs.map(doc => doc.data().name);
        setTags(tagsList);
      } else {
        const tagsList = tagsSnapshot.docs.map(doc => doc.data().name);
        setTags(tagsList);
      }
    };
    fetchTags();

    const fetchUnreadMessageCounts = async () => {
      const user = auth.currentUser;
      if (user) {
        const unreadCountsQuery = query(
          collection(db, 'messages'),
          where('recipientId', '==', user.uid),
          where('read', '==', false)
        );
        const unreadSnapshot = await getDocs(unreadCountsQuery);
        const counts = {};
        unreadSnapshot.forEach(doc => {
          const data = doc.data();
          counts[data.applicationId] = (counts[data.applicationId] || 0) + 1;
        });
        setUnreadMessages(counts);
      }
    };

    fetchUnreadMessageCounts();
    // Set up a real-time listener for new messages
    const unsubscribe = onSnapshot(
      query(
        collection(db, 'messages'),
        where('recipientId', '==', auth.currentUser.uid),
        where('read', '==', false)
      ),
      (snapshot) => {
        const counts = { ...unreadMessages };
        snapshot.docChanges().forEach(change => {
          if (change.type === 'added') {
            const data = change.doc.data();
            counts[data.applicationId] = (counts[data.applicationId] || 0) + 1;
          }
          if (change.type === 'removed') {
            const data = change.doc.data();
            counts[data.applicationId] = (counts[data.applicationId] || 1) - 1;
            if (counts[data.applicationId] === 0) {
              delete counts[data.applicationId];
            }
          }
        });
        setUnreadMessages(counts);
      }
    );

    const fetchBrandVenues = async () => {
      const user = auth.currentUser;
      if (user) {
        const brandDoc = await getDoc(doc(db, 'brands', user.uid));
        if (brandDoc.exists()) {
          const brandData = brandDoc.data();
          setBrandVenues(brandData.venues || []);
        }
      }
    };

    fetchBrandVenues();

    const user = auth.currentUser;
    if (user) {
      const unsubscribe = onSnapshot(doc(db, 'brands', user.uid), (doc) => {
        if (doc.exists()) {
          setCredits(doc.data().credits || 0);
        }
      });

      return () => unsubscribe();
    }

    return () => {
      if (unsubscribeOffers) unsubscribeOffers();
      if (unsubscribeApplications) unsubscribeApplications();
      if (unsubscribe) unsubscribe();
    };
  }, []);

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      // Revoke the old URL if it exists to prevent memory leaks
      if (offerImageUrl && offerImageUrl.startsWith('blob:')) {
        URL.revokeObjectURL(offerImageUrl);
      }
      
      setOfferImage(file);
      const imageUrl = URL.createObjectURL(file);
      setOfferImageUrl(imageUrl);
    }
  };

  const handleAddressSelect = async (selectedAddress) => {
    setAddress(selectedAddress);
    try {
      const results = await geocodeByAddress(selectedAddress.label);
      const latLng = await getLatLng(results[0]);
      setCoordinates(latLng);
      
      // Extract city from the address components
      const cityComponent = results[0].address_components.find(
        component => component.types.includes('locality')
      );
      if (cityComponent) {
        setCity(cityComponent.long_name);
      }
    } catch (error) {
      console.error("Error getting coordinates or city:", error);
    }
  };

  const handleVenueSelect = (venue) => {
    if (!venue) return;
    
    console.log('Selected venue:', venue); // For debugging
    setSelectedVenue(venue);
    setNewOffer(prev => ({
      ...prev,
      venue: venue.venueName || venue.name,
      address: venue.address,
      city: venue.city,
      latitude: venue.location?.lat,
      longitude: venue.location?.lng
    }));
  };

  const getCreditCost = (offerTypes, selectedCollabType) => {
    if (selectedCollabType === 'Paid Collab') {
      return 300; // Fixed cost for Paid Collab
    }

    // Existing logic for Barter type
    let cost = 0;
    if (offerTypes['1story']) cost += 30;
    if (offerTypes['2stories']) cost += 50;
    if (offerTypes['storiesPost']) cost += 80;
    return cost;
  };

  const handleAddOffer = async (e) => {
    e.preventDefault();

    if (isEditing) {
      try {
        // Handle image upload for editing
        let imageUrl = offerImageUrl;
        if (offerImage) {
          const imageRef = ref(storage, `offerImages/${auth.currentUser.uid}/${Date.now()}`);
          await uploadBytes(imageRef, offerImage);
          imageUrl = await getDownloadURL(imageRef);
        }

        const offerRef = doc(db, 'offers', editingOfferId);
        
        // Sanitize venue data
        const venueData = selectedVenue ? {
          name: selectedVenue.venueName || selectedVenue.name || '',
          address: selectedVenue.address || '',
          city: selectedVenue.city || '',
          location: selectedVenue.location || null,
          phoneNumber: selectedVenue.phoneNumber || '',
          website: selectedVenue.website || '',
          isMainVenue: selectedVenue.isMainVenue || false
        } : null;

        const updatedData = {
          title: newOffer.title || '',
          description: newOffer.description || '',
          compensation: newOffer.compensation || '',
          venue: venueData ? venueData.name : '',
          address: venueData ? venueData.address : '',
          city: venueData ? venueData.city : '',
          location: venueData ? venueData.location : null,
          startDate: newOffer.startDate || '',
          endDate: newOffer.endDate || '',
          updatedAt: serverTimestamp(),
          offerTypes: newOffer.offerTypes || {},
          selectedTags: selectedTags || [],
          venueDetails: venueData,
          imageUrl: imageUrl, // Add the image URL
          collabType: collabType,
          offerTypes: collabType === 'Barter' ? newOffer.offerTypes : {},
        };

        await updateDoc(offerRef, updatedData);
        addToast('Offer updated successfully', 'success');
        resetForm();
        setIsEditing(false);
        setEditingOfferId(null);

      } catch (error) {
        console.error('Error updating offer:', error);
        addToast('Error updating offer: ' + error.message, 'error');
      }
    } else {
      const cost = getCreditCost(newOffer.offerTypes, collabType);
      
      if (credits < cost) {
        addToast(`Insufficient credits. You need ${cost} credits to create this offer.`, 'error');
        return;
      }

      try {
        // Handle image upload for new offer
        let imageUrl = '';
        if (offerImage) {
          const imageRef = ref(storage, `offerImages/${auth.currentUser.uid}/${Date.now()}`);
          await uploadBytes(imageRef, offerImage);
          imageUrl = await getDownloadURL(imageRef);
        }

        await runTransaction(db, async (transaction) => {
          const brandRef = doc(db, 'brands', auth.currentUser.uid);
          const brandDoc = await transaction.get(brandRef);

          if (!brandDoc.exists()) {
            throw "Brand document does not exist!";
          }

          const newCredits = brandDoc.data().credits - cost;

          if (newCredits < 0) {
            throw "Insufficient credits";
          }

          // Sanitize venue data
          const venueData = selectedVenue ? {
            name: selectedVenue.venueName || selectedVenue.name || '',
            address: selectedVenue.address || '',
            city: selectedVenue.city || '',
            location: selectedVenue.location || null,
            phoneNumber: selectedVenue.phoneNumber || '',
            website: selectedVenue.website || '',
            isMainVenue: selectedVenue.isMainVenue || false
          } : null;

          const offerData = {
            title: newOffer.title || '',
            description: newOffer.description || '',
            compensation: newOffer.compensation || '',
            venue: venueData ? venueData.name : '',
            address: venueData ? venueData.address : '',
            city: venueData ? venueData.city : '',
            location: venueData ? venueData.location : null,
            startDate: newOffer.startDate || '',
            endDate: newOffer.endDate || '',
            brandId: auth.currentUser.uid,
            status: 'active',
            createdAt: serverTimestamp(),
            offerTypes: newOffer.offerTypes || {},
            selectedTags: selectedTags || [],
            venueDetails: venueData,
            imageUrl: imageUrl, // Add the image URL
            collabType: collabType,
            offerTypes: collabType === 'Barter' ? newOffer.offerTypes : {},
          };

          // Remove any undefined values
          Object.keys(offerData).forEach(key => {
            if (offerData[key] === undefined) {
              delete offerData[key];
            }
          });

          transaction.update(brandRef, { credits: newCredits });

          // Create the offer
          const offerRef = await addDoc(collection(db, 'offers'), offerData);

          // Add to audit trail
          await addDoc(collection(db, 'audit'), {
            action: 'offer_created',
            offerId: offerRef.id,
            brandId: auth.currentUser.uid,
            creditsCost: cost,
            timestamp: serverTimestamp()
          });
        });

        addToast('Offer created successfully', 'success');
        resetForm();
      } catch (error) {
        console.error('Error creating offer:', error);
        addToast('Error creating offer: ' + error.message, 'error');
      }
    }
  };

  const handleOfferTypeChange = (type) => {
    setNewOffer(prev => ({
      ...prev,
      offerTypes: {
        ...prev.offerTypes,
        [type]: !prev.offerTypes[type]
      }
    }));
  };

  const handleApplicationDecision = async (applicationId, decision) => {
    try {
      await updateDoc(doc(db, 'applications', applicationId), {
        status: decision,
        reviewedAt: new Date(),
      });
      
      // Add to audit collection
      await addDoc(collection(db, 'audit'), {
        action: `application_${decision}`,
        applicationId: applicationId,
        brandId: auth.currentUser.uid,
        timestamp: new Date()
      });

      addToast(`Application ${decision}`);
    } catch (error) {
      console.error('Error updating application:', error);
      addToast('Error: ' + error.message);
    }
  };

  const handleMessageCreator = async (application, offerId) => {
    setSelectedApplication(application);
    setShowChatModal(true);
    
    // Fetch messages for this application
    const messagesQuery = query(
      collection(db, 'messages'),
      where('applicationId', '==', application.id),
      where('offerId', '==', offerId)
    );
    const messagesSnapshot = await getDocs(messagesQuery);
    const messageList = messagesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setMessages(messageList);
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() === '' || !selectedApplication) return;

    try {
      await addDoc(collection(db, 'messages'), {
        applicationId: selectedApplication.id,
        offerId: selectedApplication.offerId,
        senderId: auth.currentUser.uid,
        recipientId: selectedApplication.creatorId,
        message: newMessage,
        timestamp: serverTimestamp(),
        read: false
      });
      setNewMessage('');
      // Refresh messages
      const messagesQuery = query(
        collection(db, 'messages'),
        where('applicationId', '==', selectedApplication.id),
        where('offerId', '==', selectedApplication.offerId)
      );
      const messagesSnapshot = await getDocs(messagesQuery);
      const messageList = messagesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setMessages(messageList);
      addToast('Message sent successfully');
    } catch (error) {
      console.error('Error sending message:', error);
      addToast('Failed to send message. Please try again.');
    }
  };

  const handleRemoveOffer = (offer) => {
    setOfferToDelete(offer);
    setShowDeleteConfirmation(true);
  };

  const confirmRemoveOffer = async () => {
    if (!offerToDelete) return;

    try {
      const offerRef = doc(db, 'offers', offerToDelete.id);
      await updateDoc(offerRef, {
        status: 'archived',
        archivedAt: serverTimestamp()
      });

      // Archive associated applications
      const applicationsQuery = query(collection(db, 'applications'), where('offerId', '==', offerToDelete.id));
      const applicationsSnapshot = await getDocs(applicationsQuery);
      applicationsSnapshot.forEach(async (appDoc) => {
        await updateDoc(doc(db, 'applications', appDoc.id), {
          status: 'archived',
          archivedAt: serverTimestamp()
        });
      });

      // Archive associated messages
      const messagesQuery = query(collection(db, 'messages'), where('offerId', '==', offerToDelete.id));
      const messagesSnapshot = await getDocs(messagesQuery);
      messagesSnapshot.forEach(async (msgDoc) => {
        await updateDoc(doc(db, 'messages', msgDoc.id), {
          status: 'archived',
          archivedAt: serverTimestamp()
        });
      });

      addToast('Offer archived successfully');
      setShowDeleteConfirmation(false);
      setOfferToDelete(null);
    } catch (error) {
      console.error('Error archiving offer:', error);
      addToast('Failed to archive offer. Please try again.');
    }
  };

  const handleEditOffer = (offer) => {
    // Scroll to top of the form
    window.scrollTo({ top: 0, behavior: 'smooth' });

    // Set the venue if it exists in the offer
    if (offer.venueDetails) {
      handleVenueSelect(offer.venueDetails);
    }

    // Set the image if it exists
    if (offer.imageUrl) {
      setOfferImageUrl(offer.imageUrl);
      setOfferImage(null); // Reset the file input
    }

    // Set all the form fields
    setNewOffer({
      title: offer.title || '',
      description: offer.description || '',
      compensation: offer.compensation || '',
      venue: offer.venue || '',
      startDate: offer.startDate || '',
      endDate: offer.endDate || '',
      offerTypes: offer.offerTypes || {
        '1story': false,
        '2stories': false,
        'storiesPost': false
      },
    });

    // Set tags if they exist
    if (offer.selectedTags) {
      setSelectedTags(offer.selectedTags);
    }

    // Set editing mode
    setIsEditing(true);
    setEditingOfferId(offer.id);

    // Update button text/behavior
    addToast('Offer loaded for editing', 'info');
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedOffer(null);
  };

  const handleSaveOffer = async (updatedOffer) => {
    // Update the offer in the database
    await updateDoc(doc(db, 'offers', updatedOffer.id), updatedOffer);
    // Refresh offers
    const updatedOffers = offers.map((offer) => 
      offer.id === updatedOffer.id ? updatedOffer : offer
    );
    setOffers(updatedOffers);
  };

  const handleViewContent = (application) => {
    setSelectedContent(application.contentInputs);
    setShowContentModal(true);
  };

  const handleFinalizeCollaboration = async (applicationId) => {
    try {
      const applicationRef = doc(db, 'applications', applicationId);
      await updateDoc(applicationRef, {
        status: 'collaboration_completed',
        completedAt: serverTimestamp()
      });
      addToast('Collaboration finalized successfully', 'success');
      // Refresh your application list or update the local state
    } catch (error) {
      console.error('Error finalizing collaboration:', error);
      addToast('Error finalizing collaboration', 'error');
    }
  };

  useEffect(() => {
    const fetchBrandData = async () => {
      try {
        const user = auth.currentUser;
        if (!user) return;

        const brandDoc = await getDoc(doc(db, 'brands', user.uid));
        if (!brandDoc.exists()) return;

        const brandData = brandDoc.data();
        const venues = [];

        // Add main venue if it exists
        if (brandData.venueDetails) {
          venues.push({
            name: brandData.venueName,
            venueName: brandData.venueName,
            placeId: brandData.venueDetails.place_id,
            address: brandData.venueDetails.formatted_address,
            city: brandData.venueDetails.city,
            location: brandData.venueDetails.location,
            types: brandData.venueDetails.types || [],
            phoneNumber: brandData.venueDetails.phoneNumber,
            website: brandData.venueDetails.website,
            isMainVenue: true
          });
        }

        // Add additional venues
        if (brandData.venues && Array.isArray(brandData.venues)) {
          venues.push(...brandData.venues.map(venue => ({
            ...venue,
            isMainVenue: false
          })));
        }

        console.log('Loaded venues:', venues); // For debugging
        setBrandVenues(venues);
      } catch (error) {
        console.error('Error fetching brand data:', error);
        addToast('Error loading venues', 'error');
      }
    };

    fetchBrandData();
  }, []);

  // Add this function to handle clearing the venue selection
  const handleClearVenue = () => {
    setSelectedVenue(null);
    setNewOffer(prev => ({
      ...prev,
      venue: '',
      address: '',
      city: '',
      latitude: null,
      longitude: null
    }));
  };

  // Update the venue selection part of the form
  const VenueSelection = () => (
    <div>
      <label className="block text-gray-700 text-sm font-bold mb-2">
        Select Venue
      </label>
      <select
        value={selectedVenue?.venueName || ''}
        onChange={(e) => {
          console.log('Selection changed:', e.target.value);
          const venue = brandVenues.find(v => v.venueName === e.target.value || v.name === e.target.value);
          console.log('Found venue:', venue);
          handleVenueSelect(venue);
        }}
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4"
      >
        <option value="">Select a venue</option>
        {brandVenues.map((venue) => (
          <option 
            key={venue.venueName || venue.name} 
            value={venue.venueName || venue.name}
          >
            {venue.venueName || venue.name} {venue.isMainVenue ? '(Main Venue)' : ''}
          </option>
        ))}
      </select>

      {/* Venue Preview */}
      {selectedVenue && (
        <div className="mt-0 p-4 bg-gray-50 rounded-lg border border-gray-200">
          <div className="flex justify-between items-start mb-2">
            <h4 className="font-semibold text-gray-800">Selected Venue Details</h4>
            <button
              type="button"
              onClick={handleClearVenue}
              className="text-gray-400 hover:text-gray-600"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </button>
          </div>
          <div className="space-y-2 text-sm">
            <p><span className="font-medium">Name:</span> {selectedVenue.venueName || selectedVenue.name}</p>
            <p><span className="font-medium">Address:</span> {selectedVenue.address}</p>
            {selectedVenue.city && (
              <p><span className="font-medium">City:</span> {selectedVenue.city}</p>
            )}
            {selectedVenue.phoneNumber && (
              <p><span className="font-medium">Phone:</span> {selectedVenue.phoneNumber}</p>
            )}
            {selectedVenue.website && (
              <p>
                <span className="font-medium">Website:</span>{' '}
                <a 
                  href={selectedVenue.website} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:underline"
                >
                  {selectedVenue.website}
                </a>
              </p>
            )}
            {selectedVenue.location && (
              <div className="mt-2">
                <p className="font-medium mb-1">Location:</p>
                <div className="text-xs text-gray-600">
                  <p>Latitude: {selectedVenue.location.lat}</p>
                  <p>Longitude: {selectedVenue.location.lng}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Divider */}
      <div className="my-2 relative">
        <div className="absolute inset-0 flex items-center">
          <div className="w-full border-t border-gray-300"></div>
        </div>
        <div className="relative flex justify-center text-sm">
          <span className="px-2 bg-white text-gray-500">OR</span>
        </div>
      </div>

      {/* New Address Input */}
      <div className="">
        <p className="text-sm font-medium text-gray-700 mb-2">Add a new address:</p>
        <GooglePlacesAutocomplete
          apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
          selectProps={{
            value: newVenue,
            onChange: (value) => {
              setNewVenue(value);
              setSelectedVenue(null); // Clear selected venue when adding new
            },
            placeholder: 'Enter a location',
            className: "w-full"
          }}
        />
        {newVenue && (
          <button
            type="button"
            onClick={handleAddVenue}
            className="mt-2 bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded text-sm"
          >
            Add This Venue
          </button>
        )}
      </div>
    </div>
  );

  // Add this function to handle adding a new venue
  const handleAddVenue = async () => {
    if (!newVenue?.value) return;

    try {
      const results = await geocodeByAddress(newVenue.label);
      const latLng = await getLatLng(results[0]);
      
      // Extract city from address components
      const cityComponent = results[0].address_components.find(
        component => component.types.includes('locality')
      );

      const newVenueData = {
        name: newVenue.label.split(',')[0], // Get the venue name from the first part of the address
        venueName: newVenue.label.split(',')[0],
        address: newVenue.label,
        city: cityComponent ? cityComponent.long_name : '',
        placeId: newVenue.value.place_id,
        location: {
          lat: latLng.lat,
          lng: latLng.lng
        },
        isMainVenue: false
      };

      setBrandVenues(prev => [...prev, newVenueData]);
      handleVenueSelect(newVenueData);
      setNewVenue(null);
      addToast('Venue added successfully', 'success');
    } catch (error) {
      console.error('Error adding venue:', error);
      addToast('Error adding venue', 'error');
    }
  };

  // Handle saving draft
  const handleSaveDraft = async () => {
    try {
      let imageUrl = offerImageUrl;
      
      // Upload image if exists
      if (offerImage) {
        const imageRef = ref(storage, `offerImages/${auth.currentUser.uid}/${Date.now()}`);
        await uploadBytes(imageRef, offerImage);
        imageUrl = await getDownloadURL(imageRef);
      }

      // Sanitize venue data
      const venueData = selectedVenue ? {
        name: selectedVenue.venueName || selectedVenue.name || '',
        address: selectedVenue.address || '',
        city: selectedVenue.city || '',
        location: selectedVenue.location || null,
        phoneNumber: selectedVenue.phoneNumber || '',
        website: selectedVenue.website || '',
        isMainVenue: selectedVenue.isMainVenue || false
      } : null;

      const draftData = {
        title: newOffer.title || '',
        description: newOffer.description || '',
        compensation: newOffer.compensation || '',
        venue: venueData ? venueData.name : '',
        address: venueData ? venueData.address : '',
        city: venueData ? venueData.city : '',
        location: venueData ? venueData.location : null,
        startDate: newOffer.startDate || '',
        endDate: newOffer.endDate || '',
        imageUrl: imageUrl || '',
        brandId: auth.currentUser.uid,
        status: 'draft',
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        selectedTags: selectedTags || [],
        offerTypes: newOffer.offerTypes || {},
        venueDetails: venueData // Store complete venue details
      };

      // Remove any undefined values
      Object.keys(draftData).forEach(key => {
        if (draftData[key] === undefined) {
          delete draftData[key];
        }
      });

      await addDoc(collection(db, 'draftOffers'), draftData);
      addToast('Draft saved successfully', 'success');
      
      // Reset form
      resetForm();
    } catch (error) {
      console.error('Error saving draft:', error);
      addToast('Error saving draft', 'error');
    }
  };

  // Reset form helper
  const resetForm = () => {
    setNewOffer({
      title: '',
      description: '',
      compensation: '',
      venue: '',
      startDate: '',
      endDate: '',
      offerTypes: {
        '1story': false,
        '2stories': false,
        'storiesPost': false
      },
    });
    setOfferImage(null);
    setOfferImageUrl('');
    setSelectedTags([]);
    setSelectedVenue(null);
    setIsEditing(false);
    setEditingOfferId(null);
  };

  // Add error display
  if (error) {
    return (
      <div className="min-h-screen bg-gray-50">
        <Header userType="brand" />
        <main className="pt-28 md:pt-20 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-2">
          <div className="bg-red-50 border border-red-200 rounded-md p-4">
            <p className="text-red-700">Error: {error}</p>
            <button 
              onClick={() => window.location.reload()} 
              className="mt-2 text-red-600 hover:text-red-800 underline"
            >
              Try Again
            </button>
          </div>
        </main>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Header userType="brand" />
      <main className="pt-20 md:pt-20 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-2">
      <h1 className="text-xl font-bold mb-3 text-gray-500">Manage your offers</h1>
        <div className="flex flex-col md:flex-row gap-6">
          {/* Left Column - Create Offer Form */}
          <div className="md:w-1/2">
            <div className="bg-white rounded-lg shadow p-6">
              <h2 className="text-xl font-bold mb-4 text-gray-800">Create New Offer</h2>
              <form onSubmit={handleAddOffer} className="space-y-2">
                {/* Title */}
                <div>
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Offer Title
                  </label>
                  <input
                    type="text"
                    value={newOffer.title}
                    onChange={(e) => setNewOffer({ ...newOffer, title: e.target.value })}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    required
                  />
                </div>

                {/* Description */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Description
                  </label>
                  <ReactQuill
                    theme="snow"
                    value={newOffer.description || ''}
                    onChange={(content) => {
                      setNewOffer(prev => ({
                        ...prev,
                        description: content
                      }));
                    }}
                    modules={modules}
                    className="h-48 mb-12" // Add margin bottom to account for toolbar
                  />
                </div>

                {/* Venue Selection */}
                <VenueSelection />

                {/* Offer Image */}
                <div>
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Offer Image
                  </label>
                  {offerImageUrl && (
                    <div className="mb-2">
                      <img 
                        src={offerImageUrl} 
                        alt="Offer preview" 
                        className="w-full max-h-48 object-cover rounded"
                      />
                      <button
                        type="button"
                        onClick={() => {
                          setOfferImage(null);
                          setOfferImageUrl('');
                        }}
                        className="mt-2 text-red-600 hover:text-red-800 text-sm"
                      >
                        Remove Image
                      </button>
                    </div>
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    className="mb-2"
                  />
                </div>

                {/* Compensation */}
                <div>
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Compensation
                  </label>
                  <input
                    type="text"
                    value={newOffer.compensation}
                    onChange={(e) => setNewOffer({ ...newOffer, compensation: e.target.value })}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    required
                  />
                </div>

                {/* Date Range */}
                <div>
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Date Range
                  </label>
                  <div className="grid grid-cols-2 gap-4">
                    <input
                      type="date"
                      value={newOffer.startDate}
                      onChange={(e) => setNewOffer({ ...newOffer, startDate: e.target.value })}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      required
                    />
                    <input
                      type="date"
                      value={newOffer.endDate}
                      onChange={(e) => setNewOffer({ ...newOffer, endDate: e.target.value })}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      required
                    />
                  </div>
                </div>

                {/* Tags Selection */}
                <div>
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Tags
                  </label>
                  <div className="flex flex-wrap gap-2">
                    {tags.map((tag) => (
                      <button
                        key={tag}
                        type="button"
                        onClick={() => {
                          if (selectedTags.includes(tag)) {
                            setSelectedTags(selectedTags.filter((t) => t !== tag));
                          } else {
                            setSelectedTags([...selectedTags, tag]);
                          }
                        }}
                        className={`px-3 py-1 rounded-full text-sm ${
                          selectedTags.includes(tag)
                            ? 'bg-blue-500 text-white'
                            : 'bg-gray-200 text-gray-700'
                        }`}
                      >
                        {tag}
                      </button>
                    ))}
                  </div>
                </div>

                {/* Add Collaboration Type Selection */}
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Collaboration Type
                  </label>
                  <div className="space-y-2 bg-gray-50 p-3 rounded">
                    {['Barter', 'Paid Collab'].map((type) => (
                      <label key={type} className="flex items-center space-x-2">
                        <input
                          type="radio"
                          checked={collabType === type}
                          onChange={() => setCollabType(type)}
                          className="form-radio h-5 w-5 text-blue-600"
                          name="collabType"
                          value={type}
                        />
                        <span>{type}</span>
                        {type === 'Paid Collab' && (
                          <span className="text-sm text-gray-600">
                            (300 credits - one-time use)
                          </span>
                        )}
                      </label>
                    ))}
                  </div>
                </div>

                {/* Show offer types only for Barter collaboration */}
                {collabType === 'Barter' && (
                  <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Offer Type (Select all that apply)
                    </label>
                    <div className="space-y-2 bg-gray-50 p-3 rounded">
                      {Object.entries({
                        '1story': '1 Story (30 credits)',
                        '2stories': '2 Stories (50 credits)',
                        'storiesPost': '2 Stories + 1 Post (80 credits)'
                      }).map(([key, label]) => (
                        <label key={key} className="flex items-center space-x-2">
                          <input
                            type="checkbox"
                            checked={newOffer.offerTypes[key]}
                            onChange={() => handleOfferTypeChange(key)}
                            className="form-checkbox h-5 w-5 text-blue-600"
                          />
                          <span>{label}</span>
                        </label>
                      ))}
                    </div>
                  </div>
                )}

                {/* Cost Display */}
                <div className="mb-4 p-3 bg-blue-50 rounded">
                  <p className="text-blue-800 font-semibold">
                    Total Cost: {getCreditCost(newOffer.offerTypes, collabType)} credits
                  </p>
                  <p className="text-sm text-blue-600">
                    Available Credits: {credits}
                  </p>
                </div>

                {/* Action Buttons */}
                <div className="flex gap-4">
                  <button
                    type="button"
                    onClick={resetForm}
                    className="flex-1 bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="flex-1 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    disabled={!isEditing && getCreditCost(newOffer.offerTypes, collabType) > credits}
                  >
                    {isEditing ? 'Update Offer' : 'Publish Offer'}
                  </button>
                </div>
              </form>
            </div>
          </div>

          {/* Right Column - Active Offers */}
          <div className="md:w-1/2">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold text-gray-800">Active Offers</h2>
              <Link 
                to="/applications" 
                className="text-blue-500 hover:text-blue-600 text-sm"
              >
                View All Applications →
              </Link>
            </div>
            <div className="space-y-4">
              {offers.map((offer) => (
                <div key={offer.id} className="bg-white shadow-md rounded-lg p-6">
                  <div className="flex justify-between items-start mb-4">
                    <h3 className="text-lg font-semibold">{offer.title}</h3>
                    <div className="flex gap-2">
                      <button
                        onClick={() => handleEditOffer(offer)}
                        className={`text-sm px-3 py-1 rounded ${
                          editingOfferId === offer.id
                          ? 'bg-yellow-200 text-yellow-800'
                          : 'bg-yellow-500 text-white hover:bg-yellow-600'
                        }`}
                      >
                        {editingOfferId === offer.id ? 'Currently Editing' : 'Edit'}
                      </button>
                      <button
                        onClick={() => handleRemoveOffer(offer)}
                        className="text-sm px-3 py-1 rounded bg-red-500 text-white hover:bg-red-600"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                  
                  <div className="mb-4">
                    <div className="text-gray-600 rich-text-content text-sm">
                      {parse(offer.description || '')}
                    </div>
                    <p className="text-gray-800 font-medium mt-2">
                      Compensation: {offer.compensation}
                    </p>
                    <p className="text-gray-600 text-sm">
                      Venue: {offer.venue}
                    </p>
                  </div>

                  {/* Simple application count instead of full list */}
                  <div className="mt-4 border-t pt-4">
                    <Link 
                      to={`/applications`}
                      className="text-blue-500 hover:text-blue-600 flex items-center gap-2"
                    >
                      <span>View Applications</span>
                      {offer.applications?.length > 0 && (
                        <span className="bg-blue-100 text-blue-800 px-2 py-1 rounded-full text-xs">
                          {offer.applications.length}
                        </span>
                      )}
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </main>

      {showDeleteConfirmation && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-xl">
            <h3 className="text-lg font-semibold mb-4">Confirm Offer Removal</h3>
            <p>Are you sure you want to remove this offer? This action cannot be undone.</p>
            <div className="mt-4 flex justify-end space-x-4">
              <button
                onClick={() => setShowDeleteConfirmation(false)}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={confirmRemoveOffer}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
              >
                Confirm Remove
              </button>
            </div>
          </div>
        </div>
      )}
      {isModalOpen && (
        <EditOfferModal 
          offer={selectedOffer} 
          onClose={handleCloseModal} 
          onSave={handleSaveOffer} 
        />
      )}
      {showContentModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-xl">
            <h3 className="text-lg font-semibold mb-4">Creator's Content</h3>
            <div className="space-y-4">
              {selectedContent && Object.entries(selectedContent).map(([type, content]) => (
                <div key={type}>
                  <p className="font-semibold capitalize">{type}:</p>
                  {Array.isArray(content) ? (
                    content.map((item, index) => (
                      <div key={index}>
                        {type.includes('URL') ? (
                          <a href={item} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                            {item}
                          </a>
                        ) : type === 'Image' ? (
                          <img src={item} alt={`Uploaded ${index + 1}`} className="w-full max-w-md h-auto" />
                        ) : (
                          <video src={item} controls className="w-full max-w-md h-auto" />
                        )}
                      </div>
                    ))
                  ) : (
                    <p>{content}</p>
                  )}
                </div>
              ))}
            </div>
            <button
              onClick={() => setShowContentModal(false)}
              className="mt-4 bg-gray-300 text-gray-800 px-4 py-2 rounded hover:bg-gray-400"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default BrandOfferScreen;
