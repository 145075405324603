import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth, db, realtimeDb } from '../firebase';
import { doc, getDoc, collection, query, where, onSnapshot, getDocs } from 'firebase/firestore';
import { ref, onValue, off } from 'firebase/database';
import { Search, MessagesSquare, Bell, Menu, MapPin, LocateFixed, ChevronDown, CreditCard, Navigation, Gem } from 'lucide-react';
import { useGlobalContext } from '../context/GlobalContext';
import { useAuth } from '../context/AuthContext';


// Add this function to remove diacritics
const removeDiacritics = (str) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

const Header = () => {
  const { currentUser, userType } = useAuth();
  const { refreshHeaderCount } = useGlobalContext(); // Add this line
  const [showTooltip, setShowTooltip] = useState(false);
  const [profilePicture, setProfilePicture] = useState(null);
  const [pendingNotifications, setPendingNotifications] = useState(0);
  const [newMessages, setNewMessages] = useState(0);
  const [credits, setCredits] = useState(0);
  const [cities, setCities] = useState([]);
  const { selectedCity, setSelectedCity } = useGlobalContext();
  const navigate = useNavigate();
  const [isUsingCurrentLocation, setIsUsingCurrentLocation] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, right: 0 });
  const location = useLocation();

  


  const toggleTooltip = (event) => {
    if (showTooltip) {
      setShowTooltip(false);
    } else {
      const rect = event.currentTarget.getBoundingClientRect();
      setTooltipPosition({
        top: rect.bottom + window.scrollY + 10,
        right: window.innerWidth - rect.right - 10,
      });
      setShowTooltip(true);
    }
  };

  useEffect(() => {
    let unsubscribeApplications = () => {};
    let unsubscribeMessages = () => {};
    let unsubscribeUnreadMessages = () => {};
    let unsubscribeCredits = () => {};

    const fetchData = async () => {
      const user = auth.currentUser;
      if (user && userType) {
        try {
          const collectionName = userType === 'creator' ? 'creators' : 'brands';
          const userDoc = await getDoc(doc(db, collectionName, user.uid));
          if (userDoc.exists()) {
            setProfilePicture(userDoc.data().profilePicture || 'https://via.placeholder.com/150');
          }

          // Applications notifications
          let applicationsQuery;
          if (userType === 'brand') {
            applicationsQuery = query(
              collection(db, 'applications'),
              where('brandId', '==', user.uid),
              where('status', '==', 'pending')
            );
          } else if (userType === 'creator') {
            applicationsQuery = query(
              collection(db, 'applications'),
              where('creatorId', '==', user.uid),
              where('status', 'in', ['accepted', 'rejected'])
            );
          }

          if (applicationsQuery) {
            unsubscribeApplications = onSnapshot(applicationsQuery, (querySnapshot) => {
              setPendingNotifications(querySnapshot.size);
            }, (error) => {
              console.error("Error fetching notifications:", error);
            });
          }

          // Unread messages notification
          const unreadMessagesRef = ref(realtimeDb, `unreadMessages/${user.uid}`);
          unsubscribeUnreadMessages = onValue(unreadMessagesRef, (snapshot) => {
            const unreadData = snapshot.val() || {};
            if (unreadData) {
              // Use the same calculation method as BrandDashboard
              const totalUnread = Object.values(unreadData).reduce((sum, count) => sum + count, 0);
              setNewMessages(totalUnread);
            } else {
              setNewMessages(0);
            }
          });

          // Credits
          unsubscribeCredits = onSnapshot(doc(db, collectionName, user.uid), (doc) => {
            if (doc.exists()) {
              setCredits(doc.data().credits || 0);
            }
          });

        } catch (error) {
          console.error("Error in fetchData:", error);
        }
      }
    };

    fetchData();

    // Fetch unique cities from offers
    const fetchCities = async () => {
      const offersQuery = query(collection(db, 'offers'));
      const offersSnapshot = await getDocs(offersQuery);
      const uniqueCities = new Set();
      
      offersSnapshot.forEach(doc => {
        const offerData = doc.data();
        console.log('Offer data:', offerData); // Debug log
        
        // Include city if it exists and status is not explicitly false
        if (offerData.city && offerData.status !== false) {
          uniqueCities.add(offerData.city);
        }
      });
      
      const citiesArray = ['All Cities', ...Array.from(uniqueCities)];
      console.log('Final cities array:', citiesArray); // Debug log
      setCities(citiesArray);
    };

    fetchCities();

    return () => {
      unsubscribeApplications();
      unsubscribeMessages();
      unsubscribeUnreadMessages();
      unsubscribeCredits();
    };
  }, [userType, currentUser, refreshHeaderCount]); // Add refreshHeaderCount to dependencies

  // Updated check at the beginning of the component
  if (location.pathname === '/onboarding' || location.pathname === '/admin' || location.pathname === '/pending-approval' || location.pathname.startsWith('/admin') || !userType) {
    return null;
  }

  if (!userType) {
    return null;
  }

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const navigateTo = (path) => {
    navigate(path);
    setShowTooltip(false);
  };

  const handleGetUserLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { latitude, longitude } = position.coords;
        try {
          const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`);
          const data = await response.json();
          if (data.results && data.results.length > 0) {
            const cityComponent = data.results[0].address_components.find(
              component => component.types.includes('locality')
            );
            if (cityComponent) {
              const cityName = cityComponent.long_name;
              setSelectedCity(cityName);
              setIsUsingCurrentLocation(true);
              navigate(`/offers?city=${encodeURIComponent(cityName)}&useLocation=true`);
            } else {
              setSelectedCity('All Cities');
              setIsUsingCurrentLocation(true);
              navigate('/offers?city=All%20Cities&useLocation=true');
            }
          } else {
            setSelectedCity('All Cities');
            setIsUsingCurrentLocation(true);
            navigate('/offers?city=All%20Cities&useLocation=true');
          }
        } catch (error) {
          console.error('Error getting user location:', error);
          setSelectedCity('All Cities');
          setIsUsingCurrentLocation(true);
          navigate('/offers?city=All%20Cities&useLocation=true');
        }
      }, (error) => {
        console.error('Error getting user location:', error);
        setSelectedCity('All Cities');
        setIsUsingCurrentLocation(true);
        navigate('/offers?city=All%20Cities&useLocation=true');
      });
    } else {
      alert('Geolocation is not supported by your browser. Please select a city manually.');
    }
  };

  const handleCityChange = (e) => {
    const newCity = e.target.value;
    setSelectedCity(newCity);
    setIsUsingCurrentLocation(false);
    navigate(`/offers?city=${encodeURIComponent(newCity)}`);
  };

  // Check if the current page is AdminPanel
  if (location.pathname === '/AdminPanel') {
    return null; // Don't render the header
  }

  if (!userType) {
    return null;
  }

  return (
    <header className="bg-gradient-to-b from-[rgba(205,212,213,1)] to-[rgba(255,255,255,0)] fixed top-0 left-0 right-0 z-50">
    {/* Mobile Header */}
    <div className="md:hidden px-4 py-2">
      <div className="flex justify-between items-center mb-2">
        <Link to={userType === 'creator' ? '/offers' : '/applications'} className="text-3xl font-bold tracking-tighter">Behave</Link>
        <div className="flex items-center space-x-2">
          <div className="flex items-center bg-white rounded-full px-2 py-[6px] border border-gray-300 shadow-sm">
            <CreditCard className="h-5 w-5 text-gray-500 mr-2" />
            <Link to="/buy-credits" className="font-semibold">{credits}</Link>
          </div>
          <Link 
            to="/messages"
            className="relative bg-white p-2 rounded-full border border-gray-300 shadow-sm"
          >
            <MessagesSquare className="h-5 w-5 text-gray-500" />
            {newMessages > 0 && (
              <span className="absolute -top-1 -right-1 bg-red-500 text-white rounded-full w-4 h-4 flex items-center justify-center text-xs">
                {newMessages}
              </span>
            )}
          </Link>
          <Link 
            to={userType === 'creator' ? '/applied-offers' : '/applications'}
            className="relative bg-white p-2 rounded-full border border-gray-300 shadow-sm"
          >
            <Gem className="h-5 w-5 text-gray-500" />
            {pendingNotifications > 0 && (
              <span className="absolute -top-1 -right-1 bg-red-500 text-white rounded-full w-4 h-4 flex items-center justify-center text-xs">
                {pendingNotifications}
              </span>
            )}
          </Link>
          <div className="relative flex items-center">
            <img 
              src={profilePicture || 'https://via.placeholder.com/40'} 
              alt="Profile" 
              className="w-9 h-9 rounded-full cursor-pointer object-cover shadow-sm"
              onClick={toggleTooltip}
            />
            <ChevronDown className="h-4 w-4 text-gray-500 ml-1" />
          </div>
        </div>
      </div>
      <div className="space-y-2 flex flex-row md:flex-row items-center gap-2 justify-between">
        {userType === 'creator' && (
          <div className="flex items-center bg-white rounded-full px-3 py-0 shadow-sm w-1/2 md:w-1/2 h-[40px] border border-gray-300 shadow-sm">
            <button
                onClick={handleGetUserLocation}
                className={`mr-2 ${isUsingCurrentLocation ? 'text-blue-500' : 'text-gray-500'} hover:text-blue-700`}
                title="Get current location">
            <LocateFixed className="h-6 w-5 text-gray-400 mr-2" />
            </button>
            <select
              value={selectedCity}
              onChange={handleCityChange}
              className="bg-transparent text-m font-semibold flex-grow appearance-none"
            >
              {cities.map((city, index) => (
                <option key={index} value={city}>{city}</option>
              ))}
            </select>
            <ChevronDown className="h-4 w-4 text-gray-400 ml-2" />
          </div>
        )}
        {userType === 'creator' && (
        <div className="relative w-1/2 md:w-1/2">
          <input
            type="text"
            placeholder="Search venue"
            className="w-full pl-10 pr-3 py-2 rounded-full border border-gray-300 h-[40px] shadow-sm mt-[-8px]"
          />
          <Search className="absolute left-[15px] top-[2px] h-5 w-5 text-gray-400" />
        </div>
        )}
      </div>
    </div>

      {/* Tablet and Desktop Header */}
      <div className="hidden md:block max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <Link to="/dashboard" className="text-4xl font-bold mr-8 cursor-pointer tracking-tighter">Behave</Link>
            {userType === 'creator' && (
              <div className="flex mt-1 text-sm items-center bg-white p-4 py-[10px] rounded-full border border-gray-300 font-semibold shadow-sm">
                <button
                  onClick={handleGetUserLocation}
                  className={`mr-2 ${isUsingCurrentLocation ? 'text-blue-500' : 'text-gray-500'} hover:text-blue-700`}
                  title="Get current location"
                >
                  <LocateFixed className="h-6 w-6 mr-[-4px]" />
                </button>
                <select
                  value={selectedCity}
                  onChange={handleCityChange}
                  className="bg-transparent text-l font-semibold"
                >
                  {cities.map((city, index) => (
                    <option key={index} value={city}>{city}</option>
                  ))}
                </select>
              </div>
            )}
          </div>
          <div className="flex items-center space-x-4">
          {userType === 'creator' && (
            <div className="relative">
              <input
                type="text"
                placeholder="Work in progress..."
                className="placeholder:text-sm mt-1 pl-10 pr-4 text-sm py-3 w-64 rounded-full border border-gray-300 shadow-sm font-semibold"
              />
              <Search className="absolute left-[15px] top-[18px] h-5 w-5 text-gray-500" />
            </div>
          )}
            <div className="relative">
              <Link 
                to="/messages"
                className="mt-1 p-3 rounded-full bg-white hover:bg-gray-100 border border-gray-300 shadow-sm inline-flex items-center justify-center"
              >
                <MessagesSquare className="h-6 w-6" />
                {newMessages > 0 && (
                  <span className="absolute -top-1 -right-1 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
                    {newMessages}
                  </span>
                )}
              </Link>
            </div>
            <div className="relative">
              <Link 
                to={userType === 'creator' ? '/applied-offers' : '/applications'}
                className="mt-1 p-3 rounded-full bg-white hover:bg-gray-100 border border-gray-300 shadow-sm inline-flex items-center justify-center"
              >
                <Gem className="h-6 w-6" />
                {pendingNotifications > 0 && (
                  <span className="absolute -top-1 -right-1 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
                    {pendingNotifications}
                  </span>
                )}
              </Link>
            </div>
            <div className="flex items-center bg-green-100 rounded-full px-3 py-3 hover:bg-green-200 transition-colors mt-1 p-3 rounded-full bg-white hover:bg-gray-100 border border-gray-300 shadow-sm">
              <Link to="/buy-credits" className="flex items-center">
                <CreditCard className="h-4 w-4 text-gray-500 mr-2 " />
                <span className="text-m font-semibold">{credits}</span>
              </Link>
            </div>
            <div className="relative flex items-center">
              <img 
                src={profilePicture || 'https://via.placeholder.com/150'} 
                alt="Profile" 
                className="mt-1 w-[52px] h-[52px] rounded-full cursor-pointer object-cover border border-gray-300 shadow-sm"
                onClick={toggleTooltip}
              />
              <ChevronDown className="h-5 w-5 text-gray-500 ml-1" />
            </div>
          </div>
        </div>
      </div>
      
      {showTooltip && (
        <div 
          className="absolute bg-white rounded-md shadow-lg z-20"
          style={{
            top: `${tooltipPosition.top}px`,
            right: `${tooltipPosition.right}px`,
            width: '12rem',
          }}
        >
          <div className="py-1">
            <Link to="/dashboard" className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Dashboard</Link>
            <Link to={userType === 'creator' ? '/offers' : '/manage-offers'} className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
              {userType === 'creator' ? 'View Offers' : 'Manage Offers'}
            </Link>
            <Link to={userType === 'creator' ? '/applied-offers' : '/applications'} className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
              {userType === 'creator' ? 'My Applications' : 'Received Applications'}
            </Link>
            <Link to="/messages" className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
              Messages {newMessages > 0 && `(${newMessages})`}
            </Link>
            <Link to={userType === 'creator' ? '/creator-profile' : '/brand-profile'} className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Profile</Link>
            {userType === 'brand' && (
              <Link to="/analytics" className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Analytics</Link>
            )}
            <button onClick={handleLogout} className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Logout</button>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
